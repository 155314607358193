import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiRound = (props: BaseIcon) => {
  return (
    <SvgWrapper {...props}>
      <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" />
    </SvgWrapper>
  );
};
