import { queryFetch } from "apiConnectors/queryFetch";
import {
  AddAwaitingTransportToUnloadingResponse,
  AwaitingTransportDetails,
  AwaitingTransportGroupListItem,
  AwaitingTransportListItem,
  AwaitingTransportPackageGroup,
} from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { Pagination, UUID } from "api/types";
import { wmsAwaitingTransportsKeys } from "./keys";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";
import { Unloading } from "../models";
import { fileFactoryUtils } from "utilities/fileFactory";

const getAwaitingTransports = (
  search: string = "",
): ApiFetcher<Pagination<AwaitingTransportListItem>> => ({
  key: wmsAwaitingTransportsKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/awaiting-transports/items" + search,
    }),
});

const getAwaitingTransport = (
  id: AwaitingTransportDetails["id"],
): ApiFetcher<AwaitingTransportDetails> => ({
  key: wmsAwaitingTransportsKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/awaiting-transports/items/" + id,
    }),
});

const patchAwaitingTransport = (data: Assign<Partial<AwaitingTransportDetails>, { id: UUID }>) => {
  return queryFetch<AwaitingTransportDetails>({
    method: "PATCH",
    url: "/wms/awaiting-transports/items/" + data.id,
    data: parsePatchData(data),
  });
};

const postAwaitingTransport = () =>
  queryFetch<void>({
    method: "POST",
    url: "/wms/awaiting-transports/items",
  });

const getAwaitingTransportGroups = (
  search: string = "",
): ApiFetcher<Pagination<AwaitingTransportGroupListItem>> => ({
  key: wmsAwaitingTransportsKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/awaiting-transports/groups" + search,
    }),
});

const getAwaitingTransportPackagesGroups = (
  search: string = "",
): ApiFetcher<Pagination<AwaitingTransportPackageGroup>> => ({
  key: wmsAwaitingTransportsKeys.packageGroupList(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/awaiting-transports/package-groups" + search,
    }),
});

const postAddAwaitingTransportToUnloading = (data: {
  awaitingTransportsIds: AwaitingTransportDetails["id"][];
  unloading: Unloading["id"];
}) =>
  queryFetch<AddAwaitingTransportToUnloadingResponse>({
    method: "POST",
    url: "/wms/awaiting-transports/add-to-unloading",
    data,
  });

const addOrdersToAwaitingTransport = (data: {
  awaitingTransport: AwaitingTransportDetails["id"];
  externalIds: string[];
}) =>
  queryFetch<void>({
    method: "POST",
    url: "orders/add-orders-to-awaiting-transport",
    data,
  });

const awaitingTransportFileFactory = (() => {
  return {
    packagesLabels: (search: string = "") => ({
      url: `wms/awaiting-transports/get-labels${search}`,
      name: `etykiety-${fileFactoryUtils.getDate()}`,
    }),
    awaitingTransportPdf: (id: AwaitingTransportDetails["id"], signature: string) => ({
      url: `/wms/awaiting-transports/loaded-packages-pdf/${id}`,
      name: `Awizacja-${signature}-${fileFactoryUtils.getDate()}`,
    }),
  };
})();

export const wmsAwaitingTransportsApi = {
  getAwaitingTransport,
  getAwaitingTransports,
  postAwaitingTransport,
  patchAwaitingTransport,
  getAwaitingTransportGroups,
  getAwaitingTransportPackagesGroups,
  awaitingTransportFileFactory,
  addOrdersToAwaitingTransport,
  postAddAwaitingTransportToUnloading,
};
