import { createPaginatedKey } from "api/keys";

export const carrierOrdersKeys = {
  carrierOrders: {
    list: createPaginatedKey("carrierOrders"),
    details: (id: string) => ["carrierOrder", id],
  },
  carrierOrdersEmailMessage: {
    list: createPaginatedKey("carrierOrdersEmailMessages"),
  },
};
