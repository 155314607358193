import { Car } from "api/cars/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useCarsColumns = () => {
  return useCreateTableColumns<Car>(({ columnHelper }) => {
    return [
      columnHelper.link({
        header: "nr rejestracyjny",
        size: 150,
        to: row => `/cars?panelId=${row.id}`,
        textRenderer: row => row.registrationNumber,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => row.brand, {
        header: "marka",
        size: 150,
      }),
      columnHelper.amount(row => Number(row.amountPerKilometer), {
        header: "kwota za kilometr",
        size: 120,
        textAlign: "right",
        amountDisplayProps: {
          currency: row => row.original.amountPerKilometerCurrency || undefined,
          decimal: {
            fontSize: "12",
            fontWeight: "700",
          },
          integer: {
            fontSize: "14",
            fontWeight: "700",
          },
        },
      }),
    ];
  });
};
