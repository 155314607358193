import styles from "./Switch.module.css";
import cx from "classnames";

interface Props {
  disabled?: boolean;
  onChange: (status: boolean) => void;
  checked?: boolean;
  label?: string;
  name: string;
  color?: "white" | "blue";
  overrides?: { label?: { className: string } };
}

/**
 * @deprecated
 */
export const Switch = ({
  checked,
  label = "",
  onChange,
  name,
  disabled = false,
  color = "blue",
  overrides = {},
}: Props) => {
  return (
    <div className={cx(styles.container, { [styles.disabled]: disabled })}>
      <div className={styles.row}>
        <div
          className={cx({
            [styles.checkMark]: true,
            [styles.activeMark]: checked,
            [styles.disabled]: disabled,
            [styles.white]: color === "white",
          })}
        />
        <input
          name={name}
          onChange={() => onChange(!checked)}
          type="checkbox"
          className={styles.input}
          disabled={disabled}
          checked={checked}
        />
        {label && (
          <label
            onClick={() => {
              if (!disabled) {
                onChange(!checked);
              }
            }}
            className={cx(styles.label, overrides.label?.className)}
          >
            {label}
          </label>
        )}
      </div>
    </div>
  );
};
