import { useTradingDocument } from "api/trading-documents/hooks";
import { BulkSalesInvoiceConfirmPreview } from "api/trading-documents/models";
import { proformasActions } from "api/trading-documents/proforma/actions";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useStateModal, useToggle } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { DownloadTradingDocumentPdf } from "pages/tradingDocuments/shared/components/downloadTradingDocumentPdf/DownloadTradingDocumentPdf";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { TradingDocumentOptionsMenuFactory } from "pages/tradingDocuments/shared/optionsMenuFactory/TradingDocumentOptionsMenuFactory";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "../../../shared/TradingDocumentsLists.module.css";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { TransportInfo } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/OrderMaterialModal";

export const PanelHeader = () => {
  const { close } = useDrawer("proformaInvoices");
  const { query } = useQuery();
  const { panelId } = query;
  const { data: tradingDocument } = useTradingDocument({ id: panelId });
  assertIsDefined(tradingDocument);
  const optionsMenu = TradingDocumentOptionsMenuFactory({ close, tradingDocument });
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const createSalesInvoiceFromProformaMutation = proformasActions.useCreateSalesInvoiceFromProforma();
  const { isTriggerElementHidden } = useScrollableContext();
  const transportModalController = useToggle();

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <ConfirmCheckbox tradingDocument={tradingDocument} replyModal={replyModal} />
            <div>Proforma&nbsp;{tradingDocument.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <ConfirmTradingDocument tradingDocument={tradingDocument} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          {tradingDocument.status === "CONFIRMED" && (
            <Button
              className="text-uppercase"
              isLoading={createSalesInvoiceFromProformaMutation.isLoading}
              onClick={() => createSalesInvoiceFromProformaMutation.mutate(tradingDocument.id)}
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Przekształć do faktury sprzedażowej
            </Button>
          )}
          <DownloadTradingDocumentPdf tradingDocument={tradingDocument} />
          <Menu
            className={styles.optionsMenu}
            disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
            menuItems={[
              optionsMenu.editTransportDocuments(transportModalController.open),
              { type: MenuItemType.DIVIDER },
              optionsMenu.tradingDocumentHistory(panelId),
              { type: MenuItemType.DIVIDER },
              optionsMenu.deleteTradingDocument(),
            ]}
          />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
      {transportModalController.isOpen && (
        <TransportInfo close={transportModalController.close} tradingDocument={tradingDocument} />
      )}
    </>
  );
};
