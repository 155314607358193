import { Unloading } from "api/wms/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useUnloadingOrdersColumns } from "./useUnloadingOrdersColumns";
import { wmsUnloadingActions } from "api/wms/unloading/actions";
import { cx, dateUtils, queryString } from "utilities";
import { UnloadingOrder } from "api/wms/unloading/models";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import styles from "../../RightPanel.module.css";
import { MdiCalendarClock } from "components/miloDesignSystem/atoms/icons/MdiCalendarClock";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { useState } from "react";
import { orderActions } from "api/orders/actions";

interface Props {
  close: () => void;
  unloading: Unloading;
}

export const EditUnloadingsOrders = ({ close, unloading }: Props) => {
  const search = queryString.stringify({
    unloading: unloading.id,
  });
  const { data: orders, error, isLoading } = wmsUnloadingActions.useUnloadingOrdersInfo(search);
  const columns = useUnloadingOrdersColumns(unloading);
  const [warehouseDeliveryDate, setWarehouseDeliveryDate] = useState<string | null>(null);
  const bulkWarehouseDeliveryDateMutation = orderActions.useBulkPatchOrders();

  if (isLoading)
    return (
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Edytuj zamówienia
          </Typography>
        }
        width={1140}
      >
        <div className="py-3 d-flex align-items-center justify-content-center">
          <Spinner size={40} />
        </div>
      </Modal>
    );

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Edytuj zamówienia
          </Typography>
        }
        width={1140}
      >
        <div className="py-3 d-flex align-items-center justify-content-center">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  if (!orders) return null;

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Edytuj zamówienia
        </Typography>
      }
      width={1140}
    >
      <div className="d-flex flex-column">
        <div className="py-2 px-3">
          <div className={cx("pb-2", styles.editUnloadingOrdersTable)}>
            <Table<UnloadingOrder>
              rows={orders}
              columns={columns}
              isLoading={false}
              error={null}
              uiSchema={comfortableListUiSchema}
              overrides={() => {
                return {
                  row: row => {
                    return {
                      className: cx({
                        dashedTableRow: row.isCanceled,
                      }),
                    };
                  },
                };
              }}
            />
          </div>
          <div className="d-flex flex-column gap-2 mt-2">
            <Typography
              className="text-uppercase"
              color="neutralBlack48"
              fontSize="10"
              fontWeight="600"
            >
              Wskaż datę dostarczenia do magazynu dla wszystkich zamówień:
            </Typography>
            <div className="d-flex align-items-baseline gap-3">
              <DatePicker
                isNullable={false}
                label="Data dostarczenia do magazynu"
                onChange={date => setWarehouseDeliveryDate(dateUtils.formatDateToIso(date!))}
                placeholder="Wybierz datę"
                value={warehouseDeliveryDate}
              />
              <Button
                className="text-uppercase"
                disabled={!warehouseDeliveryDate}
                isLoading={bulkWarehouseDeliveryDateMutation.isLoading}
                onClick={() =>
                  bulkWarehouseDeliveryDateMutation.mutate({
                    orders: orders.map(order => order.id),
                    warehouseDeliveryDate,
                  })
                }
                startIcon={MdiCalendarClock}
                size="small"
                variant="deepPurple"
              >
                Zmień datę
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2 p-3">
          <Button className="text-uppercase" onClick={close} size="medium" variant="deepPurple">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
