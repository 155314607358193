import { Route } from "components/utils";
import { TradingDocumentHistory } from "pages/tradingDocuments/shared/history/TradingDocumentHistory";
import { ImportPurchaseInvoices } from "pages/tradingDocuments/importPurchaseInvoices/ImportPurchaseInvoices";
import { MyInvoicesToConfirm } from "pages/tradingDocuments/importPurchaseInvoices/myImportPurchaseInvoicesList/MyInvoicesToConfirm";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const ImportInvoices = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={TradingDocumentHistory}
        exact
        path={`${match.path}/:tradingDocumentID/history`}
        title="Milo - historia faktury zakupowej"
      />
      <Route
        component={MyInvoicesToConfirm}
        exact
        path={`${match.path}/list/:tab`}
        title="Milo - moje faktury do zatwierdzenia"
      />
      <Route
        component={ImportPurchaseInvoices}
        exact
        path={`${match.path}/check`}
        title="Milo - import faktur"
      />
    </Switch>
  );
};
