import { filterFactory } from "components/utils/withFilters";
import { tradingDocumentFilterFactory } from "pages/tradingDocuments/shared/filtersFactory/filtersFactory";

export const filterList = filterFactory(({ FilterType }) => {
  const factory = tradingDocumentFilterFactory(FilterType);

  return [
    {
      type: FilterType.DateRange,
      label: "data wystawienia",
      name: ["paymentDateFrom", "paymentDateTo"],
      showMonths: true,
    },
    factory.customers,
    {
      type: FilterType.Text,
      label: "NIP",
      placeholder: "Szukaj NIP",
      name: "taxId",
    },
    factory.countryCode,
  ];
});
