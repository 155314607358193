import { TradingDocument } from "api/trading-documents/models";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { useEditTradingDocumentItemValue } from "../hooks/useEditTradingDocumentItemValue";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { GTUCode } from "api/trading-documents/enums";

export const useTradingDocumentItemsColumns = (tradingDocument: TradingDocument) => {
  const { editItemValueMutation } = useEditTradingDocumentItemValue("gtuCode", tradingDocument.id);

  return useCreateTableColumns<TradingDocument["items"][number]["tradingDocumentItems"][number]>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa towaru / usługi",
          size: 300,
          typographyProps: {
            fontSize: "16",
          },
        }),
        columnHelper.accessor(row => row, {
          header: "kod GTU",
          size: 250,
          cell: info => {
            const item = info.getValue();
            return (
              <div className="w-100 my-2">
                <Select
                  items={tradingDocumentConstants.gtuCodesOptions}
                  selected={item.gtuCode}
                  onChange={gtuCode =>
                    editItemValueMutation.mutate({
                      id: item.id,
                      gtuCode: gtuCode as GTUCode,
                    })
                  }
                />
              </div>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
