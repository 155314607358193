import { CarrierOrder } from "api/logistics/carrierOrders/models";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { useCarrierOrdersListColumns } from "./hooks/useCarrierOrdersListColumns";
import { RightPanel } from "./rightPanel/RightPanel";
import { filterFactory, withFilters } from "components/utils/withFilters";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { carrierOrdersFileFactory } from "api/logistics/carrierOrders/api";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadCsv } from "components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { carrierOrdersActions } from "api/logistics/carrierOrders/actions";

const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.SelectUser,
    kind: "carrier",
    label: "przewoźnik",
    multiple: true,
    name: "carrier",
    searchBy: "users",
  },
]);

export const CarrierOrdersList = withFilters(filterList, () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);
  const {
    data: carrierOrders,
    error,
    isLoading,
    pagination,
  } = carrierOrdersActions.useCarrierOrders(search);
  const columns = useCarrierOrdersListColumns(carrierOrders);
  const tableMultiSelect = useTableMultiSelect({ rows: carrierOrders });
  const tableProps = useTableFeatureConnector({
    rows: carrierOrders,
    withDrawer: "logisticsCarriersOrders",
    withMultiSelect: tableMultiSelect,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj wśród zleceń do przewoźników",
          tags: [],
        }}
        viewLabel="LOGISTICS_CARRIER_ORDERS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<CarrierOrder>
          columns={columns}
          isLoading={isLoading}
          error={error}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
        <TableToolbar
          close={tableMultiSelect.clear}
          numberOfSelectedItems={tableMultiSelect.selectedRows.length}
        >
          <FileDownloadHandler
            factoryFn={() =>
              carrierOrdersFileFactory.carrierOrderCSV(
                tableMultiSelect.selectedRows.map(selectedRow => selectedRow.id),
              )
            }
            type="csv"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz CSV ze zleceniami przewozu">
                <IconButton
                  icon={MdiDownloadCsv}
                  isLoading={isLoading}
                  onClick={download}
                  theme="dark"
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
        </TableToolbar>
      </div>
    </PageWrapper>
  );
});
