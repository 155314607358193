import { CarrierOrderPaymentMethod } from "api/logistics/carrierOrders/models";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

const getPaymentMethods = (method: CarrierOrderPaymentMethod) => {
  switch (method) {
    case CarrierOrderPaymentMethod.CASH:
      return "Gotówka";
    case CarrierOrderPaymentMethod.ONLINE:
      return "Przelew";
    default:
      const exhaustiveCheck: never = method;
      console.error(`Unhandled payment method: ${exhaustiveCheck}`);
      return EMPTY_VALUE;
  }
};

export const carriersContstants = {
  getPaymentMethods,
};
