import { Pagination } from "api/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { carsKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";
import { Car } from "./models";

const getCars = (search: string = ""): ApiFetcher<Pagination<Car>> => ({
  key: carsKeys.car.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/cars/items" + search,
    }),
});

export const carsApi = {
  getCars,
};
