import { PopupMenu } from "components/common/popupMenu";
import popUpstyles from "./MoreInfoButton.module.css";
import { Button } from "components/common";
import moreOptionsIcon from "assets/images/threeDots.svg";

interface Props {
  children: React.ReactNode;
}

/**
 * @deprecated
 */
export const MoreInfoButton = ({ children }: Props) => {
  return (
    <PopupMenu
      customStyles={popUpstyles}
      customButton={
        <Button
          data-for="external-options"
          data-event="click"
          data-event-off="mouseleave"
          data-tip="2"
          kind="transparent-black"
          size="square-s"
        >
          <div className="btnBase btnBaseSmall">
            <img
              alt="Więcej opcji"
              src={moreOptionsIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
      }
      dataFor="external-options"
    >
      <div className={popUpstyles.optionList}>{children}</div>
    </PopupMenu>
  );
};
