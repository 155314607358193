import { Marker as MapMarker } from "@react-google-maps/api";
import { useAssignItem } from "../hooks/useAssignItem";
import { useUnassignOrder } from "../hooks/useUnassignOrder";
import { useInfoWindow } from "../hooks/useInfoWindow";
import { useRouteViewState } from "../routeCreatorState";
import { Route } from "api/routes/models";
import { RoutePoint } from "./Map";
import { useToastr } from "hooks";
import hatIcon from "assets/images/mapMarkers/hat.png";
import withAssemblyIcon from "assets/images/withAssembly.png";
import withBringingIcon from "assets/images/withBringing.png";
import { WarehouseIconKind } from "api/wms/models";

interface Props {
  routePoint: RoutePoint;
  route: Route;
  visible?: boolean;
  isLoading: boolean;
  index: number;
}

export const Marker = ({ routePoint, visible = true, route, isLoading, index }: Props) => {
  const { toggleInfoWindow } = useInfoWindow();
  const { unassignOrder } = useUnassignOrder(route);
  const { assignOrder } = useAssignItem(route);
  const actions = useRouteViewState("slave", state => state.actions);
  const toastr = useToastr();

  const handleRightClick = () => {
    if (isLoading) return null;
    if (routePoint.type === "passpoint") {
      actions.openLoader("Trwa odpinanie punktu przelotowego");
      unassignOrder(routePoint.id, "passpoint");
    }

    if (!routePoint.isHidden) {
      if (routePoint.isPinned) {
        actions.openLoader("Trwa odpinanie punktu");
        unassignOrder(routePoint.id, "order");
      } else {
        actions.openLoader("Trwa przypinanie punktu");
        assignOrder({
          id: routePoint.id,
          point: routePoint.point,
          isAssemblingRequested: routePoint.isAssemblingRequested,
          hasCarrying: routePoint.hasCarrying,
          type: "order",
          address: null,
          warehouseDeliveryDetails: routePoint.warehouseDeliveryDetails,
          hasGuaranteedDeliveryBeforeChristmas: routePoint.hasGuaranteedDeliveryBeforeChristmas,
          warehouse: routePoint.warehouse,
        });
      }
    }

    if (routePoint.isHidden) {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: "Punkt jest ukryty",
      });
    }
  };

  return (
    <>
      {routePoint.hasGuaranteedDeliveryBeforeChristmas && (
        <MapMarker
          position={routePoint.point}
          visible={visible}
          options={{ zIndex: index + 2 }}
          icon={{
            url: hatIcon,
            anchor: getGuaranteedDeliveryBeforeChristmasMarkerAnchor(
              routePoint?.warehouse?.icon || WarehouseIconKind.ROUND,
            ),
          }}
        />
      )}
      {routePoint.isAssemblingRequested && (
        <MapMarker
          position={routePoint.point}
          visible={visible}
          options={{ zIndex: index + 2 }}
          icon={{
            url: withAssemblyIcon,
            anchor: getAssemblingMarkerAnchor(routePoint.hasCarrying),
          }}
        />
      )}
      {routePoint.hasCarrying && (
        <MapMarker
          position={routePoint.point}
          visible={visible}
          options={{ zIndex: index + 2 }}
          icon={{
            url: withBringingIcon,
            anchor: getCarryingMarkerAnchor(routePoint.isAssemblingRequested),
          }}
        />
      )}

      <MapMarker
        position={routePoint.point}
        visible={visible}
        onClick={() =>
          routePoint.type === "order" && toggleInfoWindow(Number(routePoint.id), routePoint.point)
        }
        onRightClick={handleRightClick}
        options={{ zIndex: index + 1 }}
        icon={routePoint.icon}
        label={routePoint.label}
      />
    </>
  );
};

function getAssemblingMarkerAnchor(hasCarrying: boolean) {
  if (hasCarrying) {
    return new google.maps.Point(-4, 18);
  }
  return new google.maps.Point(-4, 9);
}

function getCarryingMarkerAnchor(isAssemblingRequested: boolean) {
  if (isAssemblingRequested) {
    return new google.maps.Point(-4, 0);
  }
  return new google.maps.Point(-4, 9);
}

const getGuaranteedDeliveryBeforeChristmasMarkerAnchor = (iconKind: WarehouseIconKind) => {
  switch (iconKind) {
    case WarehouseIconKind.DIAMOND:
    case WarehouseIconKind.TRIANGLE:
    case WarehouseIconKind.PENTAGON:
      return new google.maps.Point(15, 19);
    case WarehouseIconKind.ROUND:
    case WarehouseIconKind.SQUARE:
      return new google.maps.Point(21, 23);
    default:
      const exhaustiveCheck: never = iconKind;
      console.error(`Unhandled warehouse icon: ${exhaustiveCheck}`);
      return new google.maps.Point(21, 23);
  }
};
