import { Pagination } from "api/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { queryFetch } from "apiConnectors/queryFetch";
import { AddFinancesPayment, FinancesPaymentListItem } from "./models";
import { financesPaymentsKeys } from "./keys";
import { fileFactoryUtils } from "utilities/fileFactory";

const getFinancesPayments = (
  search: string = "",
): ApiFetcher<Pagination<FinancesPaymentListItem>> => ({
  key: financesPaymentsKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/payments/items" + search,
    }),
});

const postFinancesPayment = (data: AddFinancesPayment) =>
  queryFetch<FinancesPaymentListItem>({
    method: "POST",
    url: "/finances/payments/items",
    data,
  });

const deleteFinancesPayment = (paymentId: FinancesPaymentListItem["id"]) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/payments/items/" + paymentId,
  });

export const financesPaymentsFileFactory = (() => {
  return {
    proofOfPaymentPdf: (ids: FinancesPaymentListItem["id"][], signature?: string) => ({
      url: `/finances/payments/proof-of-payment-pdf?tradingDocumentPaymentIds=${encodeURIComponent(
        ids.join(","),
      )}`,
      name:
        signature !== undefined
          ? `KP-${
              Boolean(signature.length) ? `${fileFactoryUtils.formatSignature(signature)}-` : ""
            }${fileFactoryUtils.getDate()}`
          : `Grupa-KP-${fileFactoryUtils.getDate()}`,
    }),
  };
})();

export const financesPaymentsApi = {
  deleteFinancesPayment,
  getFinancesPayments,
  postFinancesPayment,
};
