import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DatePicker } from "components/utils/datePicker";
import { RightPanelSection } from "components/utils/drawer";
import { dateUtils } from "utilities";
import styles from "../RightPanel.module.css";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { countryToFlagDict } from "constants/countriesFlags";
import { DatePicker as DesignSystemDatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useToggle } from "hooks";
import { ExchangeRateCorrection } from "./exchangeRateCorrection/ExchangeRateCorrection";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";

interface Props {
  tradingDocument: TradingDocument;
}

export const AmountSection = ({ tradingDocument }: Props) => {
  const updateVatObligationDateMutation = usePatchTradingDocumentMutation();
  const exchangeRateCorrectionModal = useToggle();

  return (
    <RightPanelSection>
      <InfoLabel title="kraj">
        {Boolean(tradingDocument.countryCode) ? (
          <img alt="Flaga kraju" src={countryToFlagDict[tradingDocument.countryCode]} />
        ) : (
          <EmptyValue fontSize="14" fontWeight="700" />
        )}
      </InfoLabel>
      <InfoLabel title="kwota">
        <div className="d-flex align-items-center">
          {Boolean(Object.keys(tradingDocument.amountSummary).length) ? (
            <FinanceAmountFormat
              decimalClassName="fw-700 fs-16"
              integerClassName="fw-700 fs-16"
              value={tradingDocument.amountSummary.totalWithTax}
            />
          ) : (
            <EmptyValue className="mr-2" fontSize="16" fontWeight="700" />
          )}
          <Typography
            className={styles.adjustedLineHeight}
            color="neutralBlack48"
            fontSize="16"
            fontWeight="700"
          >
            {tradingDocument.currency}
          </Typography>
        </div>
      </InfoLabel>
      <InfoLabel title="kwota VAT">
        {Boolean(Object.keys(tradingDocument.amountSummary).length) ? (
          <FinanceAmountFormat
            decimalClassName="body-14-600"
            integerClassName="body-14-600"
            value={tradingDocument.amountSummary.totalTax}
          />
        ) : (
          <EmptyValue className="mr-1" fontSize="14" />
        )}
        <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
          {tradingDocument.currency}
        </Typography>
      </InfoLabel>
      <InfoLabel title="waluta">
        <Typography fontSize="14" fontWeight="500">
          {tradingDocument.currency || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="kurs wymiany">
        <Typography fontSize="14" fontWeight="500">
          {tradingDocument.lastValidExchangeRate ?? EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="data wymiany">
        <div className="d-flex align-items-center gap-2">
          <Typography fontSize="14" fontWeight="500">
            {tradingDocument.lastValidExchangeRateDate ?? EMPTY_VALUE}
          </Typography>
          {tradingDocument.invoiceType === "SALES" && tradingDocument.currency !== "PLN" && (
            <Button
              className="text-uppercase"
              onClick={exchangeRateCorrectionModal.open}
              size="small"
              variant="gray"
            >
              Skoryguj datę i kurs wymiany
            </Button>
          )}
        </div>
      </InfoLabel>
      <InfoLabel title="rejestr VAT">
        <DatePicker
          showMonthYearPicker
          look="common"
          onChange={date => {
            if (!date) {
              return updateVatObligationDateMutation.mutate({
                id: tradingDocument.id,
                vatTaxObligationDate: null,
              });
            }
            return updateVatObligationDateMutation.mutate({
              id: tradingDocument.id,
              vatTaxObligationDate: dateUtils.formatDateToIso(date),
            });
          }}
          customDateFormat="MM/yyyy"
          value={tradingDocument.vatTaxObligationDate ?? ""}
        />
      </InfoLabel>
      <InfoLabel title="obowiązek podatkowy">
        {tradingDocument.invoiceType === "SALES" || tradingDocument.invoiceType === "RECEIPT" ? (
          <DesignSystemDatePicker.Async
            isNullable
            mutationHook={usePatchTradingDocumentMutation}
            transformQueryData={date => ({
              id: tradingDocument.id,
              pitTaxObligationDate: date ? dateUtils.formatDateToIso(date) : null,
            })}
            value={tradingDocument.pitTaxObligationDate}
          />
        ) : (
          <Typography fontSize="14" fontWeight="500">
            {tradingDocument.pitTaxObligationDate
              ? dateUtils.formatDateToDisplay(tradingDocument.pitTaxObligationDate)
              : EMPTY_VALUE}
          </Typography>
        )}
      </InfoLabel>
      <InfoLabel
        title={`${tradingDocument.sellerBankName ? tradingDocument.sellerBankName : "bank"} [${
          tradingDocument.currency ? tradingDocument.currency : EMPTY_VALUE
        }]`}
      >
        {tradingDocument.sellerBankAccountNumber ? (
          <div>****&nbsp;{tradingDocument.sellerBankAccountNumber.slice(-4)}</div>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      {(tradingDocument.invoiceType === "SALES" || tradingDocument.invoiceType === "PURCHASE") && (
        <InfoLabel className="mt-2" title="MPP">
          <Checkbox.Async
            checked={tradingDocument.isSplitPayment}
            label=""
            mutationHook={usePatchTradingDocumentMutation}
            size="sm"
            transformQueryData={isSplitPayment => ({
              id: tradingDocument.id,
              isSplitPayment,
            })}
          />
        </InfoLabel>
      )}
      {exchangeRateCorrectionModal.isOpen && (
        <ExchangeRateCorrection
          close={exchangeRateCorrectionModal.close}
          tradingDocument={tradingDocument}
        />
      )}
    </RightPanelSection>
  );
};
