import { filterFactory } from "components/utils/withFilters";
import { tradingDocumentFilterFactory } from "../shared/filtersFactory/filtersFactory";

export const filterList = filterFactory(({ FilterType }) => {
  const factory = tradingDocumentFilterFactory(FilterType);
  return [
    factory.invoiceDeliveryDateRange,
    factory.invoiceIssueDateRange,
    factory.countryCode,
    factory.salesAccount,
    factory.routeSignature,
    factory.orderGroupSignature,
    factory.internalBusinessEntities,
    factory.hasAllOrdersDelivered,
    factory.hasRecipientTaxID,
    factory.hasBuyerTaxID,
    factory.isFiscalized,
    factory.canBeFiscalized,
    factory.status,
    factory.customerKind,
    factory.correspondingReceiptPrintingStatus,
    factory.taxProcedure,
    factory.isPaid,
    factory.currency,
    factory.pickupID,
    factory.shipmentTrackingNumber,
    factory.paymentType,
    factory.paymentProvider,
    factory.isAssigned,
    factory.customers,
    factory.transferDate,
    factory.isXMLOptimaDownloaded,
    factory.isPaymentOverdue,
    factory.transferNumber,
  ];
});
