import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { TradingDocumentHistory } from "../pages/tradingDocuments/shared/history/TradingDocumentHistory";
import { Receipts as ReceiptsList } from "pages/tradingDocuments/receiptsList/Receipts";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const Receipts = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={TradingDocumentHistory}
        exact
        path={`${match.path}/:tradingDocumentID/history`}
        title="Milo - historia paragonu"
      />
      <Route
        component={ReceiptsList}
        exact
        path={`${match.path}/list/:tab`}
        title="Milo - paragony"
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
