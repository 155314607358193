import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiRubric } from "components/miloDesignSystem/atoms/icons/MdiRubric";
import { PaymentsRouting } from "routes/finances/PaymentsRouting";

export const payments: ModuleLink = {
  url: "payments",
  label: "Dokumenty kasowe - KP",
  icon: MdiRubric,
  routing: PaymentsRouting,
};
