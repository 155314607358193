import { tradingDocumentsActions } from "api/trading-documents/actions";
import { MyPurchaseInvoiceToReview, TradingDocument } from "api/trading-documents/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiReceiptLong } from "components/miloDesignSystem/atoms/icons/MdiReceiptLong";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { useQuery } from "hooks";

interface Props {
  hasCorrespondingReceipt?: boolean;
  selectedRows: TradingDocument[] | MyPurchaseInvoiceToReview[];
}

export const FiscalizeReceipts = ({ hasCorrespondingReceipt, selectedRows }: Props) => {
  const { query } = useQuery();
  const panelId = query.panelId;
  const fiscalizeReceiptsMutation = tradingDocumentsActions.useFiscalizeReceipts(panelId);

  return (
    <Tooltip title="Fiskalizuj">
      <IconButton
        icon={MdiReceiptLong}
        onClick={() =>
          fiscalizeReceiptsMutation.mutate({
            tradingDocuments: hasCorrespondingReceipt
              ? selectedRows.map(tradingDocument => tradingDocument.id)
              : undefined,
            receiptsIds: !hasCorrespondingReceipt
              ? selectedRows.map(tradingDocument => tradingDocument.id)
              : undefined,
          })
        }
        variant="transparent"
        theme="dark"
      />
    </Tooltip>
  );
};
