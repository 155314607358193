import { DraftDocumentPosition, TradingDocument } from "api/trading-documents/models";
import styles from "./DocumentPositions.module.css";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useDocumentPositionsColumns } from "./useDocumentPositionsColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import invoicesIcon from "assets/images/invoices.svg";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

interface Props {
  draftInvoice: TradingDocument;
}

export const DocumentPositions = ({ draftInvoice }: Props) => {
  const normalizedRows = getNormalizedTradingDocumentItems(draftInvoice.items);
  const columns = useDocumentPositionsColumns(draftInvoice, normalizedRows);
  const tableProps = useTableFeatureConnector({
    rows: normalizedRows.sort((a, b) => a.displayPosition - b.displayPosition),
  });

  return (
    <div className={styles.tableContainer}>
      <Table<DraftDocumentPosition>
        columns={columns}
        isLoading={false}
        error={null}
        uiSchema={comfortableListUiSchema}
        {...tableProps}
        overrides={() => ({
          ...tableProps?.overrides?.(),
          noResultComponent: (
            <div className="d-flex flex-column align-items-center pt-3 pb-4">
              <img alt="Brak pozycji" className={styles.emptyIcon} src={invoicesIcon} />
              <Typography fontSize="16" fontWeight="700" className="my-2">
                Jeszcze nic nie wybrano
              </Typography>
              <Typography color="neutralBlack48" fontSize="14" fontWeight="500">
                Dodaj pierwszą pozycję, zamówienie lub produkt korzystając z opcji nad tabelą
              </Typography>
            </div>
          ),
        })}
      />
    </div>
  );
};

const getNormalizedTradingDocumentItems = (
  items: TradingDocument["items"],
): DraftDocumentPosition[] => {
  return items.flatMap(item =>
    item.tradingDocumentItems.map(tradingDocumentItem => {
      return {
        ...tradingDocumentItem,
        order: item.order,
      };
    }),
  );
};
