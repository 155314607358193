import { WarehouseIconKind } from "api/wms/models";
import { MdiDiamond } from "components/miloDesignSystem/atoms/icons/MdiDiamond";
import { MdiPentagon } from "components/miloDesignSystem/atoms/icons/MdiPentagon";
import { MdiRound } from "components/miloDesignSystem/atoms/icons/MdiRound";
import { MdiSquare } from "components/miloDesignSystem/atoms/icons/MdiSquare";
import { MdiTriangle } from "components/miloDesignSystem/atoms/icons/MdiTriangle";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

const getWarehouseIcons = (icon: WarehouseIconKind) => {
  switch (icon) {
    case WarehouseIconKind.DIAMOND:
      return MdiDiamond;
    case WarehouseIconKind.PENTAGON:
      return MdiPentagon;
    case WarehouseIconKind.ROUND:
      return MdiRound;
    case WarehouseIconKind.SQUARE:
      return MdiSquare;
    case WarehouseIconKind.TRIANGLE:
      return MdiTriangle;
    default:
      const exhaustiveCheck: never = icon;
      console.error(`Unhandled warehouse icon: ${exhaustiveCheck}`);
      return EMPTY_VALUE;
  }
};

export const warehouseConstants = {
  getWarehouseIcons,
};
