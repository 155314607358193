import { useCarrierPatchMutation } from "api/logistics/carriers/hooks";
import { Carrier, kindDict } from "api/logistics/carriers/models";
import { InfoLabel } from "components/common/infoLabel";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { generateDictBasedOnFactoryAndEnum } from "utilities";
import { carriersContstants } from "constants/carriers";
import { CarrierOrderPaymentMethod } from "api/logistics/carrierOrders/models";
import { TextField } from "components/miloDesignSystem/atoms/textField";

interface Props {
  carrier: Carrier;
}

export const GeneralInfoSection = ({ carrier }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2 mb-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {carrier.name}
        </Typography>
        <Tag label={kindDict[carrier.kind].label} variant={kindDict[carrier.kind].variant} />
      </div>
      <InfoLabel title="nazwa">
        <TextField.Async
          containerClassName="w-100 pl-4"
          mutationHook={useCarrierPatchMutation}
          placeholder="Wpisz nazwę przewoźnika"
          size="small"
          transformQueryData={name => ({
            id: carrier.id,
            toUpdate: {
              name,
            },
          })}
          value={carrier.name}
        />
      </InfoLabel>
      <InfoLabel title="nazwa firmy">
        <TextField.Async
          containerClassName="w-100 pl-4"
          mutationHook={useCarrierPatchMutation}
          placeholder="Wpisz nazwę firmy"
          size="small"
          transformQueryData={companyName => ({
            id: carrier.id,
            toUpdate: {
              companyName,
            },
          })}
          value={carrier.companyName}
        />
      </InfoLabel>
      <InfoLabel title="NIP">
        <TextField.Async
          containerClassName="w-100 pl-4"
          mutationHook={useCarrierPatchMutation}
          placeholder="Wpisz NIP"
          size="small"
          transformQueryData={taxId => ({
            id: carrier.id,
            toUpdate: {
              taxId,
            },
          })}
          value={carrier.taxId}
        />
      </InfoLabel>
      <InfoLabel title="numer telefonu">
        <TextField.Async
          containerClassName="w-100 pl-4"
          mutationHook={useCarrierPatchMutation}
          placeholder="Wpisz numer telefonu"
          size="small"
          transformQueryData={phone => ({
            id: carrier.id,
            toUpdate: {
              phone,
            },
          })}
          value={carrier.phone}
        />
      </InfoLabel>
      <InfoLabel title="e-mail">
        <TextField.Async
          containerClassName="w-100 pl-4"
          mutationHook={useCarrierPatchMutation}
          placeholder="Wpisz e-mail"
          size="small"
          transformQueryData={email => ({
            id: carrier.id,
            toUpdate: {
              email,
            },
          })}
          value={carrier.email}
        />
      </InfoLabel>
      <InfoLabel title="adres">
        <TextField.Async
          containerClassName="w-100 pl-4"
          mutationHook={useCarrierPatchMutation}
          placeholder="Wpisz adres"
          size="small"
          transformQueryData={street => ({
            id: carrier.id,
            toUpdate: {
              street,
            },
          })}
          value={carrier.street}
        />
      </InfoLabel>
      <InfoLabel title="kod pocztowy">
        <TextField.Async
          containerClassName="w-100 pl-4"
          mutationHook={useCarrierPatchMutation}
          placeholder="Wpisz kod pocztowy"
          size="small"
          transformQueryData={postCode => ({
            id: carrier.id,
            toUpdate: {
              postCode,
            },
          })}
          value={carrier.postCode}
        />
      </InfoLabel>
      <InfoLabel title="miasto">
        <TextField.Async
          containerClassName="w-100 pl-4"
          mutationHook={useCarrierPatchMutation}
          placeholder="Wpisz miasto"
          size="small"
          transformQueryData={city => ({
            id: carrier.id,
            toUpdate: {
              city,
            },
          })}
          value={carrier.city}
        />
      </InfoLabel>
      <InfoLabel title="metoda płatności">
        <Select.Async
          items={generateDictBasedOnFactoryAndEnum(
            carriersContstants.getPaymentMethods,
            CarrierOrderPaymentMethod,
          ).map(({ value, key }) => ({
            text: value,
            type: MenuItemType.TEXT,
            value: String(key),
          }))}
          mutationHook={useCarrierPatchMutation}
          selected={carrier.paymentMethod}
          transformQueryData={paymentMethod => ({
            id: carrier.id,
            toUpdate: {
              paymentMethod: paymentMethod as string,
            },
          })}
        />
      </InfoLabel>
      <InfoLabel title="termin płatności [dni]">
        <TextField.Async
          mutationHook={useCarrierPatchMutation}
          size="small"
          transformQueryData={paymentDeadlineDays => ({
            id: carrier.id,
            toUpdate: {
              paymentDeadlineDays: Number(paymentDeadlineDays),
            },
          })}
          type="number"
          value={carrier.paymentDeadlineDays}
        />
      </InfoLabel>
      <InfoLabel title="rodzaj">
        <Select.Async
          items={Object.entries(kindDict).map(([kind, { label }]) => ({
            value: kind,
            text: label,
            type: MenuItemType.TEXT,
          }))}
          mutationHook={useCarrierPatchMutation}
          selected={carrier.kind}
          transformQueryData={kind => ({
            id: carrier.id,
            toUpdate: {
              kind: kind as Carrier["kind"],
            },
          })}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};
