import { carrierOrdersActions } from "api/logistics/carrierOrders/actions";
import { Route } from "api/routes/models";
import { InfoLabel } from "components/common/infoLabel/InfoLabel";
import { Link } from "components/miloDesignSystem/atoms/link";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { queryString } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  route: Route;
}

export const CarrierOrderSection = ({ route }: Props) => {
  const search = queryString.stringify({
    route: route.id,
  });
  const { data: carrierOrders, error, isLoading } = carrierOrdersActions.useCarrierOrders(search);

  if (isLoading)
    return (
      <div className="mt-2">
        <InfoLabel title="zlecenie do przewoźnika">
          <Spinner size={20} />
        </InfoLabel>
      </div>
    );

  if (error || !carrierOrders || !Boolean(carrierOrders.length))
    return (
      <div className="mt-2">
        <InfoLabel title="zlecenie do przewoźnika">
          <EmptyValue fontSize="14" />
        </InfoLabel>
      </div>
    );

  return (
    <div className="mt-2">
      <InfoLabel title="zlecenie do przewoźnika">
        <Link
          fontSize="14"
          fontWeight="700"
          to={`/logistics/carrier-orders/list/all?panelId=${carrierOrders[0].id}`}
        >
          {carrierOrders[0].route.signature}
        </Link>
      </InfoLabel>
    </div>
  );
};
