import { DEFAULT_OPTION_MENU_ICON_SIZE } from "CONSTANTS";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { getInvoiceLabel, tradingDocumentFileFactory } from "api/trading-documents/calls";
import { TradingDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { useFileDownload } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiDownloadFakir } from "components/miloDesignSystem/atoms/icons/MdiDownloadFakir";
import { MdiDownloadRevisor } from "components/miloDesignSystem/atoms/icons/MdiDownloadRevisor";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import { MdiDraw } from "components/miloDesignSystem/atoms/icons/MdiDraw";
import { MdiEditDocument } from "components/miloDesignSystem/atoms/icons/MdiEditDocument";
import { MdiFileCopy } from "components/miloDesignSystem/atoms/icons/MdiFileCopy";
import { MdiHistory } from "components/miloDesignSystem/atoms/icons/MdiHistory";
import { MenuItem, MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { useSelector } from "hooks";
import { useNavigate } from "hooks/useNavigate";
import { fileFactoryUtils } from "utilities/fileFactory";

interface Props {
  close: () => void;
  tradingDocument: TradingDocument;
}

export const TradingDocumentOptionsMenuFactory = ({ close, tradingDocument }: Props) => {
  const navigate = useNavigate();
  const { download: duplicateDownload, isLoading: isDuplicateDownloadLoading } = useFileDownload({
    factoryFn: () => tradingDocumentFileFactory.salesInvoiceDuplicatePdf(tradingDocument),
    type: "pdf",
  });
  const { download: downloadXML, isLoading: isXMLDownloadLoading } = useFileDownload({
    factoryFn: () =>
      tradingDocumentFileFactory.optimaXml(
        {
          invoiceType: tradingDocument.invoiceType,
          type: tradingDocument.type,
        },
        [tradingDocument.id],
        `Faktura-${fileFactoryUtils.formatSignature(
          tradingDocument.signature,
        )}-${fileFactoryUtils.getDate()}`,
      ),
    type: "xml",
  });
  const { download: downloadRevisor, isLoading: isRevisorDownloadLoading } = useFileDownload({
    factoryFn: () => tradingDocumentFileFactory.revisorPdf([tradingDocument.id]),
    type: "epp",
  });
  const { download: downloadFakir, isLoading: isFakirDownloadLoading } = useFileDownload({
    factoryFn: () => tradingDocumentFileFactory.fakirXml([tradingDocument.id]),
    type: "xml",
  });
  const me = useSelector(store => store.auth.user!);
  const deleteTradingDocumentMutation = tradingDocumentsActions.useDeleteTradingDocument(
    close,
    tradingDocument.signature,
  );

  return {
    tradingDocumentHistory: (panelId: UUID) =>
      ({
        type: MenuItemType.ICON,
        icon: <MdiHistory size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />,
        onClick: () =>
          navigate(
            `/finances/${getInvoiceLabel(
              tradingDocument.invoiceType,
              tradingDocument.type,
            )}/${panelId}/history`,
            {
              tradingDocument,
            },
          ),
        options: {
          typographyProps: {
            fontWeight: "600",
          },
        },
        text: `Pokaż historię ${tradingDocument.type === "RECEIPT" ? "paragonu" : "faktury"}`,
      } as MenuItem),
    downloadDuplicatePDF: () =>
      ({
        type: MenuItemType.ICON,
        icon: isDuplicateDownloadLoading ? (
          <Spinner size={DEFAULT_OPTION_MENU_ICON_SIZE} />
        ) : (
          <MdiFileCopy size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />
        ),
        onClick: duplicateDownload,
        text: `Pobierz duplikat ${tradingDocument.type === "RECEIPT" ? "paragonu" : "faktury"}`,
        options: {
          typographyProps: {
            fontWeight: "600",
          },
        },
      } as MenuItem),
    downloadTradingDocumentXML: () =>
      ({
        type: MenuItemType.ICON,
        icon: isXMLDownloadLoading ? (
          <Spinner size={DEFAULT_OPTION_MENU_ICON_SIZE} />
        ) : (
          <MdiDownloadXml size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />
        ),
        onClick: downloadXML,
        options: {
          typographyProps: {
            fontWeight: "600",
          },
        },
        text: "Pobierz XML z danymi faktury",
      } as MenuItem),
    downloadRevisor: () =>
      ({
        type: MenuItemType.ICON,
        icon: isRevisorDownloadLoading ? (
          <Spinner size={DEFAULT_OPTION_MENU_ICON_SIZE} />
        ) : (
          <MdiDownloadRevisor size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />
        ),
        onClick: downloadRevisor,
        options: {
          typographyProps: {
            fontWeight: "600",
          },
        },
        text: "Pobierz plik Revisor",
      } as MenuItem),
    downloadFakir: () =>
      ({
        type: MenuItemType.ICON,
        icon: isFakirDownloadLoading ? (
          <Spinner size={DEFAULT_OPTION_MENU_ICON_SIZE} />
        ) : (
          <MdiDownloadFakir size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />
        ),
        onClick: downloadFakir,
        options: {
          typographyProps: {
            fontWeight: "600",
          },
        },
        text: "Pobierz plik Wapro fakir",
      } as MenuItem),
    editTransportDocuments: (open: () => void) =>
      ({
        type: MenuItemType.ICON,
        icon: <MdiEditDocument size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />,
        onClick: open,
        options: {
          typographyProps: {
            fontWeight: "600",
          },
        },
        text: "Uzupełnij dokumenty przewozowe (CMR, WDT, specyfikacja)",
      } as MenuItem),
    deleteTradingDocument: () =>
      ({
        type: MenuItemType.ICON,
        icon: deleteTradingDocumentMutation.isLoading ? (
          <Spinner size={DEFAULT_OPTION_MENU_ICON_SIZE} />
        ) : (
          <MdiDelete color="danger600" size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />
        ),
        options: {
          color: "danger600",
          disabled:
            deleteTradingDocumentMutation.isLoading ||
            (tradingDocument.status === "CONFIRMED" && me.type !== "developer"),
        },
        onClick: () => deleteTradingDocumentMutation.mutate(tradingDocument.id),
        text: "Usuń",
        helperText:
          tradingDocument.status === "CONFIRMED" && me.type !== "developer"
            ? "Nie można usunąć zatwierdzonej faktury"
            : undefined,
      } as MenuItem),
    connectPurchaseInvoice: () =>
      ({
        type: MenuItemType.ICON,
        icon: <MdiDraw size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />,
        onClick: () => navigate(`/finances/purchases/connect-invoice/${tradingDocument.id}`),
        options: {
          typographyProps: {
            fontWeight: "600",
          },
        },
        text: "Połącz fakturę z PZ",
      } as MenuItem),
  };
};
