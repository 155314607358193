import { Car } from "api/cars/models";
import { Carrier } from "../carriers/models";
import { UserWithInitials } from "api/users/models";
import { ISODate, ISODateTime, ISOTime, UUID } from "api/types";
import { Order } from "api/orders/models";
import { CURRENCY_TYPE } from "CONSTANTS";

export interface CarrierOrderRoute {
  cargoVolume: string;
  cargoWeight: string;
  deliveryCost: string;
  departureDate: ISODate;
  departureTime: ISOTime;
  id: UUID;
  loadingDate: ISODate;
  loadingTime: ISOTime;
  routeCost: {
    amount: string;
    currency: CURRENCY_TYPE;
  };
  signature: string;
}

export enum CarrierOrderPaymentMethod {
  CASH = "CASH",
  ONLINE = "ONLINE",
}

export interface CarrierOrder {
  car: Pick<Car, "id" | "registrationNumber">;
  carrier: Pick<
    Carrier,
    "id" | "city" | "companyName" | "postCode" | "paymentDeadlineDays" | "paymentMethod" | "street"
  >;
  codSummary: {
    amount: number;
    currency: CURRENCY_TYPE;
  }[];
  counter: number;
  createdAt: ISODateTime;
  createdBy: UserWithInitials;
  drivers: Pick<UserWithInitials, "id" | "firstName" | "lastName">[];
  id: UUID;
  lastDownloadAt: ISODateTime | null;
  lastDownloadBy: UserWithInitials | null;
  orders: Pick<Order, "id" | "signature">[];
  paymentDeadlineDays: number;
  paymentMethod: CarrierOrderPaymentMethod | "";
  paymentsSummaryForRoute:
    | {
        confirmedCarryingProfit: string;
        currency: CURRENCY_TYPE;
        summaryConfirmedDelivery: string;
      }[]
    | null;
  route: CarrierOrderRoute;
  routeCost: {
    carRouteCost: {
      amount: number | null;
      currency: CURRENCY_TYPE;
    };
    customRouteCost: {
      amount: number | null;
      currency: CURRENCY_TYPE;
    };
  };
  signatureUnderDocument: string;
  warehouse: number;
}

export enum EmailMessageStatus {
  CLICKED_LINK = "CLICKED_LINK",
  DECLINED = "DECLINED",
  OPENED = "OPENED",
  SENT = "SENT",
}

export enum EmailMessageKind {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

export interface CarrierOrderEmailMessage {
  createdAt: ISODateTime;
  id: UUID;
  kind: EmailMessageKind;
  message: {
    recipient: string;
    sentBy: UserWithInitials;
    status: EmailMessageStatus;
  };
}

export interface CreateCarrierOrderEmailMessageDTO {
  speditionOrderId: CarrierOrder["id"];
  recipient?: {
    email: string;
  };
}
