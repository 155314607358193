import { useRedux, useSelector } from "hooks";
import blackTheme from "../map/themes/blackTheme.json";
import whiteTheme from "../map/themes/whiteTheme.json";
import greenTheme from "../map/themes/greenTheme.json";

export enum MapTheme {
  BLACK = "black",
  WHITE = "white",
  DEFAULT = "default",
  GREEN = "green",
}

export const useMapTheme = () => {
  const mapTheme = useSelector(state => state.ui.mapTheme);
  const [dispatch, { ui }] = useRedux();

  const theme = getMapTheme(mapTheme);

  return { theme, setTheme: (theme: MapTheme) => dispatch(ui.setMapTheme(theme)), mapTheme };
};

function getMapTheme(theme: MapTheme) {
  switch (theme) {
    case MapTheme.BLACK:
      return blackTheme;
    case MapTheme.WHITE:
      return whiteTheme;
    case MapTheme.GREEN:
      return greenTheme;
    case MapTheme.DEFAULT:
      return "";

    default: {
      const exhaustiveCheck: never = theme;
      console.error(`Unhandled map theme: ${exhaustiveCheck}`);
      return "";
    }
  }
}
