import { TradingDocument } from "api/trading-documents/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTradingDocumentItemsColumns } from "./useTradingDocumentItemsColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { Button } from "components/miloDesignSystem/atoms/button";

interface Props {
  close: () => void;
  tradingDocument: TradingDocument;
}

export const ManageGTUCodes = ({ close, tradingDocument }: Props) => {
  const columns = useTradingDocumentItemsColumns(tradingDocument);
  const tableProps = useTableFeatureConnector({
    rows: tradingDocument.items.flatMap(item => item.tradingDocumentItems),
  });

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj kody GTU do pozycji na fakturze
        </Typography>
      }
      width={650}
    >
      <div className="d-flex flex-column">
        <div className="px-3 py-2">
          <Table<TradingDocument["items"][number]["tradingDocumentItems"][number]>
            columns={columns}
            error={null}
            isLoading={false}
            uiSchema={{
              cell: { height: "36" },
              header: { backgroundColor: "neutralWhite100" },
            }}
            {...tableProps}
            overrides={() => {
              return {
                table: { className: "overflow-visible" },
                tableInnerWrapper: { className: "overflow-visible" },
              };
            }}
          />
        </div>
        <div className="d-flex align-items-center gap-2 p-3">
          <Button className="text-uppercase" onClick={close} size="medium" variant="deepPurple">
            Gotowe
          </Button>
        </div>
      </div>
    </Modal>
  );
};
