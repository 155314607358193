import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiHall = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 3H6V6H5.5V14H2.5L2.5 3Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5 3H6V6H12.5V14.5H15.5V3Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 7V3H22V7H20Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 10V8H22V10H20Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 13V11H22V13H20Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 16V14H22V16H20Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 21.5V17H22V21.5H20Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 5H18V3H20V5Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 21.5H18V19.5H20V21.5Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.5 21.5H14V19.5H16.5V21.5Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.5 5H13V3L16.5 3V5Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5 21.5H6V18.5H12.5V17H15.5V21.5Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 21.5H6V18.5H5.5V17H2.5V21.5Z" />
  </SvgWrapper>
);
