import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { ExpectedPaymentForm, InvoiceType } from "api/trading-documents/models";
import { UUID } from "api/types";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";

interface Props {
  expectedPaymentForm: ExpectedPaymentForm;
  id: UUID;
  invoiceType: InvoiceType;
}

export const PaymentForm = ({ expectedPaymentForm, id, invoiceType }: Props) => {
  switch (invoiceType) {
    case "SALES":
    case "CORRECTION":
    case "RECEIPT":
      return <EditablePaymentForm expectedPaymentForm={expectedPaymentForm} id={id} />;
    case "ADVANCE":
    case "FINAL":
    case "PROFORMA":
    case "PURCHASE":
      return <ExpectedPaymentFormLabel expectedPaymentForm={expectedPaymentForm} />;
    default:
      return <EmptyValue className="italic" fontSize="14" fontWeight="500" />;
  }
};

const EditablePaymentForm = ({
  expectedPaymentForm,
  id,
}: Pick<Props, "expectedPaymentForm" | "id">) => {
  return (
    <div>
      <TextField.Async
        mutationHook={usePatchTradingDocumentMutation}
        placeholder="Wpisz formę płatności"
        size="small"
        transformQueryData={expectedPaymentForm => ({
          id,
          expectedPaymentForm,
        })}
        value={
          expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE"
            ? tradingDocumentConstants.expectedPaymentFormDict[expectedPaymentForm]
            : expectedPaymentForm
        }
      />
    </div>
  );
};

const ExpectedPaymentFormLabel = ({ expectedPaymentForm }: Pick<Props, "expectedPaymentForm">) => {
  if (!Boolean(expectedPaymentForm))
    return <EmptyValue className="italic" fontSize="14" fontWeight="500" />;

  return (
    <Typography
      className={cx({
        italic: expectedPaymentForm !== "CASH" && expectedPaymentForm !== "ONLINE",
      })}
      fontSize="14"
      fontWeight="500"
      noWrap
    >
      {expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE"
        ? tradingDocumentConstants.expectedPaymentFormDict[expectedPaymentForm]
        : expectedPaymentForm}
    </Typography>
  );
};
