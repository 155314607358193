import { Product } from "api/products/models";
import { useEffect, useState } from "react";
import { Button } from "components/common";
import { isFabric } from "typePredicates";
import editIcon from "assets/images/1.svg";
import binIcon from "assets/images/81.svg";
import styles from "./ProductFilter.module.css";
import { useHistory } from "react-router";
import { useQuery } from "hooks";
import { useProductsQuery } from "hooks/apiPrimitives";
import {
  CombinedProductFilter,
  PickedProducts,
} from "pages/orders/ordersView/components/productFilterModal/ProductFilterModal";
import { ProductForm } from "pages/orders/ordersView/components/productFilterModal/ProductForm";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { queryString } from "utilities";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Switch } from "components/miloDesignSystem/atoms/switch";

interface ProductFilterType {
  product: number;
  values: number[];
  exclude: boolean;
}

//This file needs refactoring, as is ProductFilerModal
/**
 * @deprecated
 */
export const ProductFilter = () => {
  const [product, setProduct] = useState<Product | null>(null);
  const [operatorType, setOperatorType] = useState<CombinedProductFilter["operator"]>("OR");
  const [pickedProducts, setPickedProducts] = useState<PickedProducts>({});
  const [editMode, setEditMode] = useState(false);
  const history = useHistory();
  const { query } = useQuery();
  const productsIds =
    query?.productsWithValues &&
    JSON.parse(query.productsWithValues)
      .query.map((el: ProductFilterType) => el.product)
      .join(",");

  const { isLoading } = useProductsQuery(`?productsIds=${productsIds}&pageSize=999`, {
    enabled: Boolean(query?.productsWithValues),
    onSuccess: (payload: Product[]) => {
      const operator: CombinedProductFilter["operator"] = query?.productsWithValues
        ? JSON.parse(query?.productsWithValues).operator
        : "OR";
      const pickedProducts: PickedProducts = JSON.parse(query?.productsWithValues).query.reduce(
        // @ts-ignore
        (acc, el) => {
          const product = payload.find(prod => prod.id === el.product);
          Object.assign(acc, {
            ...acc,
            [el.product]: {
              selectedValues: el.values,
              isProductExcluded: el.exclude,
              product: product,
            },
          });
          return acc;
        },
        {},
      );
      setOperatorType(operator);
      setPickedProducts(pickedProducts);
    },
  });

  useEffect(() => {
    const productsQuery = Object.entries(pickedProducts).map(([productId, values]) => ({
      product: parseInt(productId),
      values: values.selectedValues,
      exclude: values.isProductExcluded,
    }));

    const json = {
      operator: operatorType,
      query: productsQuery,
    };

    history.replace({
      search: queryString.stringify({
        ...query,
        productsWithValues: Object.keys(pickedProducts).length ? JSON.stringify(json) : "",
      }),
    });
  }, [pickedProducts, operatorType, history, query]);

  const addAttributeValue = (productId: number, value: number) => {
    setPickedProducts(prevProducts => {
      const prevProduct = prevProducts[productId];
      const prevList = prevProduct.selectedValues || [];
      const valueIndex = prevList?.findIndex(el => el === value);

      if (valueIndex > -1) {
        return prevProducts;
      }

      const newList = [...prevList, value];
      return { ...prevProducts, [productId]: { ...prevProduct, selectedValues: newList } };
    });
  };

  function deleteProduct(key: number): void {
    if (product?.id === key) {
      setProduct(null);
    }
    setPickedProducts(prevState => {
      const { [key]: deletedProduct, ...restOfProducts } = prevState;
      return restOfProducts;
    });
  }

  const addProduct = (product: Product) => {
    setPickedProducts(prevProduct => ({
      ...prevProduct,
      [product.id]: { selectedValues: [], isProductExcluded: false, product: product },
    }));
  };

  const toggleIsProductExcluded = (productId: number) => {
    setPickedProducts(prevProducts => {
      const prevProduct = prevProducts[productId];
      return {
        ...prevProducts,
        [productId]: { ...prevProduct, isProductExcluded: !prevProduct.isProductExcluded },
      };
    });
  };

  const toggleAttributeValue = (productId: number, value: number) => {
    setPickedProducts(prevProducts => {
      const prevProduct = prevProducts[productId];
      const prevList = prevProduct.selectedValues || [];
      const valueIndex = prevList?.findIndex(el => el === value);

      let newList = [];
      // when value is a part of pickedProduct's selectedValues - remove it
      // if it is not - add it
      if (valueIndex > -1) {
        newList = [...prevList.slice(0, valueIndex), ...prevList.slice(valueIndex + 1)];
      } else {
        newList = [...prevList, value];
      }
      return { ...prevProducts, [productId]: { ...prevProduct, selectedValues: newList } };
    });
  };

  const editProduct = (product: Product) => {
    setEditMode(true);
    setProduct(product);
  };

  const renderProductAttributes = () => {
    return (
      <>
        {Object.keys(pickedProducts).map(productId => {
          const { product, selectedValues, isProductExcluded } = pickedProducts[
            parseInt(productId)
          ];

          return (
            <div key={productId} className={`mb-3 ${styles.renderedProducts}`}>
              <div>
                <div className="d-flex align-items-center">
                  <strong className="fs-16 mr-2">{product.name}</strong>
                  <span className="fs-12 text-muted">
                    {isProductExcluded ? "(Produkt wykluczony)" : "(Zawiera produkt)"}
                  </span>
                </div>
                <div className={styles.attributesContainer}>
                  {product.attributes.map(attribute => {
                    if (isFabric(attribute)) {
                      return attribute.categories
                        .filter(category => category.values.some(val => val.isAssignableToIndex))
                        .map(category => (
                          <div key={category.name}>
                            {category.values.filter(value => selectedValues.includes(value.id))
                              .length > 0 ? (
                              category.values
                                .filter(value => selectedValues.includes(value.id))
                                .map(value => (
                                  <div className={styles.attribute} key={value.id}>
                                    <span>{attribute.name}: </span>
                                    <strong>{value.name}</strong>
                                  </div>
                                ))
                            ) : (
                              <div className={styles.attribute} key={attribute.id}>
                                <span>{attribute.name}: </span>
                                <strong>Nie wybrano</strong>
                              </div>
                            )}
                          </div>
                        ));
                    }

                    return (
                      <div className={`d-flex ${styles.selectedProductsList}`} key={attribute.name}>
                        {attribute.values.filter(value => selectedValues.includes(value.id))
                          .length > 0 ? (
                          attribute.values
                            .filter(value => selectedValues.includes(value.id))
                            .map(value => (
                              <div className={styles.attribute} key={value.id}>
                                <span>{attribute.name}: </span>
                                <strong>{value.name}</strong>
                              </div>
                            ))
                        ) : (
                          <div className={styles.attribute} key={attribute.id}>
                            <span>{attribute.name}: </span>
                            <strong>Nie wybrano</strong>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  kind="secondary"
                  size="round-s"
                  title="Usuń product"
                  onClick={() => deleteProduct(product.id)}
                >
                  <img alt="" src={binIcon} />
                </Button>
                <Button
                  kind="secondary"
                  size="round-s"
                  title="Edytuj product"
                  onClick={() => editProduct(product)}
                >
                  <img alt="" src={editIcon} />
                </Button>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  if (Object.keys(pickedProducts).length === 0 && isLoading && !editMode) {
    return (
      <div>
        <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-1">
          Filtruj po produktach / indeksach
        </Typography>
        <div className="d-flex align-items-center justify-content-center mt-1">
          <Spinner size={24} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-1">
        Filtruj po produktach / indeksach
      </Typography>
      <div className="my-2 d-flex justify-content-between">
        <Switch
          checked={operatorType === "OR"}
          label={operatorType === "OR" ? "Alternatywa (lub)" : "Koniunkcja (i)"}
          onChange={() => {
            if (operatorType === "OR") {
              setOperatorType("AND");
            } else if (operatorType === "AND") {
              setOperatorType("OR");
            }
          }}
        />
      </div>

      {pickedProducts && renderProductAttributes()}

      <ProductForm
        addAttributeValue={addAttributeValue}
        addProduct={addProduct}
        editMode={editMode}
        product={product}
        setEditMode={setEditMode}
        setProduct={setProduct}
        toggleAttributeValue={toggleAttributeValue}
        toggleIsProductExcluded={toggleIsProductExcluded}
        pickedProducts={pickedProducts}
      />
    </div>
  );
};
