import { useState } from "react";
import { InProgressSection } from "./subcomponents/inProgressSection/InProgressSection";
import { TodoSection } from "./subcomponents/todoSection/TodoSection";
import { ReadySection } from "./subcomponents/readySection/ReadySection";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { useQueryClient } from "react-query";
import { Pagination } from "api/types";
import {
  ManufacturingGroupWithId,
  ManufacturingUnit,
  ManufacturingUnitGroup,
} from "api/manufacturing/units/models";
import { manufacturingUnitsKeys } from "api/manufacturing/units/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { dateUtils, queryString } from "utilities";
import { useQuery, useToastr } from "hooks";
import { useDefaultFilters } from "hooks/useDefaultFilters";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { manufacturingActions } from "api/manufacturing/actions";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Header } from "../subcomponents/Header";
import { AttributeCategory } from "api/manufacturing/schemas/models";
import { useStageId } from "../../hooks/useStageId";

export enum ColumnType {
  TODO = "todo",
  IN_PROGRESS = "inProgress",
  READY = "ready",
}

export const ColumnView = () => {
  const [columnType, setColumnType] = useState<ColumnType | null>(null);
  const markUnitAsInProgressMutation = manufacturingUnitsActions.useDragUnitToInProgressSection();
  const markGroupAsInProgressMutation = manufacturingUnitsActions.useDragGroupToInProgressSection();
  const markUnitAsReadyMutation = manufacturingUnitsActions.useDragUnitToReadySection();
  const markGroupAsReadyMutation = manufacturingUnitsActions.useDragGroupToReadySection();
  const [todoGroupsWithId, setTodoGroupsWithId] = useState<ManufacturingGroupWithId[]>([]);
  const queryClient = useQueryClient();
  const stageId = useStageId();
  const toastr = useToastr();
  const { query } = useQuery();
  const { data: manufacturingStage } = useManufacturingStage(stageId, {
    enabled: Boolean(stageId),
  });
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );

  useDefaultFilters({
    finishedAtFrom: dateUtils.formatDateToIso(new Date()),
    finishedAtTo: dateUtils.formatDateToIso(new Date()),
  });

  const attributesKinds = manufacturingStagesUtils.getAttributesKinds(defaultFilters!);

  const todoUnitSearchParams = manufacturingStagesUtils.getTodoUnitColumnSearch(
    query,
    stageId,
    attributesKinds,
  );
  const readyUnitSearchParams = manufacturingStagesUtils.getReadyColumnSearch(
    query,
    stageId,
    attributesKinds,
  );

  const handleObjectDropped = (result: DropResult) => {
    const inProgressSearch = manufacturingStagesUtils.getInProgressColumnSearch(
      query,
      stageId,
      manufacturingStage!.boardFormat,
      attributesKinds,
    );

    if (!result.destination) return;

    const [dragItemType, uniqueId] = result.draggableId.split(";");

    const units = queryClient.getQueryData<Pagination<ManufacturingUnit>>(
      manufacturingUnitsKeys.todoManufacturingUnit.list(todoUnitSearchParams),
    );
    const inProgressList = queryClient.getQueryData<ManufacturingUnitGroup[]>(
      manufacturingUnitsKeys.manufacturingUnitGroup.list(inProgressSearch),
    );
    assertIsDefined(units);
    assertIsDefined(inProgressList);

    const dropColumnId = result.destination.droppableId;
    if (
      dropColumnId === manufacturingStagesConstants.IN_PROGRESS_DROPPABLE &&
      dragItemType === "unitTodo"
    ) {
      const unitToAdd = units.results.find(unit => unit.id === uniqueId);
      assertIsDefined(unitToAdd);

      if (inProgressList.some(item => item.id === unitToAdd.id)) {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: "Ta paczka już została dodana",
        });
        queryClient.invalidateQueries();
        return;
      }

      markUnitAsInProgressMutation.mutate({
        data: {
          attributes: unitToAdd.attributeValues,
          manufacturingWorkingUnitIds: [uniqueId],
          schema_stage_id: stageId,
        },
        todoUnitSearchParams,
      });
    }

    if (
      dropColumnId === manufacturingStagesConstants.IN_PROGRESS_DROPPABLE &&
      dragItemType === "groupTodo"
    ) {
      const groupToAdd = todoGroupsWithId.find(todoGroup => todoGroup.id === uniqueId);
      assertIsDefined(groupToAdd);

      markGroupAsInProgressMutation.mutate({
        data: {
          attributes: [
            {
              attribute: {
                id: null,
                name: "",
              },
              category: AttributeCategory.PRODUCT,
              value: {
                id: null,
                name: groupToAdd.modelName,
              },
            },
            ...groupToAdd.attributesValues,
          ],
          manufacturingWorkingUnitIds: groupToAdd.elements.map(unit => unit.id),
          schema_stage_id: stageId,
        },
        setTodoGroupsWithId: setTodoGroupsWithId,
        todoGroupsWithId: todoGroupsWithId,
        uniqueId: uniqueId,
      });
    }

    if (
      dropColumnId === manufacturingStagesConstants.READY_DROPPABLE &&
      (dragItemType === "unitInProgress" || dragItemType === "unitTodo")
    ) {
      // const unitToAdd = inProgressList.find(unit => unit.id === uniqueId);
      // assertIsDefined(unitToAdd);

      // if (units.results.some(item => item.id === unitToAdd.id)) {
      //   toastr.open({
      //     type: "warning",
      //     title: "Wymagane działanie",
      //     text: "To zlecenie już zostało oznaczone jako gotowe",
      //   });
      //   queryClient.invalidateQueries();
      //   return;
      // }

      markUnitAsReadyMutation.mutate({
        data: { manufacturingWorkingUnitsIds: [uniqueId] },
        readyUnitSearchParams,
      });
    }

    if (
      dropColumnId === manufacturingStagesConstants.READY_DROPPABLE &&
      dragItemType === "groupTodo"
    ) {
      const groupToAdd = todoGroupsWithId.find(todoGroup => todoGroup.id === uniqueId);
      assertIsDefined(groupToAdd);
      // const unitToAdd = inProgressList?.find(unit => unit.id === uniqueId);
      // assertIsDefined(unitToAdd);

      // if (units.results.some(item => item.id === unitToAdd.id)) {
      //   toastr.open({
      //     type: "warning",
      //     title: "Wymagane działanie",
      //     text: "Ta grupa zleceń już została oznaczona jako gotowa",
      //   });
      //   queryClient.invalidateQueries();
      //   return;
      // }

      markUnitAsReadyMutation.mutate({
        data: { manufacturingWorkingUnitsIds: groupToAdd.elements.map(unit => unit.id) },
        readyUnitSearchParams,
      });
    }
    if (
      dropColumnId === manufacturingStagesConstants.READY_DROPPABLE &&
      dragItemType === "groupInProgress"
    ) {
      // const unitToAdd = inProgressList?.find(unit => unit.id === uniqueId);
      // assertIsDefined(unitToAdd);

      // if (units.results.some(item => item.id === unitToAdd.id)) {
      //   toastr.open({
      //     type: "warning",
      //     title: "Wymagane działanie",
      //     text: "Ta grupa zleceń już została oznaczona jako gotowa",
      //   });
      //   queryClient.invalidateQueries();
      //   return;
      // }

      markGroupAsReadyMutation.mutate({
        data: { manufacturingWorkingUnitGroupsIds: [uniqueId] },
        readyUnitSearchParams,
      });
    }
  };

  return (
    <PageWrapper key={`columnView-${stageId}-${query.finishedAtFrom}-${query.finishedAtTo}`}>
      <Header />
      <div className="d-flex flex-1 overflow-hidden gap-2 pl-3 pr-4 py-2">
        <DragDropContext onDragEnd={handleObjectDropped}>
          <TodoSection
            columnType={columnType}
            key={`todo-${stageId}`}
            setColumnType={setColumnType}
            todoGroupsWithId={todoGroupsWithId}
            setTodoGroupsWithId={setTodoGroupsWithId}
          />
          <InProgressSection
            columnType={columnType}
            key={`inProgress-${stageId}`}
            isDraggingMutationInProgress={
              markUnitAsInProgressMutation.isLoading || markGroupAsInProgressMutation.isLoading
            }
            setColumnType={setColumnType}
          />
          <ReadySection
            key={`ready-${stageId}`}
            columnType={columnType}
            isDraggingMutationInProgress={
              markUnitAsReadyMutation.isLoading || markGroupAsReadyMutation.isLoading
            }
            setColumnType={setColumnType}
          />
        </DragDropContext>
      </div>
    </PageWrapper>
  );
};
