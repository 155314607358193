import { Route } from "api/routes/models";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { TextFieldProps } from "components/miloDesignSystem/atoms/textField/types";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay/AmountDisplay";
import { Select } from "components/miloDesignSystem/molecules/select";
import { SelectProps } from "components/miloDesignSystem/molecules/select/types";
import { MDSAsyncType } from "typeUtilities";
import { cx } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props<TData, TError, TVariables, TContext> {
  routeCost: Route["routeCost"];
  amountProps: MDSAsyncType<TextFieldProps, string, TData, TError, TVariables, TContext>;
  currencyProps: MDSAsyncType<
    SelectProps,
    SelectProps["selected"],
    TData,
    TError,
    TVariables,
    TContext
  >;
}

export const RouteCost = <TData = unknown, TError = unknown, TVariables = unknown, TContext=unknown>({ amountProps, currencyProps, routeCost }: Props<TData, TError, TVariables, TContext>) => {
  return (
    <div className="d-flex justify-content-start align-items-center gap-2">
      <div className="d-flex align-items-baseline gap-2">
        <div className="d-flex align-items-baseline gap-2 mr-3">
          <Typography fontSize="14" fontWeight="600" noWrap>
            Kwota z samochodu:
          </Typography>
          {routeCost.carRouteCost && routeCost.carRouteCost.amount !== null ? (
            <AmountDisplay
              className={cx({
                "line-through": routeCost.customRouteCost.amount !== null,
              })}
              amount={String(routeCost.carRouteCost.amount)}
              currency={routeCost.carRouteCost.currency}
              integerTypographyProps={{
                fontSize: "16",
                fontWeight: "600",
              }}
              decimalTypographyProps={{
                fontSize: "14",
                fontWeight: "500",
              }}
            />
          ) : (
            <EmptyValue fontSize="14" />
          )}
        </div>
        <TextField.Async {...amountProps} />
      </div>
      <Select.Async {...currencyProps} />
    </div>
  );
};
