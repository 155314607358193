import {
  availablePaymentTypesDict,
  deliveryMethodDict,
  getDeliveryMethodToTranslationDict,
  paymentTypeDict,
} from "CONSTANTS";
import { useSettings } from "./hooks";
import { OrderPaymentType } from "api/orders/models";
import { DeliveryMethodChoices } from "api/common/enums";

export const useDefaultDeliveryMethod = (initialValue?: DeliveryMethodChoices) => {
  const settings = useSettings();
  const normalizedSettingsDeliveryMethods: Record<DeliveryMethodChoices, boolean> = (() => {
    const normalizedSettingsDeliveryMethods: Record<DeliveryMethodChoices, boolean> = {
      [DeliveryMethodChoices.EXTERNAL_SHIPMENT]: false,
      [DeliveryMethodChoices.PERSONAL_COLLECTION]: false,
      [DeliveryMethodChoices.SELF_SHIPPED]: false,
    };

    Object.entries(settings.sales.deliveryMethods).forEach(([key, value]) => {
      const normalizedKey = deliveryMethodDict[key].id;
      normalizedSettingsDeliveryMethods[normalizedKey] = value;
    });

    return normalizedSettingsDeliveryMethods;
  })();

  if (!normalizedSettingsDeliveryMethods[settings.transport.defaultDeliveryMethod]) {
    normalizedSettingsDeliveryMethods[settings.transport.defaultDeliveryMethod] = true;
  }

  if (initialValue && !normalizedSettingsDeliveryMethods[initialValue]) {
    normalizedSettingsDeliveryMethods[initialValue] = true;
  }

  return {
    defaultDeliveryMethod: settings.transport.defaultDeliveryMethod,
    deliveryMethods: Object.entries(normalizedSettingsDeliveryMethods)
      .filter(([_, value]) => Boolean(value))
      .map(([key]) => ({
        value: key,
        name: getDeliveryMethodToTranslationDict(key as DeliveryMethodChoices),
      })),
  };
};

export const useDefaultPaymentType = (initialValue?: OrderPaymentType) => {
  const settings = useSettings();
  const normalizedSettingsPaymentTypes: Record<OrderPaymentType, boolean> = (() => {
    const normalizedSettingsPaymentTypes: Record<OrderPaymentType, boolean> = {
      CASH_ON_DELIVERY: false,
      ONLINE: false,
      DEBIT_CARD: false,
      INSTALMENT: false,
    };

    Object.entries(settings.sales.paymentType).forEach(([key, value]) => {
      normalizedSettingsPaymentTypes[paymentTypeDict[key as keyof typeof paymentTypeDict]] = value;
    });

    return normalizedSettingsPaymentTypes;
  })();

  if (!normalizedSettingsPaymentTypes[settings.sales.defaultPaymentType]) {
    normalizedSettingsPaymentTypes[settings.sales.defaultPaymentType] = true;
  }

  if (initialValue && !normalizedSettingsPaymentTypes[initialValue]) {
    normalizedSettingsPaymentTypes[initialValue] = true;
  }

  return {
    defaultPaymentType: settings.sales.defaultPaymentType,
    paymentTypes: Object.entries(normalizedSettingsPaymentTypes)
      .filter(([_, value]) => Boolean(value))
      .map(([key]) => ({
        value: key,
        name: availablePaymentTypesDict[key as OrderPaymentType],
      })),
  };
};
