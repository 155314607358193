import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { FileDownloadHandlerProps } from "./types";
import { useErrorToastr } from "hooks/useErrorToastr";
import { StatusHandlerHelpers, useStatusHandler } from "components/utils";
import { fileDownloader } from "fileDownloader";
import { DOMAIN } from "ENDPOINTS";

export const FileDownloadHandler = ({
  children,
  factoryFn,
  type,
  mode = "download",
}: FileDownloadHandlerProps) => {
  const { download, isLoading } = useFileDownload({ factoryFn, mode, type });

  return children({ download, isLoading });
};

export const useFileDownload = ({
  factoryFn,
  mode,
  type,
}: Pick<FileDownloadHandlerProps, "factoryFn" | "mode" | "type">) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();
  const helpers = useStatusHandler(undefined);

  const handleDownload = async (helpers: StatusHandlerHelpers) => {
    if (mode === "preview") {
      window.open(
        `${DOMAIN}${factoryFn()
          .url.split("/")
          .slice(1)
          .join("/")}`,
        "_blank",
      );
      return;
    }
    helpers.startFetching();
    const tstr = downloadFeedbackToastr.open({ type });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url: factoryFn().url,
      name: factoryFn().name,
      type,
    });
    if (response.status === "success") {
      helpers.stopFetching();
      tstr.lazyClose();
    } else {
      helpers.stopFetching();
      tstr.close();
      handleErrorMessage(response);
    }
  };

  return {
    download: () => handleDownload(helpers),
    isLoading: helpers.isFetching,
  };
};
