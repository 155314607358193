import { patchItemSummaryAmounts } from "api/trading-documents/calls";
import { GTUCode } from "api/trading-documents/enums";
import { useTradingDocument } from "api/trading-documents/hooks";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { PatchItemSummaryAmountPayload, TradingDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import produce from "immer";
import { useState } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useEditTradingDocumentItemValue = (
  fieldToUpdate: keyof PatchItemSummaryAmountPayload,
  tradingDocumentId: UUID,
) => {
  const [showInput, setShowInput] = useState(false);
  const { handleMutate } = useTradingDocument({ id: tradingDocumentId }, { enabled: false });

  const editItemValueMutation = useMutation(patchItemSummaryAmounts, ({ queryClient, toastr }) => ({
    onMutate: args => {
      return handleMutate(draft => {
        draft.isManagedManually = true;
        draft.items = draft.items.map(item => {
          if (item) {
            item.tradingDocumentItems = item.tradingDocumentItems.map(_tradingDocumentItem => {
              if (_tradingDocumentItem.id !== args.id) {
                return _tradingDocumentItem;
              }
              if (fieldToUpdate === "gtuCode") {
                _tradingDocumentItem[fieldToUpdate] = String(args[fieldToUpdate]) as GTUCode;
              }
              if (fieldToUpdate === "discount") {
                _tradingDocumentItem[fieldToUpdate] = Number(args[fieldToUpdate]);
                _tradingDocumentItem.discountAmount =
                  args.discount !== undefined
                    ? Number(args.discount / 100) * _tradingDocumentItem.amountWithoutTax
                    : 0;
              }
              if (fieldToUpdate !== "discount" && fieldToUpdate !== "gtuCode") {
                _tradingDocumentItem[fieldToUpdate] = Number(args[fieldToUpdate]);
              }
              return _tradingDocumentItem;
            });
          }
          return item;
        });
      });
    },
    onSuccess: payload => {
      queryClient.setQueryData<TradingDocument>(
        tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
        currentPanel => {
          assertIsDefined(currentPanel);
          return produce(currentPanel, draft => {
            draft.isManagedManually = payload.isManagedManually;
          });
        },
      );
      queryClient.invalidateQueries(
        tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
      );
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: getFieldToUpdateStatusInfo(fieldToUpdate),
      });
    },
    onError: (error, _, rollback) => {
      // @ts-ignore
      rollback(error);
    },
    onSettled: () => {
      setShowInput(false);
    },
  }));

  return {
    editItemValueMutation,
    setShowInput,
    showInput,
  };
};

const getFieldToUpdateStatusInfo = (field: keyof PatchItemSummaryAmountPayload) => {
  switch (field) {
    case "amountWithTax":
      return "Zmodyfikowano cenę brutto";
    case "amountWithoutTax":
      return "Zmodyfikowano cenę netto";
    case "discount":
      return "Zmodyfikowano rabat";
    case "gtuCode":
      return "Zmodyfikowano kod GTU";
    default:
      const exhaustiveCheck = field;
      console.error(`Unhandled field to update: ${exhaustiveCheck}`);
      return EMPTY_VALUE;
  }
};
