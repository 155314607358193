import { tradingDocumentsActions } from "api/trading-documents/actions";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import {
  FiscalizeReceiptPayload,
  ReceiptPrintingStatus as ReceiptPrintingStatusType,
  TradingDocument,
} from "api/trading-documents/models";
import { ISODateTime } from "api/types";
import { InfoLabel } from "components/common/infoLabel";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiReceiptLong } from "components/miloDesignSystem/atoms/icons/MdiReceiptLong";
import { Link } from "components/miloDesignSystem/atoms/link/Link";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { FailedResponseModal } from "components/miloDesignSystem/organisms/failedResponseModal/FailedResponseModal";
import { RightPanelSection } from "components/utils/drawer/Drawer";
import { useQueryUtils, useStateModal } from "hooks";
import { dateUtils, pluralize } from "utilities";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  showItemsWithoutAmountWarning: boolean;
  showCorrespondingReceiptDetails: boolean;
  tradingDocument: TradingDocument;
}

interface ReplyModal {
  openReplyModal: (stateToSet: { preview: FiscalizeReceiptPayload }) => void;
}

export const ReceiptFiscalization = ({
  showCorrespondingReceiptDetails,
  showItemsWithoutAmountWarning,
  tradingDocument,
}: Props) => {
  const fiscalizationReplyModal = useStateModal<{ preview: FiscalizeReceiptPayload }>();

  if (showItemsWithoutAmountWarning)
    return (
      <RightPanelSection>
        <div className="d-flex align-items-center gap-2">
          <InfoLabel title="paragon">
            <Tag label="Paragon zawiera pozycje o wartości 0" variant="danger" />
          </InfoLabel>
        </div>
      </RightPanelSection>
    );

  return (
    <>
      <RightPanelSection>
        <>
          <div className="d-flex align-items-center gap-2">
            <InfoLabel title="paragon">
              {showCorrespondingReceiptDetails && tradingDocument.correspondingReceipt ? (
                <FiscalizationDetails
                  id={tradingDocument.id}
                  items={tradingDocument.items}
                  openReplyModal={fiscalizationReplyModal.open}
                  printingRequestedAt={tradingDocument.correspondingReceipt.printingRequestedAt}
                  printingStatus={tradingDocument.correspondingReceipt.printingStatus}
                  showCorrespondingReceiptDetails={showCorrespondingReceiptDetails}
                />
              ) : (
                <FiscalizationDetails
                  id={tradingDocument.id}
                  items={tradingDocument.items}
                  openReplyModal={fiscalizationReplyModal.open}
                  printingRequestedAt={tradingDocument.printingRequestedAt}
                  printingStatus={tradingDocument.printingStatus}
                  showCorrespondingReceiptDetails={showCorrespondingReceiptDetails}
                />
              )}
            </InfoLabel>
          </div>
          {showCorrespondingReceiptDetails && tradingDocument.correspondingReceipt && (
            <ReceiptPrintingStatus tradingDocument={tradingDocument} />
          )}
        </>
      </RightPanelSection>
      {fiscalizationReplyModal.isOpen && fiscalizationReplyModal.state && (
        <FailedResponseModal
          close={fiscalizationReplyModal.close}
          data={{
            success: Boolean(fiscalizationReplyModal.state.preview.message.valid.objects.length)
              ? {
                  message: `Pomyślnie sfiskalizowano paragon dla: ${fiscalizationReplyModal.state.preview.message.valid.objects
                    .map(document => document.signature)
                    .join(", ")}`,
                }
              : null,
            failed:
              getNotFiscalizedReceiptsNumber(fiscalizationReplyModal.state.preview) > 0
                ? {
                    message: getInvalidFiscalizationMessage(fiscalizationReplyModal.state.preview),
                    reasons: Object.values(
                      fiscalizationReplyModal.state.preview.message.invalid,
                    ).map(reason => ({
                      message: reason.message,
                      signatures: reason.objects.map(object => object.signature),
                    })),
                  }
                : null,
          }}
          title="Status fiskalizacji"
        />
      )}
    </>
  );
};

const ReceiptPrintingStatus = ({ tradingDocument }: Pick<Props, "tradingDocument">) => {
  if (tradingDocument.correspondingReceipt!.printingStatus !== "FINISHED") return null;
  return (
    <div className="d-flex align-items-center gap-2 pb-2">
      <InfoLabel title="nr paragonu:">
        <Link
          fontSize="14"
          fontWeight="700"
          to={`/finances/receipts/list/all?panelId=${tradingDocument.correspondingReceipt!.id}`}
        >
          <div>{tradingDocument.correspondingReceipt!.signature}</div>
        </Link>
      </InfoLabel>
    </div>
  );
};

interface FiscalizationDetailsProps {
  printingRequestedAt: ISODateTime | null;
  printingStatus: ReceiptPrintingStatusType;
  showCorrespondingReceiptDetails: Props["showCorrespondingReceiptDetails"];
  items: TradingDocument["items"];
  id: TradingDocument["id"];
}

const FiscalizationDetails = ({
  id,
  items,
  openReplyModal,
  printingRequestedAt,
  printingStatus,
  showCorrespondingReceiptDetails,
}: FiscalizationDetailsProps & ReplyModal) => {
  switch (printingStatus) {
    case "NOT_STARTED":
      return (
        <div className="d-flex align-items-center gap-3">
          <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
            nie wydrukowany
          </Typography>
          <FiscalizationButton
            id={id}
            items={items}
            openReplyModal={openReplyModal}
            showCorrespondingReceiptDetails={showCorrespondingReceiptDetails}
          />
        </div>
      );
    case "IN_PROGRESS":
      return <Tag label="w trakcie fiskalizacji" variant="warning" />;
    case "FAILED":
      return (
        <div className="d-flex align-items-center gap-3">
          <Tag label="niepowodzenie" variant="danger" />
          <FiscalizationButton
            id={id}
            items={items}
            openReplyModal={openReplyModal}
            showCorrespondingReceiptDetails={showCorrespondingReceiptDetails}
          />
        </div>
      );
    case "FINISHED":
      return (
        <div className="d-flex align-items-center gap-3">
          <Tag label="wydrukowano" variant="info" />
          <Typography fontSize="10" fontWeight="400">
            {printingRequestedAt
              ? dateUtils.formatDateAndTimeToDisplay(printingRequestedAt)
              : EMPTY_VALUE}
          </Typography>
        </div>
      );
    default:
      const exhaustiveCheck = printingStatus;
      console.error(`Unhandled printing status: ${exhaustiveCheck}`);
      return null;
  }
};

const FiscalizationButton = ({
  openReplyModal,
  showCorrespondingReceiptDetails,
  id,
  items,
}: Omit<FiscalizationDetailsProps, "printingRequestedAt" | "printingStatus"> & ReplyModal) => {
  const fiscalizeReceiptMutation = tradingDocumentsActions.useFiscalizeReceipt(openReplyModal, id);
  const queryUtils = useQueryUtils();

  const mutateCorrespondingReceipt = () => {
    return queryUtils.handleMutate<TradingDocument>(
      tradingDocumentsKeys.tradingDocument.details(id),
      draft => {
        draft.correspondingReceipt = {
          id: draft.correspondingReceipt!.id,
          isPrintingAllowed: false,
          signature: draft.correspondingReceipt!.signature,
          printingDisabledStatus: null,
          printingStatus: ReceiptPrintingStatusType.IN_PROGRESS,
          printingRequestedAt: null,
        };
      },
    );
  };

  const mutateReceipt = () => {
    return queryUtils.handleMutate<TradingDocument>(
      tradingDocumentsKeys.tradingDocument.details(id),
      draft => {
        draft.printingStatus = ReceiptPrintingStatusType.IN_PROGRESS;
      },
    );
  };

  if (Boolean(items.flatMap(item => item.tradingDocumentItems).length))
    return (
      <Button
        className="text-uppercase"
        onClick={() => {
          const tradingDocument = showCorrespondingReceiptDetails
            ? mutateCorrespondingReceipt()
            : mutateReceipt();
          fiscalizeReceiptMutation.mutate(
            {
              id:
                tradingDocument.invoiceType === "RECEIPT"
                  ? tradingDocument.id
                  : tradingDocument.correspondingReceipt!.id,
            },
            {
              onError: error => {
                queryUtils.rollback(
                  tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
                  tradingDocument,
                  error,
                );
              },
            },
          );
        }}
        size="small"
        startIcon={MdiReceiptLong}
        theme="light"
        variant="gray"
      >
        Fiskalizuj
      </Button>
    );
  return (
    <Typography fontSize="12" fontWeight="700">
      brak pozycji na fakturze
    </Typography>
  );
};

export const getInvalidFiscalizationMessage = (preview: FiscalizeReceiptPayload): string => {
  const notFiscalized = getNotFiscalizedReceiptsNumber(preview);
  if (notFiscalized === 0) return "";
  if (notFiscalized === 1) return "Nie sfiskalizowano paragonu";
  return `Nie sfiskalizowano ${pluralize.pl(notFiscalized, {
    singular: "paragonu",
    plural: "paragonów",
    other: "paragonów",
  })}`;
};

export const getNotFiscalizedReceiptsNumber = (preview: FiscalizeReceiptPayload): number => {
  return Object.values(preview.message.invalid).reduce((acc, values) => {
    acc += values.objects.length;
    return acc;
  }, 0);
};
