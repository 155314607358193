import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiChangeStatus = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path d="M4 9.4C4 7.78333 4.525 6.39583 5.575 5.2375C6.625 4.07917 8.5 2.5 11.5 2.5C14.5 2.5 16.375 4.07917 17.425 5.2375C18.475 6.39583 19 7.78333 19 9.4V9.7L21.6 7.1L23 8.5L18 13.5L13 8.5L14.4 7.1L17 9.7V9.4C17 8.35 16.6667 7.4375 16 6.6625C15.3333 5.8875 14 4.5 11.5 4.5C9 4.5 7.66667 5.8875 7 6.6625C6.33333 7.4375 6 8.35 6 9.4V16.5H4V9.4Z" />
    <path d="M5 15C5.83333 15 6.54167 15.2917 7.125 15.875C7.70833 16.4583 8 17.1667 8 18C8 18.8333 7.70833 19.5417 7.125 20.125C6.54167 20.7083 5.83333 21 5 21C4.16667 21 3.45833 20.7083 2.875 20.125C2.29167 19.5417 2 18.8333 2 18C2 17.1667 2.29167 16.4583 2.875 15.875C3.45833 15.2917 4.16667 15 5 15ZM5 19C5.28333 19 5.52083 18.9042 5.7125 18.7125C5.90417 18.5208 6 18.2833 6 18C6 17.7167 5.90417 17.4792 5.7125 17.2875C5.52083 17.0958 5.28333 17 5 17C4.71667 17 4.47917 17.0958 4.2875 17.2875C4.09583 17.4792 4 17.7167 4 18C4 18.2833 4.09583 18.5208 4.2875 18.7125C4.47917 18.9042 4.71667 19 5 19Z" />
    <path d="M18 15C18.8333 15 19.5417 15.2917 20.125 15.875C20.7083 16.4583 21 17.1667 21 18C21 18.8333 20.7083 19.5417 20.125 20.125C19.5417 20.7083 18.8333 21 18 21C17.1667 21 16.4583 20.7083 15.875 20.125C15.2917 19.5417 15 18.8333 15 18C15 17.1667 15.2917 16.4583 15.875 15.875C16.4583 15.2917 17.1667 15 18 15ZM18 19C18.2833 19 18.5208 18.9042 18.7125 18.7125C18.9042 18.5208 19 18.2833 19 18C19 17.7167 18.9042 17.4792 18.7125 17.2875C18.5208 17.0958 18.2833 17 18 17C17.7167 17 17.4792 17.0958 17.2875 17.2875C17.0958 17.4792 17 17.7167 17 18C17 18.2833 17.0958 18.5208 17.2875 18.7125C17.4792 18.9042 17.7167 19 18 19Z" />
  </SvgWrapper>
);
