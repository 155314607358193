import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { withFilters } from "components/utils/withFilters";
import { useQuery, useToggle } from "hooks";
import { dateUtils, queryString } from "utilities";
import { filterList } from "./filterList";
import { PageHeader } from "components/common";
import { startOfDay, subDays } from "date-fns";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { FinancesPaymentListItem, FinancesPaymentType } from "api/finances/payments/models";
import { usePaymentsColumns } from "./usePaymentsColumns";
import { financesPaymentsActions } from "api/finances/payments/actions";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { DownloadPaymentsPDF } from "./components/downloadPaymentsPdf/DownloadPaymentsPDF";
import { CreatePayment } from "./components/createPayment/CreatePayment";

export const FinancesPayments = withFilters(filterList, () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify({
    ...query,
    type: FinancesPaymentType.CASH,
  });
  const {
    data: financesPayments,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = financesPaymentsActions.useFinancesPayments(search);
  const columns = usePaymentsColumns();
  const tableMultiSelect = useTableMultiSelect({ rows: financesPayments });
  const tableProps = useTableFeatureConnector({
    rows: financesPayments,
    withMultiSelect: tableMultiSelect,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <Header />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<FinancesPaymentListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={{
            header: {
              backgroundColor: "grey25",
            },
            cell: {
              height: "27",
            },
          }}
          {...tableProps}
        />
        <TableToolbar
          close={tableMultiSelect.clear}
          numberOfSelectedItems={tableMultiSelect.selectedRows.length}
        >
          <DownloadPaymentsPDF financesPayments={tableMultiSelect.selectedRows} />
        </TableToolbar>
      </div>
    </PageWrapper>
  );
});

const Header = () => {
  const createPaymentModal = useToggle();

  return (
    <div>
      <PageHeader
        createButton={{
          alt: "dodaj płatność",
          label: "Dodaj płatność",
          onClick: createPaymentModal.open,
        }}
        searchInput={{
          label: "Szukaj...",
          tags: [
            {
              name: "paymentDateFrom",
              label: "data wystawienia (od)",
              value: dateUtils.formatDateToIso(startOfDay(subDays(new Date(), 7))),
            },
            {
              name: "paymentDateTo",
              label: "data wystawienia (do)",
              value: dateUtils.formatDateToIso(new Date()),
            },
          ],
        }}
        title="Dokumenty kasowe - KP"
        viewLabel="FINANCES_PAYMENTS"
      />
      {createPaymentModal.isOpen && <CreatePayment close={createPaymentModal.close} />}
    </div>
  );
};
