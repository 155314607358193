import { FinancesPaymentListItem } from "api/finances/payments/models";
import {
  EMPTY_VALUE,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { DownloadPaymentsPDF } from "./components/downloadPaymentsPdf/DownloadPaymentsPDF";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { financesPaymentsActions } from "api/finances/payments/actions";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";

export const usePaymentsColumns = () => {
  const deleteMutation = financesPaymentsActions.useDeletePayment();

  return useCreateTableColumns<FinancesPaymentListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "numer",
        size: 200,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.date(row => row.paymentDate, {
        header: "data wystawienia",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.text(row => row.customer?.name, {
        header: "kontrahent",
        size: 250,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.text(
        row =>
          row.seller?.companyName ||
          (row.seller?.firstName &&
            row.seller?.lastName &&
            `${row.seller?.firstName} ${row.seller?.lastName}`) ||
          EMPTY_VALUE,
        {
          header: "sprzedawca",
          size: 250,
          typographyProps: {
            fontSize: "14",
            fontWeight: "600",
          },
        },
      ),
      columnHelper.text(row => row.buyer?.taxId || EMPTY_VALUE, {
        header: "NIP",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.country(row => row, {
        header: "kraj",
        size: 60,
        countryFlagProps: {
          countryCode: row => row.original.countryCode,
        },
      }),
      columnHelper.accessor(row => row, {
        id: "downloadProofOfPaymentPDF",
        header: " ",
        size: 50,
        cell: info => {
          const financesPayment = info.getValue();
          return <DownloadPaymentsPDF financesPayments={[financesPayment]} />;
        },
      }),
      columnHelper.accessor(row => row, {
        id: "removeProofOfPayment",
        header: " ",
        size: 50,
        cell: info => {
          const financesPayment = info.getValue();
          return (
            <div onClick={event => event.stopPropagation()}>
              <Tooltip title="Usuń KP">
                <IconButton
                  icon={MdiDelete}
                  isLoading={
                    deleteMutation.isLoading && deleteMutation.variables === financesPayment.id
                  }
                  onClick={() => deleteMutation.mutate(financesPayment.id)}
                  variant="transparent"
                />
              </Tooltip>
            </div>
          );
        },
      }),
    ];
  });
};
