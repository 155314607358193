import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { RouteComponentProps } from "react-router";
import { TradingDocument, TradingDocumentTab } from "api/trading-documents/models";
import { useQuery } from "hooks";
import { getSearch } from "../shared/utils/getSearch";
import { useTradingDocuments } from "api/trading-documents/hooks";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useReceiptsColumns } from "./useReceiptsColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageHeader } from "components/common";
import { dateUtils } from "utilities";
import { startOfDay, subDays } from "date-fns";
import { getTabs } from "../shared/utils/getTabs";
import { receipts } from "components/common/moduleNavigation/moduleConfig/finances/routes/receipts";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { RightPanel } from "./rightPanel/RightPanel";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { LedgerAccountSelector } from "../shared/tableToolbar/ledgerAccountSelector/LedgerAccountSelector";
import { WaproFakirXml } from "../shared/tableToolbar/waproFakirXml/WaproFakirXml";
import { DownloadRevisor } from "../shared/tableToolbar/downloadRevisor/DownloadRevisor";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { TradingDocumentPdf } from "../shared/tableToolbar/tradingDocumentPdf/TradingDocumentPdf";
import { FiscalizeReceipts } from "../shared/tableToolbar/fiscalizeReceipts/FiscalizeReceipts";
import { EmailNotifications } from "../shared/tableToolbar/emailNotifications/EmailNotifications";
import { ConfirmReceipts } from "./tableMultiSelect/ConfirmReceipts";

export const Receipts = withFilters(
  filterList,
  ({ match }: RouteComponentProps<{ tab: TradingDocumentTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ query, tab, type: "RECEIPT" });
    const {
      data: tradingDocuments,
      error,
      isLoading,
      isPreviousData,
      pagination,
    } = useTradingDocuments(search);
    const columns = useReceiptsColumns();
    const tableMultiSelect = useTableMultiSelect({
      rows: tradingDocuments,
    });
    const tableProps = useTableFeatureConnector({
      rows: tradingDocuments,
      withDrawer: "receipts",
      withMultiSelect: tableMultiSelect,
      withPagination: { pagination, defaultPaginationVisibility: true },
    });

    return (
      <PageWrapper>
        <Header />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<TradingDocument>
            columns={columns}
            error={error}
            isLoading={isLoading || isPreviousData}
            uiSchema={{
              header: {
                backgroundColor: "grey25",
              },
              cell: {
                height: "27",
              },
            }}
            {...tableProps}
          />
          <RightPanel />
          <TableToolbar
            close={tableMultiSelect.clear}
            numberOfSelectedItems={tableMultiSelect.selectedRows.length}
          >
            <ConfirmReceipts selectedRows={tableMultiSelect.selectedRows} />
            <EmailNotifications selectedRows={tableMultiSelect.selectedRows} />
            <LedgerAccountSelector selectedRows={tableMultiSelect.selectedRows} />
            <TradingDocumentPdf selectedRows={tableMultiSelect.selectedRows} type="RECEIPT" />
            <WaproFakirXml selectedRows={tableMultiSelect.selectedRows} />
            <DownloadRevisor selectedRows={tableMultiSelect.selectedRows} />
            <FiscalizeReceipts selectedRows={tableMultiSelect.selectedRows} />
          </TableToolbar>
        </div>
      </PageWrapper>
    );
  },
);

const Header = () => {
  return (
    <PageHeader
      additionalListParams={{
        type: "RECEIPT",
      }}
      bottomBarButtons={<PrintingStatusFilter />}
      searchInput={{
        label: "Szukaj...",
        tags: [
          {
            name: "issueDateFrom",
            label: "data wystawienia (od)",
            value: dateUtils.formatDateToIso(startOfDay(subDays(new Date(), 7))),
          },
          {
            name: "issueDateTo",
            label: "data wystawienia (do)",
            value: dateUtils.formatDateToIso(new Date()),
          },
        ],
      }}
      tabs={{
        list: getTabs(),
        routesRoot: `finances/${receipts.url}`,
        urlSpan: "list",
      }}
      viewLabel="RECEIPTS"
    />
  );
};

const PrintingStatusFilter = () => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const handlePrintingStatusChange = (statusValue: string | null) => {
    if (statusValue) {
      const values = (() => {
        const appliedFilters = query["printingStatus"]?.split(",");
        if (appliedFilters?.includes(statusValue)) {
          return appliedFilters?.filter(appliedValue => appliedValue !== statusValue).join(",");
        }
        return [...(appliedFilters || []), statusValue].join(",");
      })();
      updateQuery({ printingStatus: values, page: 1 });
      return;
    }
    updateQuery({ printingStatus: "", page: 1 });
  };

  const isChecked = (statusValue: string) =>
    query.printingStatus === statusValue || query.printingStatus?.includes(statusValue);

  return (
    <div className="d-flex align-items-center pt-1 gap-2">
      {tradingDocumentConstants.printingStatusOptions.map(status => {
        return (
          <div className="cursor-pointer" onClick={() => handlePrintingStatusChange(status.value)}>
            <Tag
              label={status.label}
              startIcon={<MdiPoint color={status.color} size="12" />}
              type={isChecked(status.value) ? "filled" : "outlined"}
              variant={isChecked(status.value) ? "deepPurple50" : "quaternary"}
            />
          </div>
        );
      })}
    </div>
  );
};
