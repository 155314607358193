import {
  CarrierOrderEmailMessage,
  EmailMessageStatus as EmailMessageStatusType,
} from "api/logistics/carrierOrders/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useEmailsColumns = () => {
  return useCreateTableColumns<CarrierOrderEmailMessage>(
    ({ columnHelper }) => {
      return [
        columnHelper.date(row => row.createdAt, {
          header: "wysłano",
          size: 100,
          typographyProps: {
            color: "neutralBlack48",
            fontSize: "12",
            fontWeight: "500",
          },
        }),
        columnHelper.text(row => row.message.recipient, {
          header: "na adres",
          size: 150,
        }),
        columnHelper.accessor(row => row.message.status, {
          header: "status",
          size: 100,
          cell: info => {
            const status = info.getValue();
            return <EmailMessageStatus status={status} />;
          },
        }),
        columnHelper.accessor(row => row.message.sentBy, {
          header: "przez kogo",
          size: 120,
          cell: info => {
            const sentBy = info.getValue();
            if (!sentBy) return <EmptyValue fontSize="12" fontWeight="700" />;
            return (
              <div className="d-flex align-items-center gap-1">
                <Avatar size="sm" user={sentBy} />
                <Typography fontSize="12" fontWeight="700">
                  {sentBy.firstName} {sentBy.lastName}
                </Typography>
              </div>
            );
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};

const EmailMessageStatus = ({
  status,
}: {
  status: CarrierOrderEmailMessage["message"]["status"];
}) => {
  switch (status) {
    case EmailMessageStatusType.CLICKED_LINK:
    case EmailMessageStatusType.OPENED:
      return (
        <Typography color="success500" fontSize="12" fontWeight="700">
          Odczytano
        </Typography>
      );
    case EmailMessageStatusType.DECLINED:
      return (
        <Typography color="danger500" fontSize="12" fontWeight="700">
          Błąd wysyłki
        </Typography>
      );
    case EmailMessageStatusType.SENT:
      return (
        <Typography color="deepPurple400" fontSize="12" fontWeight="700">
          Wysłano
        </Typography>
      );
    default: {
      const exhaustiveCheck: never = status;
      console.error(`Unhandled email status: ${exhaustiveCheck}`);
      return <EmptyValue fontWeight="700" />;
    }
  }
};
