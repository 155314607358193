import { StageSchemaAssignment } from "api/manufacturingNew/models";
import styles from "../RightPanel.module.css";
import { Button } from "components/common";
import binIcon from "assets/images/deleteNew.svg";
import { UseMutationResult } from "react-query";
import { QueryFetchError } from "api/types";
import { RightPanelTableRow } from "components/utils/drawer";
import { dateUtils } from "utilities";
import { StateLabel } from "components/common/stateLabel";
import { stagesListConfig } from "../utils/panelTablesConfig";
import { ConfirmModal } from "components/common/confirmModal/ConfirmModalController";
import { AvatarWithTooltip } from "components/common/avatarWithTooltip/AvatarWithTooltip";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { manufacturingSchemaActions } from "api/manufacturing/schemas/actions";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Radio } from "components/miloDesignSystem/atoms/radio";

interface Props {
  confirmModal: ConfirmModal;
  deleteMutation: UseMutationResult<unknown, QueryFetchError, string, unknown>;
  index: number;
  panelKey: string[];
  stage: StageSchemaAssignment;
  stages: StageSchemaAssignment[];
}

export const Stage = ({ confirmModal, deleteMutation, index, panelKey, stage, stages }: Props) => {
  const stagesOptions = stages
    .filter(_stage => _stage.stageId !== stage.stageId)
    .map(_stage => ({
      text: _stage.name,
      type: MenuItemType.TEXT,
      value: _stage.relation?.id ?? "",
    }));

  return (
    <RightPanelTableRow className={styles.tableRowPPStages} grid={stagesListConfig.grid}>
      <div className="fw-700">{index + 1}.</div>
      <div>{stage.name}</div>
      <div>
        <div className="btnBase btnBase16 btnBaseSmall">
          <Button
            kind="secondary"
            size="square-s"
            title="Usuń etap"
            onClick={() => {
              if (stage.stageId) {
                confirmModal.open({
                  text: `Czy na pewno chcesz usunąć etap "${stage.name}"?`,
                  confirmText: "Usuń",
                  callback: () => deleteMutation.mutate(String(stage.relation!.id)),
                });
              }
            }}
          >
            <img src={binIcon} alt="Usuń" />
          </Button>
        </div>
      </div>
      <div className="d-flex align-items-center justfiy-content-center h-100">
        <div
          className={styles.colorLabel}
          style={{
            backgroundColor: stage.backgroundColor,
          }}
        ></div>
      </div>
      <div>
        {stage.implementedBy === "CONTRACTOR" ? (
          <StateLabel kind="limeBlack"> kontrahent</StateLabel>
        ) : stage.implementedBy === "IN_HOUSE" ? (
          <StateLabel kind="deepPurple">na miejscu</StateLabel>
        ) : (
          EMPTY_VALUE
        )}
      </div>
      <div>{stage.code}</div>
      <div className="pr-1" onClick={event => event.stopPropagation()}>
        <Select.Async
          items={[
            {
              text: "brak",
              type: MenuItemType.TEXT,
              value: (null as unknown) as string | number,
            },
          ].concat(stagesOptions)}
          mutationHook={() =>
            manufacturingSchemaActions.usePatchManufacturingSchemaStages(panelKey)
          }
          transformQueryData={nextStageId => ({
            id: stage.relation!.id,
            child: nextStageId as number,
          })}
          selected={stage.relation?.child ?? null}
        />
      </div>
      <div className="d-flex justify-content-center">
        <Radio.Async
          mutationHook={() =>
            manufacturingSchemaActions.usePatchManufacturingSchemaStages(panelKey)
          }
          transformQueryData={isFirst => ({
            id: stage.relation!.id,
            isFirst,
          })}
          checked={stage.isFirst}
          size="sm"
        />
      </div>
      <div className="d-flex justify-content-center">
        <Radio.Async
          mutationHook={() =>
            manufacturingSchemaActions.usePatchManufacturingSchemaStages(panelKey)
          }
          transformQueryData={isLast => ({
            id: stage.relation!.id,
            isLast,
          })}
          checked={stage.isLast}
          size="sm"
        />
      </div>
      <div>{stage.createdAt ? dateUtils.formatDateToDisplay(stage.createdAt) : EMPTY_VALUE}</div>
      <div>
        {stage.addedBy ? (
          <AvatarWithTooltip className={styles.showTooltip} user={stage.addedBy} />
        ) : (
          EMPTY_VALUE
        )}
      </div>
    </RightPanelTableRow>
  );
};
