import { filterFactory } from "components/utils/withFilters";
import { tradingDocumentFilterFactory } from "pages/tradingDocuments/shared/filtersFactory/filtersFactory";

export const filterList = filterFactory(({ FilterType }) => {
  const factory = tradingDocumentFilterFactory(FilterType);
  return [
    factory.invoiceDeliveryDateRange,
    factory.invoiceIssueDateRange,
    factory.countryCode,
    factory.salesAccount,
    factory.isPaid,
    factory.currency,
    factory.paymentType,
    factory.isAssigned,
    factory.customers,
    factory.transferDate,
    factory.isPaymentOverdue,
    factory.transferNumber,
  ];
});
