import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery } from "hooks";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { DownloadTradingDocumentPdf } from "pages/tradingDocuments/shared/components/downloadTradingDocumentPdf/DownloadTradingDocumentPdf";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import styles from "../../../shared/TradingDocumentsLists.module.css";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { assertIsDefined } from "utilities/assertIsDefined";
import { TradingDocumentOptionsMenuFactory } from "pages/tradingDocuments/shared/optionsMenuFactory/TradingDocumentOptionsMenuFactory";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { useDrawer } from "hooks/useDrawer";

export const PanelHeader = () => {
  const { close } = useDrawer("correctionInvoices");
  const { query } = useQuery();
  const { panelId } = query;
  const { data: tradingDocument } = useTradingDocument({ id: panelId });
  assertIsDefined(tradingDocument);
  const optionsMenu = TradingDocumentOptionsMenuFactory({ close, tradingDocument });
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper className="gap-2">
          <ConfirmCheckbox tradingDocument={tradingDocument} />
          <div>Faktura VAT&nbsp;{tradingDocument.signature}</div>
        </SlidingHeaderWrapper>
      ) : (
        <ConfirmTradingDocument tradingDocument={tradingDocument} />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        <DownloadTradingDocumentPdf tradingDocument={tradingDocument} />
        <Menu
          className={styles.optionsMenu}
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            optionsMenu.downloadTradingDocumentXML(),
            optionsMenu.downloadRevisor(),
            optionsMenu.downloadFakir(),
            { type: MenuItemType.DIVIDER },
            optionsMenu.tradingDocumentHistory(panelId),
            { type: MenuItemType.DIVIDER },
            optionsMenu.deleteTradingDocument(),
          ]}
        />
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
