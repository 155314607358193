import { MyImportInvoicesTab, TradingDocumentTab } from "api/trading-documents/models";

export const tradingDocumentsTabDict: Record<TradingDocumentTab, string> = {
  all: "Wszystkie",
  paid: "Opłacone",
  "not-paid": "Nieopłacone",
  "partially-paid": "Częściowo opłacone",
};
export const getTabs = (): { label: string; name: string }[] => {
  return Object.entries(tradingDocumentsTabDict).map(([name, label]) => ({ label, name }));
};

export const myImportTabDict: Record<MyImportInvoicesTab, string> = {
  all: "Wszystkie",
  opened: "Otwarte",
  closed: "Zamknięte",
};
export const getMyImportTabs = (): { label: string; name: string }[] => {
  return Object.entries(myImportTabDict).map(([name, label]) => ({ label, name }));
};
