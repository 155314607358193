import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiSquare = (props: BaseIcon) => {
  return (
    <SvgWrapper {...props}>
      <path d="M2 5.33333C2 3.49238 3.49238 2 5.33333 2H18.6667C20.5076 2 22 3.49238 22 5.33333V18.6667C22 20.5076 20.5076 22 18.6667 22H5.33333C3.49238 22 2 20.5076 2 18.6667V5.33333Z" />
    </SvgWrapper>
  );
};
