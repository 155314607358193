import { useTradingDocument } from "api/trading-documents/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { AmountSection } from "pages/tradingDocuments/shared/components/rightPanel/amountSection/AmountSection";
import { CorrectionSection } from "pages/tradingDocuments/shared/components/rightPanel/correctionSection/CorrectionSection";
import { CorrespondingDocumentSection } from "pages/tradingDocuments/shared/components/rightPanel/correspondingDocumentsSection/CorrespondingDocumentSection";
import { GeneralInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/generalInfoSection/GeneralInfoSection";
import { NotificationSection } from "pages/tradingDocuments/shared/components/rightPanel/notificationSection/NotificationSection";
import { TitleSection } from "pages/tradingDocuments/shared/components/rightPanel/titleSection/TitleSection";
import { PanelHeader } from "./components/PanelHeader";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { SellerInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/sellerInfoSection/SellerInfoSection";
import { useRef } from "react";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { ItemsSection } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/tradingDocumentItems/ItemsSection";
import { FinancesPayments } from "pages/tradingDocuments/shared/components/rightPanel/paymentSection/FinancesPayments";
import { ReceiptFiscalization } from "pages/tradingDocuments/shared/components/rightPanel/receiptFiscalization/ReceiptFiscalization";
import { ReceiptPrintingDisabledStatus } from "api/trading-documents/models";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: tradingDocument, error, isLoading } = useTradingDocument(
    { id: panelId },
    { enabled: Boolean(panelId) },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!tradingDocument) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={tradingDocument.id}>
        <PanelHeader />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody myRef={clearCommentsRef} hasCommentsModule>
            <TitleSection tradingDocument={tradingDocument} />
            {tradingDocument.invoiceType === "RECEIPT" &&
              tradingDocument.status === "CONFIRMED" && (
                <ReceiptFiscalization
                  showCorrespondingReceiptDetails={false}
                  showItemsWithoutAmountWarning={
                    tradingDocument.printingAvailability.printingDisabledStatus ===
                      ReceiptPrintingDisabledStatus.RECEIPT_0_AMOUNT &&
                    (tradingDocument.printingStatus === "NOT_STARTED" ||
                      tradingDocument.printingStatus === "FAILED")
                  }
                  tradingDocument={tradingDocument}
                />
              )}
            <GeneralInfoSection tradingDocument={tradingDocument} />
            <AmountSection tradingDocument={tradingDocument} />
            {Boolean(tradingDocument.items.length) && (
              <ItemsSection tradingDocument={tradingDocument} />
            )}
            <FinancesPayments tradingDocument={tradingDocument} />
            <NotificationSection tradingDocument={tradingDocument} />
            {Boolean(tradingDocument.corrections.length) && (
              <CorrectionSection tradingDocument={tradingDocument} />
            )}
            {Boolean(tradingDocument.correspondingDocuments.length) && (
              <CorrespondingDocumentSection tradingDocument={tradingDocument} />
            )}
            <SellerInfoSection tradingDocument={tradingDocument} />
          </RightPanelBody>
          <CommentsGenericSection
            outsideRef={clearCommentsRef}
            paramName="tradingDocument"
            endpointUrl="/finances/comments/items"
            commentedObject={tradingDocument}
          />
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
