import { filterFactory } from "components/utils/withFilters";
import { tradingDocumentFilterFactory } from "../shared/filtersFactory/filtersFactory";

export const filterList = filterFactory(({ FilterType }) => {
  const factory = tradingDocumentFilterFactory(FilterType);
  return [
    factory.invoiceDeliveryDateRange,
    factory.invoiceIssueDateRange,
    factory.countryCode,
    factory.salesAccount,
    {
      type: FilterType.Select,
      label: "status paragonu",
      name: "status",
      options: [
        { label: "Zatwierdzone", value: "CONFIRMED" },
        { label: "Niezatwierdzone", value: "NEW" },
      ],
    },
    factory.isPaid,
    factory.currency,
    factory.paymentType,
    factory.isAssigned,
    factory.customers,
    factory.transferDate,
    factory.isPaymentOverdue,
    factory.transferNumber,
  ];
});
