import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import styles from "./UploadTransactionFiles.module.css";
import { ToggleHookState } from "hooks";
import { MdiAddCsv } from "components/miloDesignSystem/atoms/icons/MdiAddCsv";
import { MdiAddTxt } from "components/miloDesignSystem/atoms/icons/MdiAddTxt";

interface Props {
  close: () => void;
  uploadCsvAllegroModal: ToggleHookState;
  uploadCsvEbayModal: ToggleHookState;
  uploadCsvPaypalModal: ToggleHookState;
  uploadCsvKauflandModal: ToggleHookState;
  uploadTxtAmazonModal: ToggleHookState;
}

export const UploadTransactionFiles = ({
  close,
  uploadCsvAllegroModal,
  uploadCsvEbayModal,
  uploadCsvKauflandModal,
  uploadCsvPaypalModal,
  uploadTxtAmazonModal,
}: Props) => {
  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Importuj pliki
        </Typography>
      }
      width={480}
    >
      <div className="w-100">
        <div className="w-100 mb-2">
          <div
            className={styles.option}
            onClick={() => {
              uploadCsvAllegroModal.open();
              close();
            }}
          >
            <MdiAddCsv />
            <Typography fontSize="16" fontWeight="700">
              CSV - Allegro
            </Typography>
          </div>
          <div
            className={styles.option}
            onClick={() => {
              uploadCsvEbayModal.open();
              close();
            }}
          >
            <MdiAddCsv />
            <Typography fontSize="16" fontWeight="700">
              CSV - Ebay
            </Typography>
          </div>
          <div
            className={styles.option}
            onClick={() => {
              uploadCsvPaypalModal.open();
              close();
            }}
          >
            <MdiAddCsv />
            <Typography fontSize="16" fontWeight="700">
              CSV - Paypal
            </Typography>
          </div>
          <div
            className={styles.option}
            onClick={() => {
              uploadCsvKauflandModal.open();
              close();
            }}
          >
            <MdiAddCsv />
            <Typography fontSize="16" fontWeight="700">
              CSV - Kaufland
            </Typography>
          </div>
          <div
            className={styles.option}
            onClick={() => {
              uploadTxtAmazonModal.open();
              close();
            }}
          >
            <MdiAddTxt />
            <Typography fontSize="16" fontWeight="700">
              TXT - Amazon
            </Typography>
          </div>
        </div>
      </div>
    </Modal>
  );
};
