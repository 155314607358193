import {
  getIndex,
  getIndexAssignments,
  getIndexes,
  getIndexesFulfillment,
  getIndexesManufacturers,
  getIndexGreatestWeight,
} from "api/indexes/calls";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";
import { createApiQuery } from "hooks/createApiQuery";
export const useIndex = createPrimitiveHook(getIndex);
export const useIndexes = createPrimitivePaginatedHook(getIndexes);
export const useIndexAssignments = createPrimitiveHook(getIndexAssignments);
export const useIndexesManufacturers = createApiQuery(getIndexesManufacturers);
export const useIndexGreatestWeight = createApiQuery(getIndexGreatestWeight);
export const useIndexesFulfillment = createPaginatedApiQuery(getIndexesFulfillment);
