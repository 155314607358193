import { carrierOrdersActions } from "api/logistics/carrierOrders/actions";
import { CarrierOrder, CarrierOrderEmailMessage } from "api/logistics/carrierOrders/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { RightPanelSection } from "components/utils/drawer";
import { useEmailsColumns } from "./useEmailsColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useFilters } from "hooks/useFilters";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiSend } from "components/miloDesignSystem/atoms/icons/MdiSend";
import { useToggle } from "hooks";
import { SendEmailToCustomRecipient } from "./SendEmailToCustomRecipient";

interface Props {
  carrierOrder: CarrierOrder;
}

export const EmailsSection = ({ carrierOrder }: Props) => {
  const { searchParams, setFilter } = useFilters({
    page: 1,
    speditionOrderId: carrierOrder.id,
  });
  const {
    data: emails,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = carrierOrdersActions.useCarrierOrdersEmailMessages(searchParams);
  const columns = useEmailsColumns();
  const tableProps = useTableFeatureConnector({
    rows: emails,
  });
  const sendEmailMutation = carrierOrdersActions.useSendCarrierOrdersEmailMessage();
  const sendEmailWithCustomRecipientModal = useToggle();

  return (
    <RightPanelSection title="Dokumenty przewozowe">
      <Table<CarrierOrderEmailMessage>
        columns={columns}
        error={error}
        onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
        pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
        isLoading={isLoading || isPreviousData}
        uiSchema={comfortableListUiSchema}
        {...tableProps}
        overrides={() => {
          return {
            hideHeader: !Boolean(emails.length),
            noResultComponent: (
              <EmptySection
                AdditionalInfoRenderer={
                  <div className="d-flex align-items-center gap-2 py-2">
                    <Button
                      className="text-uppercase"
                      isLoading={sendEmailMutation.isLoading}
                      onClick={() =>
                        sendEmailMutation.mutate({
                          speditionOrderId: carrierOrder.id,
                        })
                      }
                      size="small"
                      startIcon={MdiSend}
                      variant="gray"
                    >
                      Wyślij teraz
                    </Button>
                    <Button
                      className="text-uppercase"
                      onClick={sendEmailWithCustomRecipientModal.open}
                      size="small"
                      startIcon={MdiSend}
                      variant="gray"
                    >
                      Wskaż adres i wyślij
                    </Button>
                  </div>
                }
                label="Jeszcze nie wysłano żadnego dokumentu przewozowego"
              />
            ),
          };
        }}
      />
      {!error && Boolean(emails.length) && (
        <div className="d-flex align-items-center gap-2 py-2">
          <Button
            className="text-uppercase"
            isLoading={sendEmailMutation.isLoading}
            onClick={() =>
              sendEmailMutation.mutate({
                speditionOrderId: carrierOrder.id,
              })
            }
            size="small"
            startIcon={MdiSend}
            variant="gray"
          >
            Wyślij ponownie
          </Button>
          <Button
            className="text-uppercase"
            onClick={sendEmailWithCustomRecipientModal.open}
            size="small"
            startIcon={MdiSend}
            variant="gray"
          >
            Wskaż adres i wyślij
          </Button>
        </div>
      )}
      {sendEmailWithCustomRecipientModal.isOpen && (
        <SendEmailToCustomRecipient
          carrierOrder={carrierOrder}
          close={sendEmailWithCustomRecipientModal.close}
        />
      )}
    </RightPanelSection>
  );
};
