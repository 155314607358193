import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { financesPaymentsApi } from "./api";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { financesPaymentsKeys } from "./keys";

const useFinancesPayments = createPaginatedApiQuery(financesPaymentsApi.getFinancesPayments);

const useCreatePayment = () => {
  return useMutation(financesPaymentsApi.postFinancesPayment, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano płatność",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useDeletePayment = () => {
  return withDeleteConfirmation(
    useMutation(financesPaymentsApi.deleteFinancesPayment, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(financesPaymentsKeys.list());
        toastr.open({
          type: "success",
          title: "Udało się",
          text: "Usunięto dokument kasowy KP",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć to KP?",
  )();
};

export const financesPaymentsActions = {
  useCreatePayment,
  useDeletePayment,
  useFinancesPayments,
};
