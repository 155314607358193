import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./CreateInvoiceModal.module.css";
import { useSelector } from "hooks";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { useState } from "react";
import { Seller } from "api/sellers/models";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";

interface Props {
  close: () => void;
}

type SelectableSeller = Pick<Seller, "id" | "companyName" | "taxId">;

export const CreateInvoiceModal = ({ close }: Props) => {
  const sellers = useSelector(store => store.partials.businessEntities);
  const sellersOptions: SelectableSeller[] = sellers
    .filter(seller => seller.kind === "INTERNAL")
    .map(seller => ({
      id: seller.id,
      companyName: seller.companyName,
      taxId: seller.taxId,
    }));
  const [selectedSeller, setSelectedSeller] = useState<SelectableSeller | null>(null);
  const createDraftDocumentMutation = tradingDocumentsActions.useCreateDraftDocument();
  const [searchedTerm, setSearchedTerm] = useState("");

  const isSellerSelected = (seller: SelectableSeller) => {
    return selectedSeller?.id === seller.id;
  };

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Wybierz nabywcę
        </Typography>
      }
    >
      <div>
        <div className={styles.contentBody}>
          <TextField
            endIcon={<MdiSearch size="18" />}
            onChange={event => setSearchedTerm(event.target.value)}
            placeholder="Szukaj..."
            value={searchedTerm}
          />
          <div className={styles.contentTable}>
            {filterSellers(sellersOptions, searchedTerm).map(seller => (
              <div className={styles.sellerItem}>
                <Radio
                  checked={isSellerSelected(seller)}
                  onClick={() => setSelectedSeller(seller)}
                  size="sm"
                />
                <div>
                  <Typography color="neutralBlack88" fontSize="12" fontWeight="700">
                    {seller.companyName}
                  </Typography>
                  <Typography
                    className={styles.taxIdDisplay}
                    color="neutralBlack48"
                    fontSize="10"
                    fontWeight="600"
                  >
                    NIP: {seller.taxId ?? EMPTY_VALUE}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.buttonSection}>
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Anuluj
          </Button>
          <Button
            className="text-uppercase"
            disabled={!selectedSeller}
            endIcon={MdiArrowForward}
            isLoading={createDraftDocumentMutation.isLoading}
            onClick={() =>
              createDraftDocumentMutation.mutate({
                businessEntityId: selectedSeller!.id,
              })
            }
            size="medium"
            variant="deepPurple"
          >
            Kontynuuj
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const filterSellers = (sellers: SelectableSeller[], searchedTerm: string): SelectableSeller[] => {
  return sellers.filter(seller => {
    const companyNameWords = seller.companyName.toLowerCase().split(" ");
    const matchesCompanyName = companyNameWords.some(word =>
      word.includes(searchedTerm.toLowerCase()),
    );
    const matchesTaxId = seller.taxId.includes(searchedTerm);
    return matchesCompanyName || matchesTaxId;
  });
};
