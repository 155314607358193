import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";
import styles from "./Header.module.css";
import { useQuery, useSelector } from "hooks";
import { SchemaSearch } from "../../schemaSearch/SchemaSearch";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiWarehouseAlt } from "components/miloDesignSystem/atoms/icons/MdiWarehouseAlt";
import { warehouseConstants } from "constants/warehouse";
import { MdiHall } from "components/miloDesignSystem/atoms/icons/MdiHall";

export const Header = () => {
  const halls = useSelector(store => store.partials.halls);
  const warehouses = useSelector(store => store.partials.warehouses);
  const { query, updateQuery } = useQuery();
  const { hallId } = query;
  const selectedHall = halls.find(hall => hall.id === Number(hallId));
  const warehouseHalls = halls.filter(hall => hall.warehouse === selectedHall?.warehouse);
  const selectedWarehouse = halls.find(hall => hall.id === Number(hallId))?.warehouse;

  return (
    <div
      className={cx(
        "d-flex align-items-center justify-content-between borderBottomB px-3 pt-2",
        styles.header,
      )}
    >
      <div className="d-flex align-items-center">
        <Typography fontSize="20" fontWeight="700" color="neutralWhite100" className="pr-3">
          Magazyn
        </Typography>
        <SchemaSearch textFieldProps={{ containerClassName: styles.input }} />
      </div>
      <div className="d-flex align-items-center gap-4">
        <div className="d-flex align-items-center gap-2">
          <div className="d-flex align-items-center gap-2">
            <MdiWarehouseAlt color="yellow300" size="18" />
            <Typography
              fontSize="10"
              fontWeight="700"
              color="neutralWhite76"
              className="text-uppercase"
            >
              Magazyn:
            </Typography>
          </div>
          <div className="d-flex align-items-center gap-1">
            {warehouses.map(warehouse => (
              <div
                className="cursor-pointer d-flex"
                key={warehouse.id}
                onClick={() =>
                  updateQuery({
                    hallId: Number(halls.filter(hall => hall.warehouse === warehouse.id)?.[0]?.id),
                  })
                }
              >
                <Tag
                  label={warehouse.name}
                  startIcon={warehouseConstants.getWarehouseIcons(warehouse.icon)}
                  type={selectedWarehouse === warehouse.id ? "filled" : "outlined"}
                  variant={selectedWarehouse === warehouse.id ? "deepPurple50" : "quaternary"}
                  theme="dark"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="d-flex align-items-center gap-2">
            <MdiHall color="cyan200" size="18" />
            <Typography
              fontSize="10"
              fontWeight="700"
              color="neutralWhite76"
              className="text-uppercase"
            >
              Hala:
            </Typography>
          </div>
          <div className="d-flex align-items-center gap-1">
            {warehouseHalls.map(hall => (
              <div
                className="cursor-pointer d-flex"
                key={hall.id}
                onClick={() => updateQuery({ hallId: Number(hall.id) })}
              >
                <Tag
                  label={hall.name}
                  theme="dark"
                  type={hallId === String(hall.id) ? "filled" : "outlined"}
                  variant={hallId === String(hall.id) ? "deepPurple50" : "quaternary"}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
