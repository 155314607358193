import { financesPaymentsFileFactory } from "api/finances/payments/api";
import { FinancesPaymentListItem } from "api/finances/payments/models";
import { TradingDocument } from "api/trading-documents/models";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Assign } from "utility-types";

interface Props {
  financesPayments:
    | FinancesPaymentListItem[]
    | Assign<TradingDocument["payments"][number], { signature: string }>[];
}

export const DownloadPaymentsPDF = ({ financesPayments }: Props) => {
  const isBulk = financesPayments.length > 1;

  return (
    <FileDownloadHandler
      factoryFn={() =>
        financesPaymentsFileFactory.proofOfPaymentPdf(
          financesPayments.map(payment => payment.id),
          isBulk ? undefined : financesPayments[0].signature,
        )
      }
      type="pdf"
    >
      {({ download, isLoading }) => (
        <div onClick={event => event.stopPropagation()}>
          <Tooltip title={isBulk ? "Pobierz PDF dla zaznaczonych" : "Pobierz PDF KP"}>
            <IconButton
              icon={MdiDownloadPdf}
              isLoading={isLoading}
              onClick={download}
              theme={isBulk ? "dark" : "light"}
              variant="transparent"
            />
          </Tooltip>
        </div>
      )}
    </FileDownloadHandler>
  );
};
