import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiDownloadFakir = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path d="M5 5H9.09V6.24H6.8V7.37H8.69V8.46H6.8V10.7H5V5Z" />
    <path d="M15.0177 10.7H13.0477L11.9377 8.57C11.9177 8.53667 11.8644 8.44333 11.7777 8.29C11.6977 8.13667 11.6077 7.99 11.5077 7.85V10.7H9.70773V5H11.5077V7.59C11.6211 7.46333 11.7177 7.32667 11.7977 7.18C11.8844 7.02667 11.9344 6.93667 11.9477 6.91L13.0177 5H14.9177L13.7777 6.91C13.7577 6.93667 13.7144 7.00667 13.6477 7.12C13.5811 7.23333 13.5077 7.34 13.4277 7.44C13.3544 7.53333 13.2744 7.62 13.1877 7.7C13.3077 7.81333 13.4044 7.92667 13.4777 8.04C13.5577 8.14667 13.6477 8.29 13.7477 8.47L15.0177 10.7Z" />
    <path d="M15.63 19.9211L19.7404 15.8107L18.3018 14.372L16.6576 15.9648V11.7002H14.6024V15.9648L12.9582 14.372L11.5195 15.8107L15.63 19.9211Z" />
    <path d="M1 1H17.617C20.59 1 23 3.41004 23 6.38298V23H6.38298C3.41004 23 1 20.59 1 17.617V1ZM2.40426 2.40426V17.617C2.40426 19.8144 4.18559 21.5957 6.38298 21.5957H21.5957V6.38298C21.5957 4.18559 19.8144 2.40426 17.617 2.40426H2.40426Z" />
  </SvgWrapper>
);
