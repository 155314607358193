import styled from "@emotion/styled";
import { BaseIcon } from "../icons/types";
import { ColorPalette } from "../colorsPalette";

export type IconRendererIcon = React.FunctionComponent<BaseIcon> | JSX.Element | string;

export const IconRenderer = ({
  size,
  icon,
  color,
}: {
  icon: IconRendererIcon;
  size: number;
  color: ColorPalette;
}) => {
  if (typeof icon === "string") {
    return <StyledImage src={icon} width={size} alt="ikona" />;
  }
  if (typeof icon === "function") {
    const Icon = icon;
    return <Icon color={color} size={String(size)} />;
  }
  return icon;
};

const StyledImage = styled.img<{ width: number }>`
  width: ${props => props.width}px;
  height: ${props => props.width}px;
`;
