import { Shipment } from "api/shipping/models";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../../RightPanel.module.css";
import { cx } from "utilities";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { shippingActions } from "api/shipping/actions";

interface Props {
  shipment: Shipment;
}

export const ReturnShipmentSection = ({ shipment }: Props) => {
  return (
    <RightPanelSection>
      <div
        className={cx("w-100 pt-2 px-2", {
          [styles.returnShipment]: shipment.isReturnShipment,
        })}
      >
        <Checkbox.Async
          checked={shipment.isReturnShipment}
          label="Zwrot przesyłki"
          mutationHook={shippingActions.usePatchShipping}
          size="sm"
          transformQueryData={isReturnShipment => ({
            id: shipment.id,
            toUpdate: {
              isReturnShipment,
            },
          })}
        />
      </div>
    </RightPanelSection>
  );
};
