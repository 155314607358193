import { tradingDocumentsActions } from "api/trading-documents/actions";
import { BulkSalesInvoiceConfirmPreview, TradingDocument } from "api/trading-documents/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { useQuery, useStateModal } from "hooks";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";

export const ConfirmReceipts = ({ selectedRows }: { selectedRows: TradingDocument[] }) => {
  const { query } = useQuery();
  const panelId = query.panelId;
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const bulkConfirmReceiptsMutation = tradingDocumentsActions.useBulkConfirmReceipts(panelId);

  return (
    <>
      <Tooltip title="Zatwierdź paragony">
        <IconButton
          icon={MdiCheck}
          onClick={() =>
            bulkConfirmReceiptsMutation.mutate(
              {
                tradingDocumentsIds: selectedRows.map(tradingDocument => tradingDocument.id),
              },
              {
                onSuccess: payload => {
                  replyModal.open(payload.message);
                },
              },
            )
          }
          variant="transparent"
          theme="dark"
        />
      </Tooltip>
      {replyModal.isOpen && replyModal.state && (
        <ReplyModal
          bulkInvoiceConfirmation={replyModal.state}
          close={replyModal.close}
          hasReceipts
        />
      )}
    </>
  );
};
