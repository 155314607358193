import { useProductsQuery } from "api/products/hooks";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError, Spinner } from "components/utils";
import { BuyingPriceModalContent } from "./BuyingPriceModalContent";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useFilters } from "hooks/useFilters";
import { useState } from "react";
import { Product } from "api/products/models";
import styles from "./BuyingPriceModal.module.css";
import { cx } from "utilities";

interface Props {
  close: () => void;
}

export const BuyingPriceModal = ({ close }: Props) => {
  const { searchParams, setFilter } = useFilters({ page: 1, search: "" });
  const { data: products, isLoading, isPreviousData, error, pagination } = useProductsQuery(
    searchParams,
  );
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  if (isLoading)
    return (
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Pobieranie pliku z cenami zakupowymi
          </Typography>
        }
        width={600}
      >
        <div className={cx("pb-3", styles.modalContainer)}>
          <div className="d-flex align-items-center justify-content-center">
            <Spinner color="blue" on={isLoading} size="big" text="trwa wczytywanie produktów" />
          </div>
        </div>
      </Modal>
    );

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Pobieranie pliku z cenami zakupowymi
          </Typography>
        }
        width={600}
      >
        <div className={cx("pb-3", styles.modalContainer)}>
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  assertIsDefined(products);

  return (
    <BuyingPriceModalContent
      close={close}
      error={error}
      isPreviousData={isPreviousData}
      isLoading={isLoading}
      pagination={pagination}
      products={products}
      selectedProducts={selectedProducts}
      setFilter={setFilter}
      setSelectedProducts={setSelectedProducts}
    />
  );
};
