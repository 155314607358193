import { DrawerSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { ToolbarAdvancedDateFilter } from "../toolbar/types";
import styles from "./FiltersDrawer.module.css";
import cx from "classnames";
import { DatePicker } from "components/utils/datePicker";
import { dateFns } from "utilities";
import { clickOutsideIgnoreClassToolbar } from "../toolbar";

interface Props {
  filter: ToolbarAdvancedDateFilter;
  query: Record<string, string>;
}

export const AdvancedDateFilter = ({ filter, query }: Props) => {
  const { updateQuery } = useQuery();

  return (
    <DrawerSection title={filter.label}>
      <div className="mb-2 d-flex align-items-center">
        <div className={styles.monthsContainer}>
          {filter.options.map(option => (
            <div
              className={cx(styles.month, "d-flex align-items-center justify-content-center", {
                [styles.activeMonth]:
                  option.value[0] === query[filter.range[0]] &&
                  option.value[1] === query[filter.range[1]],
              })}
              key={option.label}
              onClick={() => {
                if (
                  option.value[0] === query[filter.range[0]] &&
                  option.value[1] === query[filter.range[1]]
                ) {
                  updateQuery({
                    [filter.range[0]]: "",
                    [filter.range[1]]: "",
                    page: 1,
                  });
                } else {
                  updateQuery({
                    [filter.range[0]]: option.value[0],
                    [filter.range[1]]: option.value[1],
                    page: 1,
                  });
                }
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
        <div className={cx(styles.timeInputBox, "mb-1")}>
          <div className="d-flex align-items-center">
            <div className="fs-14 text-color-grey mr-3">Od</div>
            <div className="d-flex align-items-center">
              <DatePicker
                className={cx("d-inline-flex mr-2", styles.input)}
                onChange={date => {
                  if (!date) {
                    updateQuery({ [filter.range[0]]: "", page: 1 });
                  } else {
                    updateQuery({
                      [filter.range[0]]: dateFns.format(new Date(date), "yyyy-MM-dd"),
                      page: 1,
                    });
                  }
                }}
                overwrites={{
                  popup: { className: cx(styles.datePickerPopup, clickOutsideIgnoreClassToolbar) },
                }}
                value={query[filter.range[0]] ?? ""}
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="fs-14 text-color-grey mr-3">Do</div>
            <div className="d-flex align-items-center">
              <DatePicker
                className={cx("d-inline-flex mr-2", styles.input)}
                onChange={date => {
                  if (!date) {
                    updateQuery({ [filter.range[1]]: "", page: 1 });
                  } else {
                    updateQuery({
                      [filter.range[1]]: dateFns.format(new Date(date), "yyyy-MM-dd"),
                      page: 1,
                    });
                  }
                }}
                overwrites={{
                  popup: { className: cx(styles.datePickerPopup, clickOutsideIgnoreClassToolbar) },
                }}
                value={query[filter.range[1]] ?? ""}
              />
            </div>
          </div>
        </div>
      </div>
    </DrawerSection>
  );
};
