import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiRedo } from "components/miloDesignSystem/atoms/icons/MdiRedo";

interface Props {
  close: () => void;
  manufacturingStage: ManufacturingStage;
}

export const PanelHeader = ({ close, manufacturingStage }: Props) => {
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      <div className="d-flex w-100 align-items-center">
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="nowrap">{manufacturingStage.name}</SlidingHeaderWrapper>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Button
            className="text-uppercase"
            disabled
            size="small"
            startIcon={MdiRedo}
            variant="gray"
          >
            Przywróć ustawienia domyślne
          </Button>
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </div>
    </RightPanelHeader>
  );
};
