import * as React from "react";
import { useField } from "formik";
import styles from "./FormCheckbox.module.css";
import cx from "classnames";

/**
 * @deprecated
 */
export const FormCheckbox: React.FC<{ label: string; name: string; disabled?: boolean }> = ({
  label,
  children,
  disabled = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <label className={cx(styles.box)}>
        <input
          className={styles.input}
          type="checkbox"
          {...field}
          {...props}
          checked={field.value}
          disabled={disabled}
        />
        <span className={styles.checkmark}></span>
        <span className={styles.label}>{label}</span>
      </label>
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );
};
