import { Pagination } from "api/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { financesOverviewKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";
import { SoldIndex, SoldPackage } from "./models";
import { queryString } from "utilities";
import { fileFactoryUtils } from "utilities/fileFactory";

const getSoldPackages = (search: string = ""): ApiFetcher<Pagination<SoldPackage>> => {
  const query = queryString.parse(search);
  const overwrittenSearch = queryString.stringify({ ...query, response_format: "json" });
  return {
    key: financesOverviewKeys.soldPackages.list(search),
    fetcher: () =>
      queryFetch({
        method: "GET",
        url: "/orders/sold-packages" + overwrittenSearch,
      }),
  };
};

const getSoldIndexes = (search: string = ""): ApiFetcher<Pagination<SoldIndex>> => {
  const query = queryString.parse(search);
  const overwrittenSearch = queryString.stringify({ ...query, response_format: "json" });
  return {
    key: financesOverviewKeys.soldIndexes.list(search),
    fetcher: () =>
      queryFetch({
        method: "GET",
        url: "/orders/sold-indexes" + overwrittenSearch,
      }),
  };
};

export const financesOverviewFileFactory = (() => {
  return {
    soldPackagesCsv: (search: string) => ({
      url: `/orders/sold-packages${search}`,
      name: `Zestawienie-sprzedanych-paczek-${fileFactoryUtils.getDate()}`,
    }),
    soldIndexesCsv: (search: string) => ({
      url: `/orders/sold-indexes${search}`,
      name: `Zestawienie-sprzedanych-indeksów-${fileFactoryUtils.getDate()}`,
    }),
  };
})();

export const financesOverviewApi = {
  getSoldPackages,
  getSoldIndexes,
};
