import { carsActions } from "api/cars/actions";
import { Car } from "api/cars/models";
import { Carrier } from "api/logistics/carriers/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { useCarsColumns } from "./useCarsColumns";
import { useFilters } from "hooks/useFilters";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";

interface Props {
  carrier: Carrier;
}

export const CarsSection = ({ carrier }: Props) => {
  const { searchParams, setFilter } = useFilters({ page: 1, carriers: carrier.id });
  const { data: cars, error, isLoading, isPreviousData, pagination } = carsActions.useCars(
    searchParams,
  );
  const columns = useCarsColumns();
  const tableProps = useTableFeatureConnector({
    rows: cars,
  });

  return (
    <RightPanelSection title="Samochody">
      <Table<Car>
        columns={columns}
        error={error}
        onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
        pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
        isLoading={isLoading || isPreviousData}
        uiSchema={comfortableListUiSchema}
        {...tableProps}
        overrides={() => {
          return {
            hideHeader: !Boolean(cars.length),
            noResultComponent: <EmptySection label="Brak samochodów" />,
          };
        }}
      />
    </RightPanelSection>
  );
};
