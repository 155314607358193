import cx from "classnames";
import styles from "./FiltersDrawer.module.css";
import { DatePicker } from "components/utils/datePicker";
import { Button } from "components/common";
import { useQuery } from "hooks";
import { RadioGroup } from "components/utils";
import { dateFns } from "utilities";
import { Props as ToolbarProps } from "../toolbar";
import { SearchFilter } from "./SearchFilter";
import { SearchMultipleAttributeFilter } from "./SearchMultipleAttributesFilter";
import { AutocompleteAsyncFilter } from "./AutocompleteAsyncFilter";
import { DrawerSection } from "components/utils/drawer";
import { SearchMultipleFilter } from "./SearchMultipleFilter";
import { AdvancedDateFilter } from "./AdvancedDateFilter";
import { clickOutsideIgnoreClassToolbar } from "../toolbar";

type Filters = ToolbarProps["drawerFilters"];

interface Props {
  filtersDrawerOpen: boolean;
  filters: Filters;
}

export const FiltersDrawer = ({ filtersDrawerOpen, filters }: Props) => {
  const { query, updateQuery, setQuery } = useQuery({ exclude: ["panelId"] });

  const onRadioChange = (
    item: { name: React.ReactNode; id: string | number } | null,
    name: string,
  ) => {
    if (item) {
      updateQuery({ [name]: item.id, page: 1 });
    }
  };

  return (
    <div className={cx(styles["map-drawer"], { [styles["closed"]]: !filtersDrawerOpen })}>
      <div className={styles["drawer_inner-wrapper"]}>
        <div className={styles["drawer_content"]}>
          <div className={cx(styles["drawer_sections"])}>
            <Button
              kind="secondary-stroke"
              size="small"
              className={cx(styles.clearBtn, "mb-2 mt-2")}
              onClick={() => setQuery({})}
            >
              Wyczyść filtry
            </Button>

            {filters?.map((filter, index) => {
              if (filter.type === "selectWithProducts") {
                return <AutocompleteAsyncFilter key={index} type="selectWithProducts" />;
              }
              if (filter.type === "excludeWithProducts") {
                return <AutocompleteAsyncFilter key={index} type="excludeWithProducts" />;
              }
              if (filter.type === "search") {
                return (
                  <SearchFilter
                    key={index}
                    query={query}
                    filter={filter}
                    onRadioChange={onRadioChange}
                  />
                );
              }
              if (filter.type === "advancedDate") {
                return <AdvancedDateFilter filter={filter} key={index} query={query} />;
              }

              if (filter.type === "searchMultipleAttribute") {
                return <SearchMultipleAttributeFilter key={index} query={query} filter={filter} />;
              }
              if (filter.type === "searchMultiple") {
                return <SearchMultipleFilter key={index} query={query} filter={filter} />;
              }

              if (filter.type === "date") {
                return (
                  <DrawerSection title={filter.label} key={index}>
                    <div className="mb-2 d-flex">
                      <div className="mt-2">
                        <DatePicker
                          overwrites={{
                            popup: {
                              className: cx(styles.datePickerPopup, clickOutsideIgnoreClassToolbar),
                            },
                          }}
                          onChange={date => {
                            if (!date) {
                              updateQuery({ [filter.name]: "", page: 1 });
                            } else {
                              updateQuery({
                                [filter.name]: dateFns.format(new Date(date), "yyyy-MM-dd"),
                                page: 1,
                              });
                            }
                          }}
                          value={query[filter.name] ?? ""}
                        />
                      </div>
                      <RadioGroup
                        className="d-flex flex-column ml-2"
                        name="isCollectionDateConfirmed"
                        items={filter.options!.map(el => ({ id: el.value, name: el.label }))}
                        onChange={onRadioChange}
                        value={query["isCollectionDateConfirmed"] ?? ""}
                      />
                    </div>
                  </DrawerSection>
                );
              }

              return (
                <DrawerSection title={filter.label} key={index}>
                  <div className="ml-2" key={index}>
                    <RadioGroup
                      className="d-flex flex-column mb-3"
                      name={filter.name}
                      items={filter.options.map(el => ({ id: el.value, name: el.label }))}
                      onChange={onRadioChange}
                      value={query[filter.name] ?? ""}
                    />
                  </div>
                </DrawerSection>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
