import { UnloadingOrder } from "api/wms/unloading/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import styles from "../../RightPanel.module.css";
import { cx, getAnyErrorKey, queryString } from "utilities";
import { useToastr, useToggle } from "hooks";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DeliveryConfirmModal } from "components/common";
import { confirmOrder } from "api/orders/calls";
import { Unloading } from "api/wms/models";
import { StatusHandler, StatusHandlerHelpers } from "components/utils";
import { useQueryClient } from "react-query";
import { unloadingKeys } from "api/wms/unloading/keys";
import { assertIsDefined } from "utilities/assertIsDefined";

interface Props {
  order: UnloadingOrder;
  unloading: Unloading;
}

interface ModalProps {
  close: () => void;
}

export const ConfirmOrder = ({ order, unloading }: Props) => {
  const confirmLocalizationModal = useToggle();
  const toastr = useToastr();
  const queryClient = useQueryClient();
  const search = queryString.stringify({
    unloading: unloading.id,
  });

  async function confirm(isConfirmed: boolean, helpers: StatusHandlerHelpers) {
    helpers.startFetching();
    const [payload, error] = await confirmOrder({ isConfirmed, orderId: order.id });
    helpers.stopFetching();
    if (payload) {
      if (!payload.isValid) {
        confirmLocalizationModal.open();
        return;
      }
      queryClient.setQueryData<UnloadingOrder[]>(unloadingKeys.ordersInfo(search), currentList => {
        assertIsDefined(currentList);
        return currentList.map(searchedOrder => {
          if (searchedOrder.id === order.id) {
            return {
              ...searchedOrder,
              isConfirmed: true,
            };
          }
          return searchedOrder;
        });
      });
      queryClient.invalidateQueries(unloadingKeys.ordersInfo(search));
    } else if (error) {
      toastr.open({ type: "warning", title: "Wymagane działanie", text: getAnyErrorKey(error) });
    }
  }

  return (
    <>
      <StatusHandler>
        {helpers => (
          <Button
            className={cx("text-uppercase", styles.orderConfirmBtn)}
            disabled={order.isConfirmed || order.isCanceled}
            isLoading={helpers.isFetching}
            onClick={() => confirm(true, helpers)}
            size="small"
            variant={order.isConfirmed ? "success" : "outline"}
          >
            {order.isConfirmed ? "Potwierdzone" : "Potwierdź"}
          </Button>
        )}
      </StatusHandler>
      {confirmLocalizationModal.isOpen && (
        <ConfirmLocalizationModal
          close={confirmLocalizationModal.close}
          order={order}
          unloading={unloading}
        />
      )}
    </>
  );
};

const ConfirmLocalizationModal = ({ close, order, unloading }: Props & ModalProps) => {
  const queryClient = useQueryClient();
  const search = queryString.stringify({
    unloading: unloading.id,
  });

  return (
    <Modal
      close={close}
      isOpen
      width={700}
      title={
        <Typography fontSize="20" fontWeight="700">
          Potwierdź lokalizację
        </Typography>
      }
    >
      <div className="p-3">
        <DeliveryConfirmModal
          delivery={order.address}
          submit={async ({ address, coords }) => {
            const [payload, error] = await confirmOrder({
              isConfirmed: true,
              orderId: order.id,
              point: coords,
              countryCode: address
                ? address.address_components
                    .filter(addressComponent => addressComponent.types.includes("country"))
                    .map(addressComponent => addressComponent.short_name)[0]
                : "",
            });
            if (payload) {
              queryClient.setQueryData<UnloadingOrder[]>(
                unloadingKeys.ordersInfo(search),
                currentList => {
                  assertIsDefined(currentList);
                  return currentList.map(searchedOrder => {
                    if (searchedOrder.id === order.id) {
                      return {
                        ...searchedOrder,
                        isConfirmed: true,
                      };
                    }
                    return searchedOrder;
                  });
                },
              );
              queryClient.invalidateQueries(unloadingKeys.ordersInfo(search));
              close();
            } else {
              alert(getAnyErrorKey(error));
            }
          }}
        />
      </div>
    </Modal>
  );
};
