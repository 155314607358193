import { queryFetch } from "apiConnectors/queryFetch";
import {
  CreateReturn,
  ReturnDetails,
  ReturnLineItem,
  ReturnLineItemDetails,
  ReturnListItem,
  ReturnWorkspace,
  StartOrFinishReturnPayload,
} from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { Pagination, UUID } from "api/types";
import { wmsReturnsKeys } from "./keys";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";
import { fileFactoryUtils } from "utilities/fileFactory";

const getReturns = (search: string = ""): ApiFetcher<Pagination<ReturnListItem>> => ({
  key: wmsReturnsKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/returns/web/items" + search,
    }),
});

const getReturn = (id: UUID): ApiFetcher<ReturnDetails> => ({
  key: wmsReturnsKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/returns/web/items/" + id,
    }),
});

const postReturn = (data: CreateReturn) =>
  queryFetch<void>({
    method: "POST",
    url: "/deliveries-and-returns/create-wms-return",
    data,
  });

const deleteReturn = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/wms/returns/web/items/" + id,
  });

const postStartReturn = (data: StartOrFinishReturnPayload) =>
  queryFetch<void>({
    method: "POST",
    url: `/wms/returns/web/start-wms-return`,
    data,
  });

const postFinishReturn = (data: StartOrFinishReturnPayload) =>
  queryFetch<void>({
    method: "POST",
    url: `/wms/returns/web/finish-wms-return`,
    data,
  });

const patchReturn = (data: Assign<Partial<ReturnDetails>, { id: UUID }>) => {
  const { ramp, ...otherFields } = data;
  return queryFetch<ReturnDetails>({
    method: "PATCH",
    url: "/wms/returns/web/items/" + data.id,
    data: Boolean(data.ramp)
      ? { ramp: data.ramp, ...parsePatchData(otherFields) }
      : parsePatchData(data),
  });
};

const getPackagesToReturn = (search: string = ""): ApiFetcher<Pagination<ReturnLineItem>> => ({
  key: wmsReturnsKeys.lineItems(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/returns/web/line-items" + search,
    }),
});

const getPackageToReturnDetails = (id: UUID): ApiFetcher<ReturnLineItemDetails> => ({
  key: wmsReturnsKeys.lineItem(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/returns/web/line-items/" + id,
    }),
});

const getReturnWorkspaceInventory = ({
  wmsReturnId,
  user,
}: {
  wmsReturnId: UUID;
  user: number | string;
}): ApiFetcher<ReturnWorkspace> => ({
  key: wmsReturnsKeys.workspace(String(user)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/returns/web/inventory/${wmsReturnId}/user/${user}`,
    }),
});

export const wmsReturnFileFactory = (() => {
  return {
    returnPdf: (returnDetails: Pick<ReturnListItem, "id" | "signature">) => ({
      url: `wms/returns/get-labels/${returnDetails.id}`,
      name: `${fileFactoryUtils.formatSignature(
        returnDetails.signature,
      )}-${fileFactoryUtils.getDate()}`,
    }),
    returnLineItemPdf: (
      returnLineItem: Pick<ReturnLineItem, "id" | "signature" | "wmsReturn">,
    ) => ({
      url: `wms/returns/get-labels/${returnLineItem.wmsReturn}?lineItemIds=${returnLineItem.id}`,
      name: `${fileFactoryUtils.formatSignature(
        returnLineItem.signature,
      )}-${fileFactoryUtils.getDate()}`,
    }),
  };
})();

export const wmsReturnsApi = {
  deleteReturn,
  getPackagesToReturn,
  getPackageToReturnDetails,
  getReturns,
  getReturn,
  getReturnWorkspaceInventory,
  patchReturn,
  postReturn,
  postFinishReturn,
  postStartReturn,
};
