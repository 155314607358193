import { ManufacturingUnitListViewItem } from "api/manufacturing/units/models";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { AssignEmployee } from "./assignEmployee/AssignEmployee";
import { useToggle } from "hooks";
import { MdiFabric } from "components/miloDesignSystem/atoms/icons/MdiFabric";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { OrderMaterial } from "./orderMaterial/OrderMaterial";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { ChangeStatus } from "./ChangeStatus";

interface Props {
  close: () => void;
  numberOfSelectedItems: number;
  units: ManufacturingUnitListViewItem[];
  hideStatusChange?: boolean;
}

export const StagesTableToolbar = ({
  close,
  numberOfSelectedItems,
  units,
  hideStatusChange,
}: Props) => {
  const orderMaterialModal = useToggle();

  return (
    <>
      <TableToolbar close={close} numberOfSelectedItems={numberOfSelectedItems}>
        <AssignEmployee units={units} />
        <div>
          <Tooltip title="Zamów materiały">
            <IconButton
              icon={MdiFabric}
              onClick={orderMaterialModal.open}
              theme="dark"
              variant="transparent"
            />
          </Tooltip>
        </div>
        <div>
          <FileDownloadHandler
            factoryFn={() =>
              manufacturingFileFactory.manufacturingItemPdf(
                units.map(unit => unit.manufacturingItem.id),
                "Zlecenia-produkcyjne",
              )
            }
            type="pdf"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz etykiety zleceń">
                <IconButton
                  icon={MdiQrCode}
                  isLoading={isLoading}
                  onClick={event => {
                    event.stopPropagation();
                    download();
                  }}
                  theme="dark"
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
        </div>
        {!hideStatusChange && <ChangeStatus selectedRows={units} />}
      </TableToolbar>
      {orderMaterialModal.isOpen && (
        <OrderMaterial close={orderMaterialModal.close} units={units} />
      )}
    </>
  );
};
