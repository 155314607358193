import { UnloadingDetails, UnloadingStatus } from "api/wms/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useToggle } from "hooks";
import { AddOrdersBySignaturesModal } from "./addOrdersBySignaturesModal/AddOrdersBySignaturesModal";
import { useDownloadUnloadingPdf } from "../../hooks/useDownloadUnloadingPdf";
import { wmsUnloadingActions } from "api/wms/unloading/actions";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { AddAwaitingTransportsToUnloading } from "./addAwaitingTransportsToUnloading/AddAwaitingTransportsToUnloading";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { EditUnloadingsOrders } from "./editUnloadingsOrders/EditUnloadingsOrders";

interface Props {
  close: () => void;
  unloading: UnloadingDetails;
}

export const PanelHeader = ({ close, unloading }: Props) => {
  const startUnloadingMutation = wmsUnloadingActions.usePostStartUnloading();
  const finishUnloadingMutation = wmsUnloadingActions.usePostFinishUnloading();
  const deleteMutation = wmsUnloadingActions.useRemoveUnloading(close, unloading);
  const addOrdersBySignaturesModal = useToggle();
  const handleDownloadUnloadingPdf = useDownloadUnloadingPdf();
  const { isTriggerElementHidden } = useScrollableContext();
  const addAwaitingTransportsToUnloadingModal = useToggle();
  const editUnloadingOrdersModal = useToggle();

  return (
    <>
      <RightPanelHeader>
        <div className="d-flex align-items-center gap-2">
          {!unloading.startedAt && !unloading.finishedAt && (
            <Button
              className="text-uppercase"
              disabled={startUnloadingMutation.isLoading}
              onClick={() => startUnloadingMutation.mutate({ unloadingId: unloading.id })}
              size="small"
              startIcon={MdiCheck}
              theme="light"
              variant="outline"
            >
              Rozpocznij rozładunek
            </Button>
          )}
          {unloading.startedAt && !unloading.finishedAt && (
            <Button
              className="text-uppercase"
              disabled={finishUnloadingMutation.isLoading}
              onClick={() => finishUnloadingMutation.mutate({ unloadingId: unloading.id })}
              size="small"
              startIcon={MdiCheck}
              theme="light"
              variant="outline"
            >
              Zakończ rozładunek
            </Button>
          )}
          {isTriggerElementHidden && (
            <SlidingHeaderWrapper className="noWrap">{unloading.signature}</SlidingHeaderWrapper>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end flex-1">
          <Button
            className="text-uppercase mr-1"
            onClick={editUnloadingOrdersModal.open}
            size="small"
            startIcon={MdiEdit}
            variant="gray"
          >
            Edytuj zamówienia
          </Button>
          <Tooltip title="Pobierz etykiety">
            <IconButton
              icon={<MdiQrCode size="16" />}
              onClick={() => handleDownloadUnloadingPdf(unloading)}
              variant="transparent"
            />
          </Tooltip>
          <Menu
            disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
            menuItems={[
              {
                type: MenuItemType.TEXT,
                options: {
                  iconStart: MdiAdd,
                },
                onClick: addOrdersBySignaturesModal.open,
                text: "Dodaj zamówienia do rozładunku",
              },
              {
                type: MenuItemType.TEXT,
                options: {
                  iconStart: MdiAdd,
                },
                onClick: addAwaitingTransportsToUnloadingModal.open,
                text: "Dodaj awizacje do rozładunku",
              },
              {
                type: MenuItemType.TEXT,
                options: {
                  disabled: unloading.status !== UnloadingStatus.NOT_STARTED,
                  color: "red100",
                  iconStart: MdiDelete,
                },
                onClick: () => deleteMutation.mutate(unloading.id),
                text: "Usuń",
              },
            ]}
          />
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </RightPanelHeader>
      {addOrdersBySignaturesModal.isOpen && (
        <AddOrdersBySignaturesModal
          close={addOrdersBySignaturesModal.close}
          unloading={unloading}
        />
      )}
      {addAwaitingTransportsToUnloadingModal.isOpen && (
        <AddAwaitingTransportsToUnloading
          close={addAwaitingTransportsToUnloadingModal.close}
          unloading={unloading}
        />
      )}
      {editUnloadingOrdersModal.isOpen && (
        <EditUnloadingsOrders close={editUnloadingOrdersModal.close} unloading={unloading} />
      )}
    </>
  );
};
