import { Typography } from "components/miloDesignSystem/atoms/typography";
import { NavLink } from "react-router-dom";
import styles from "./NavigationSubLink.module.css";
import { PropsWithChildren } from "react";

export const NavigationSubLink = ({ to, children }: PropsWithChildren<{ to: string }>) => {
  return (
    <NavLink tabIndex={-1} className={styles.link} to={to} activeClassName={styles.linkActive}>
      <div className={styles.border} />
      <Typography fontSize="12" fontWeight="700" className={styles.sectionName}>
        {children}
      </Typography>
    </NavLink>
  );
};
