import calculateIcon from "assets/images/calculatePink.svg";
import financeIcon from "assets/images/Invoicing.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { bankAccounts } from "./routes/bankAccounts";
import { dashboard } from "./routes/dashboard";
import { salesInvoices } from "./routes/salesInvoices";
import { purchaseInvoices } from "./routes/purchaseInvoices";
import { correctionInvoices } from "./routes/correctionInvoices";
import { receipts } from "./routes/receipts";
import favicon from "assets/images/favicons/fv_finances.svg";
import { warehouseFinancialState } from "./routes/warehouseFinancialState";
import { myInvoicesToConfirm } from "./routes/myInvoicesToConfirm";
import { soldPackages } from "./routes/soldPackages";
import { soldIndexes } from "./routes/soldIndexes";
import { proformas } from "./routes/proformas";
import { advances } from "./routes/advances";
import { NavigationTitle } from "../../components/NavigationTitle";
import { NavigationDivider } from "../../components/navigationDivider/NavigationDivider";
import { financesBalance } from "./routes/financesBalance";
import { payments } from "./routes/payments";

export const finances: ModuleNavigation = {
  favicon,
  color: "#b65eff",
  navigationConfig: {
    label: "Finanse",
    icon: { src: calculateIcon, background: "#431d87" },
  },
  menuDropdownIcon: financeIcon,
  name: "FINANCES",
  url: "/finances",
  dashboard,
  navigationSections: [
    <NavigationTitle>Dokumenty handlowe</NavigationTitle>,
    salesInvoices,
    correctionInvoices,
    receipts,
    purchaseInvoices,
    advances,
    proformas,
    myInvoicesToConfirm,
    bankAccounts,
    <NavigationDivider />,
    <NavigationTitle>Dokumenty finansowe</NavigationTitle>,
    warehouseFinancialState,
    payments,
    <NavigationDivider />,
    <NavigationTitle>Zestawienia</NavigationTitle>,
    soldPackages,
    soldIndexes,
    financesBalance,
  ],
};
