import menuToggleIcon from "assets/images/16.svg";
import logoIcon from "assets/images/logo.svg";
import cx from "classnames";
import { ClickOutsideHandler } from "components/utils";
import cuid from "cuid";
import { usePrevious, useSelector, useThresholdToggle, useToggle } from "hooks";
import { memo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CreateButtonsAside } from "./createButtonsAside";
import { Footer } from "./footer/Footer";
import { FullNavigation } from "./fullNavigation/FullNavigation";
import { MoreLinksNavigation } from "./moreLinksNavigation/MoreLinksNavigation";
import styles from "./Navbar.module.css";
import { CollapseNavigation } from "./collapseNavigation";
import { FLAVOR } from "CONSTANTS";
import { useUnpaidInvoiceToastr } from "../unpaidInvoiceToastr/useUnpaidInvoiceToastr";

export const clickOutsideShortcutsIgnoreClass = "ignore-outside-shortcuts-click-" + cuid();
export const clickOutsideIgnoreClass = "ignore-outside-click-" + cuid();

export const Navbar = memo(() => {
  const { shouldBeOpen, isOpen, toggleOpen } = useThresholdToggle();
  const shortcuts = useToggle();
  const createNavbarControls = useToggle();
  const location = useLocation();
  const previousLocation = usePrevious(location);
  useUnpaidInvoiceToastr();
  const showUnpaidInvoiceToastr = useSelector(store => store.ui.showUnpaidInvoiceToastr);

  useEffect(() => {
    if (shortcuts.isOpen) {
      createNavbarControls.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcuts.isOpen]);

  useEffect(() => {
    if (createNavbarControls.isOpen) {
      shortcuts.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNavbarControls.isOpen]);

  useEffect(() => {
    if (previousLocation !== location) {
      createNavbarControls.close();
      shortcuts.close();
    }
  }, [location, previousLocation, createNavbarControls, shortcuts]);

  return (
    <>
      <nav
        className={cx(styles.navLeft, { [styles.closed]: !shouldBeOpen })}
        role="navigation"
        data-testid="navigation"
      >
        <div
          className={cx("d-flex align-items-center p-3", styles.navLeftHeader, {
            [styles.defaulHeaderPositioning]: !showUnpaidInvoiceToastr,
          })}
        >
          {isOpen && (
            <Link className={styles.logo} to="/">
              <img src={logoIcon} alt="Milo" width="78px" />
            </Link>
          )}
          {shouldBeOpen && !isOpen && <div />}
          <button className={styles.menuToggleBtn} onClick={toggleOpen}>
            <img src={menuToggleIcon} alt="Otwiera i zamyka menu" />
          </button>
        </div>

        <FullNavigation isOpen={isOpen} />

        <CollapseNavigation isOpen={!shouldBeOpen} />

        <Footer
          createNavbarControlsToggle={createNavbarControls.toggle}
          shortcutsToggle={shortcuts.toggle}
        />
      </nav>

      {FLAVOR !== "externalManufacturing" && shortcuts.isOpen && (
        <ClickOutsideHandler
          onClickOutside={shortcuts.close}
          outsideClickIgnoreClass={clickOutsideShortcutsIgnoreClass}
        >
          <MoreLinksNavigation shortCutsOpen={shortcuts.isOpen} shouldBeOpen={shouldBeOpen} />
        </ClickOutsideHandler>
      )}

      {createNavbarControls.isOpen && (
        <ClickOutsideHandler
          onClickOutside={createNavbarControls.close}
          outsideClickIgnoreClass={clickOutsideIgnoreClass}
        >
          <CreateButtonsAside controls={createNavbarControls} mainMenuOpened={shouldBeOpen} />
        </ClickOutsideHandler>
      )}
    </>
  );
});
