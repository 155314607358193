import { filterFactory } from "components/utils/withFilters";
import { tradingDocumentFilterFactory } from "../shared/filtersFactory/filtersFactory";

export const filterList = filterFactory(({ FilterType }) => {
  const factory = tradingDocumentFilterFactory(FilterType);
  return [
    factory.invoiceDeliveryDateRange,
    factory.invoiceIssueDateRange,
    factory.countryCode,
    factory.salesAccount,
    factory.status,
    {
      type: FilterType.Select,
      label: "status połączenia z PZ",
      name: "isGRNConfirmed",
      options: [
        { label: "Połączono", value: "true" },
        { label: "Nie połączono", value: "false" },
      ],
    },
    factory.isPaid,
    factory.currency,
    factory.paymentType,
    factory.isAssigned,
    {
      type: FilterType.Search,
      label: "kontrahent",
      name: "sellers",
      multiple: true,
      searchBy: "businessEntities",
      overrides: {
        businessEntitiesKind: "EXTERNAL",
      },
    },
    factory.transferDate,
    factory.isPaymentOverdue,
    factory.transferNumber,
  ];
});
