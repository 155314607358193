import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { carrierOrdersApi } from "./api";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { carrierOrdersKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { createApiQuery } from "hooks/createApiQuery";
import { patchRoute_query } from "api/routes/calls";
import { PartialOf } from "typeUtilities";
import { Route } from "api/routes/models";
import { parsePatchData } from "utilities/parsePatchData";
import { CarrierOrder } from "./models";
import { RouteEditedHandler } from "pages/routes/shared/RouteEditedHandler";

const useCarrierOrders = createPaginatedApiQuery(carrierOrdersApi.getCarrierOrders);
const useCarrierOrder = createApiQuery(carrierOrdersApi.getCarrierOrder);
const useCarrierOrdersEmailMessages = createPaginatedApiQuery(
  carrierOrdersApi.getCarrierOrdersEmailMessages,
);

const useSendCarrierOrdersEmailMessage = () => {
  return useMutation(carrierOrdersApi.postCarrierOrdersEmailMessage, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Pomyślnie wysłano dokument przewozowy",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePatchCarrierOrder = () => {
  return useMutation(carrierOrdersApi.patchCarrierOrder, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevPanel = queryUtils.handleMutate(
        carrierOrdersKeys.carrierOrders.details(String(id)),
        toUpdate,
      );
      const prevList = queryUtils.handlePaginatedListUpdate(
        carrierOrdersKeys.carrierOrders.list(),
        id,
        toUpdate,
      );
      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(
        carrierOrdersKeys.carrierOrders.details(String(id)),
        onMutateReturn.prevPanel,
        error,
      );
      queryUtils.rollbackList(carrierOrdersKeys.carrierOrders.list(), onMutateReturn.prevList, id);
    },
  }));
};

const usePatchCarrierOrderRouteCost = (carrierOrder: CarrierOrder) => {
  return useMutation(
    ({
      id,
      toUpdate,
    }: {
      id: number;
      toUpdate: PartialOf<Pick<Route, "customRouteCostCurrency" | "customRouteCost">>;
    }) => patchRoute_query(parsePatchData(toUpdate), id),
    ({ queryClient, queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        RouteEditedHandler(id);
        return queryUtils.handleMutate<CarrierOrder>(
          carrierOrdersKeys.carrierOrders.details(String(carrierOrder.id)),
          draft => {
            if (toUpdate.customRouteCost)
              draft.routeCost.customRouteCost.amount = Number(toUpdate.customRouteCost);
            if (toUpdate.customRouteCostCurrency)
              draft.routeCost.customRouteCost.currency = toUpdate.customRouteCostCurrency;
          },
        );
      },
      onSuccess: () => queryClient.invalidateQueries(),
      onError: (error, _, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(
          carrierOrdersKeys.carrierOrders.details(carrierOrder.id),
          onMutateReturn,
          error,
        );
      },
    }),
  );
};

export const carrierOrdersActions = {
  useCarrierOrder,
  useCarrierOrders,
  useCarrierOrdersEmailMessages,
  usePatchCarrierOrder,
  usePatchCarrierOrderRouteCost,
  useSendCarrierOrdersEmailMessage,
};
