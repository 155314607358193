import { ColorPalette } from "../colorsPalette";
import { BaseIcon } from "../icons/types";
import { TypographyProps } from "../typography/types";

export enum MenuItemType {
  AVATAR = "AVATAR",
  DIVIDER = "DIVIDER",
  SECTION = "SECTION",
  TEXT = "TEXT",
  ICON = "ICON",
}

export interface BaseMenuItem {
  options?: {
    disabled?: boolean;
    divider?: React.ReactNode;
    color?: ColorPalette;
    className?: string;
    iconStart?: React.FunctionComponent<BaseIcon>;
    buttonEnd?: React.ReactNode;
    typographyProps?: Partial<TypographyProps>;
  };
  onClick: () => void;
  text: string;
  helperText?: string;
}

interface AvatarMenuItem extends BaseMenuItem {
  avatar: string;
  type: MenuItemType.AVATAR;
}

interface DividerMenuItem extends Omit<BaseMenuItem, "onClick" | "text"> {
  type: MenuItemType.DIVIDER;
  text?: never;
  onClick?: never;
}

export interface IconMenuItem extends BaseMenuItem {
  icon: React.FunctionComponent<BaseIcon> | string | JSX.Element;
  type: MenuItemType.ICON;
}
interface TextMenuItem extends BaseMenuItem {
  type: MenuItemType.TEXT;
}

interface SectionMenuItem extends BaseMenuItem {
  type: MenuItemType.SECTION;
  title?: string;
  menuItems: MenuItem[];
}

export type MenuItem =
  | AvatarMenuItem
  | DividerMenuItem
  | TextMenuItem
  | SectionMenuItem
  | IconMenuItem;
