import { PostModel } from "typeUtilities";
import apiFetch, { tokenRefreshMiddleware } from "../../apiConnectors/fetchConnector";
import {
  AddManufacturerToDocument,
  AddManufacturerToDocumentPreview,
  Cell,
  CellFillingStatus,
  CollectionPackage,
  Column,
  Direction,
  Entry,
  EntryListItem,
  EntryPackage,
  Hall,
  Index,
  IndexDetail,
  Level,
  Package,
  PackageAssignPayload,
  PackageDemandsForItem,
  PackageFromSource,
  PackageIndex,
  PackageListItem,
  PackageLocations,
  PostPackage,
  Row,
  SourceType,
  Type,
  Warehouse,
  WarehouseDocumentStatus,
  WarehouseEntry,
  WhEntryOrder,
  WhEntryOrderPackage,
  WhEntryReturnedPackage,
} from "./models";
import { Pagination } from "../types";
import { formatSourceTypeToUrl } from "utilities/formatWarehouseSourceTypeToUrl";
import { ApiFetcher } from "hooks/createApiQuery";
import { queryFetch } from "apiConnectors/queryFetch";
import { warehouseKeys } from "./keys";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";
import { fileFactoryUtils } from "utilities/fileFactory";

const api = tokenRefreshMiddleware(apiFetch);

export const getIndexes = (search: string = "", abortToken?: string) =>
  api<Pagination<Index>>({
    method: "GET",
    url: "/indexes/in-orders" + search,
    abortToken,
  });
export const getIndex = (id: IndexDetail["id"]) =>
  api<IndexDetail>({
    method: "GET",
    url: "/indexes/in-orders/" + id,
  });

export const patchIndex = (id: Index["id"], data: Partial<Index>) =>
  api<Index>({
    method: "PATCH",
    url: "/indexes/in-orders/" + id,
    data,
  });

export const patchIndexDetail = (id: IndexDetail["id"], data: Partial<IndexDetail>) =>
  api<Index>({
    method: "PATCH",
    url: "/indexes/in-orders/" + id,
    data,
  });

export const postPackageAssign = (data: PackageAssignPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/packages/assign",
    data,
  });

export const deletePackageAssign = (data: PackageAssignPayload) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/packages/assign",
    data,
  });

export const getPackages = (search: string = "", abortToken?: string) =>
  api<Pagination<PackageListItem>>({
    method: "GET",
    url: "/packages/items" + search,
    abortToken,
  });

export const getPackagesQuery = (search: string = ""): ApiFetcher<Pagination<PackageListItem>> => ({
  key: warehouseKeys.packages.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/packages/items" + search,
    }),
});

export const getPackage = (internalId: string) =>
  api<Package>({
    method: "GET",
    url: "/packages/items-by-internal-id/" + internalId,
  });

export const nullPackage = (packageId: number) =>
  api<Package>({
    method: "POST",
    url: "/wh-entries/packages/reset",
    data: { packageId: packageId },
  });

export const acceptPackage = (data: { package: number; quantity: number }) =>
  api<WarehouseEntry>({
    method: "POST",
    url: "/wh-entry-items/out-of-scan/reception",
    data,
  });

export const patchPackage = (id: Package["id"], data: Partial<Package>) =>
  api<Package>({
    method: "PATCH",
    url: "/packages/items/" + id,
    data,
  });

export const putPackage = (data: PostModel<PostPackage>, internalId: string) =>
  api<Package>({
    method: "PUT",
    url: "/packages/items-by-internal-id/" + internalId,
    data,
  });

export const patchPackageQuery = (id: number, data: Partial<Package>) =>
  queryFetch<Package>({
    method: "PATCH",
    url: "/packages/items/" + id,
    data,
  });

export const postPackage = (data: PostModel<PostPackage>) =>
  api<Package>({
    method: "POST",
    url: "/packages/items",
    data,
  });

export const getPackageLocations = (internalId: string) =>
  api<PackageLocations>({
    method: "GET",
    url: "/packages/items-by-internal-id/" + internalId + "/locations",
  });

export const getPackageIndexes = (internalId: string) =>
  api<PackageIndex[]>({
    method: "GET",
    url: "/packages/items-by-internal-id/" + internalId + "/indexes",
  });

export const getEntries = (search: string = "", abortToken?: string) =>
  api<Pagination<EntryListItem>>({
    method: "GET",
    url: "/wh-entries/items" + search,
    abortToken,
  });

export const getEntry = (id: string) =>
  api<Entry>({
    method: "GET",
    url: "/wh-entries/items/" + id,
  });

export const confirmEntry = (id: number) =>
  api<{ message: string; status: WarehouseDocumentStatus }>({
    method: "POST",
    url: `/wh-entries/items/${id}/confirm`,
  });

export const openEntry = (id: number) =>
  api<{ message: string; status: WarehouseDocumentStatus }>({
    method: "POST",
    url: `/wh-entries/items/${id}/open`,
  });

export const deleteEntry = (id: number) =>
  api<void>({
    method: "DELETE",
    url: "/wh-entries/items/" + id,
  });

export const postEntry = (
  id: number | string | null,
  instance: SourceType,
  type: Type,
  direction: Direction,
) => {
  const url = (() => {
    if (!id) {
      return `/wh-entries/items`;
    }
    return `/wh-entries/items/${instance}/${id}`;
  })();

  return api<EntryListItem>({
    method: "POST",
    url,
    data: {
      type,
      direction,
    },
  });
};
export const patchEntry = ({ data, id }: { data: Partial<Entry>; id: number }) =>
  queryFetch<Entry>({
    method: "PATCH",
    url: `/wh-entries/items/${id}`,
    data: data,
  });

export const getEntryPackages = (id: number | string) =>
  api<EntryPackage[]>({
    method: "GET",
    url: "/wh-entries/packages-from-source/" + id,
  });

export const postEntryCollection = (data: {
  collections: { sourceType: SourceType; sourceId: number | string }[];
}) =>
  api<CollectionPackage[]>({
    method: "POST",
    url: `/wh-entries/collect`,
    data,
  });

export const getPackagesFromSource = (args: {
  id: number | string;
  sourceType: SourceType;
}): ApiFetcher<PackageFromSource[]> => ({
  key: warehouseKeys.packagesFromSource(String(args.id), args.sourceType),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-entries/${formatSourceTypeToUrl(args.sourceType)}/packages-from-source/${args.id}`,
    }),
});

export const getLevel = ({ id }: { id: number | string }): ApiFetcher<Level> => ({
  key: warehouseKeys.level.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-levels/items/${id}`,
    }),
});

export const patchLevel = (data: Assign<Partial<Level>, { id: number }>) =>
  queryFetch<Level>({
    method: "PATCH",
    url: `/wh-levels/items/${data.id}`,
    data: parsePatchData(data),
  });

export const deleteLevel = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: `/wh-levels/items/${id}`,
  });

export const getLevels = (search: string): ApiFetcher<Pagination<Level>> => ({
  key: warehouseKeys.level.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-levels/items${search}`,
    }),
});

export const postLevel = (data: Omit<Level, "id">) =>
  queryFetch<Level>({
    method: "POST",
    url: `/wh-levels/items`,
    data: parsePatchData(data),
  });

export const deleteManufacturerFromDocument = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/wh-entries/manufacturers/" + id,
  });

export const postManufacturerToDocument = (data: AddManufacturerToDocument) =>
  queryFetch<AddManufacturerToDocumentPreview>({
    method: "POST",
    url: "wh-entries/manufacturers",
    data: parsePatchData(data),
  });

export const getHall = ({ id }: { id: number | string }): ApiFetcher<Hall> => ({
  key: warehouseKeys.hall.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-halls/items/${id}`,
    }),
});

export const patchHall = (data: Assign<Partial<Hall>, { id: number }>) =>
  queryFetch<Hall>({
    method: "PATCH",
    url: `/wh-halls/items/${data.id}`,
    data: parsePatchData(data),
  });

export const deleteHall = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: `/wh-halls/items/${id}`,
  });

export const getHalls = (search: string): ApiFetcher<Pagination<Hall>> => ({
  key: warehouseKeys.hall.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-halls/items${search}`,
    }),
});

export const postHall = (data: Omit<Assign<Hall, { warehouse: Warehouse | null }>, "id">) =>
  queryFetch<Hall>({
    method: "POST",
    url: `/wh-halls/items`,
    data: parsePatchData(data),
  });

export const getWarehouse = ({ id }: { id: number | string }): ApiFetcher<Warehouse> => ({
  key: warehouseKeys.warehouse.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/warehouses/items/${id}`,
    }),
});

export const patchWarehouse = (data: Assign<Partial<Omit<Warehouse, "id">>, { id: number }>) =>
  queryFetch<Warehouse>({
    method: "PATCH",
    url: `/warehouses/items/${data.id}`,
    data,
  });

export const deleteWarehouse = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: `/warehouses/items/${id}`,
  });

export const getWarehouses = (search: string): ApiFetcher<Pagination<Warehouse>> => ({
  key: warehouseKeys.warehouse.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/warehouses/items${search}`,
    }),
});

export const postWarehouse = (data: Omit<Warehouse, "id" | "code" | "icon">) =>
  queryFetch<Warehouse>({
    method: "POST",
    url: `/warehouses/items`,
    data,
  });

export const getWhEntriesOrders = (args: {
  id: number | string;
  sourceType: SourceType;
}): ApiFetcher<WhEntryOrder[]> => ({
  key: warehouseKeys.whEntriesOrders(String(args.id), args.sourceType),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-entries/${formatSourceTypeToUrl(args.sourceType)}/orders/${args.id}`,
    }),
});

export const getWhEntriesPackages = (args: {
  routeId: number | string;
  orderId: number | string;
  sourceType: SourceType;
}): ApiFetcher<WhEntryOrderPackage[]> => ({
  key: warehouseKeys.whEntriesOrderPackages(
    String(args.routeId),
    String(args.orderId),
    args.sourceType,
  ),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-entries/${formatSourceTypeToUrl(args.sourceType)}/orders/${args.routeId}/${
        args.orderId
      }`,
    }),
});

export const getWhEntryReturnedPackages = (args: {
  id: number | string;
  sourceType: SourceType;
}): ApiFetcher<WhEntryReturnedPackage[]> => ({
  key: warehouseKeys.whEntriesReturnedPackages(String(args.id), args.sourceType),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-entries/${formatSourceTypeToUrl(args.sourceType)}/returned-packages/${args.id}`,
    }),
});

export const getPackageDemandsForItem = (itemId: number): ApiFetcher<PackageDemandsForItem> => ({
  key: warehouseKeys.packageDemandsForItem(itemId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-product-demand/package-demands-for-item/${itemId}`,
    }),
});

export const resetPackageDemandsForItem = (itemId: number) =>
  queryFetch<PackageDemandsForItem>({
    method: "POST",
    url: `/wh-product-demand/package-demands-for-item/${itemId}/reset`,
  });

export const getRow = ({ id }: { id: number | string }): ApiFetcher<Row> => ({
  key: warehouseKeys.row.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-rows/items/${id}`,
    }),
});

export const patchRow = (data: Assign<Partial<Row>, { id: number }>) =>
  queryFetch<Row>({
    method: "PATCH",
    url: `/wh-rows/items/${data.id}`,
    data: parsePatchData(data),
  });

export const deleteRow = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: `/wh-rows/items/${id}`,
  });

export const getRows = (search: string): ApiFetcher<Pagination<Row>> => ({
  key: warehouseKeys.row.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-rows/items${search}`,
    }),
});

export const postRow = (data: Omit<Row, "id">) =>
  queryFetch<Row>({
    method: "POST",
    url: `/wh-rows/items`,
    data: parsePatchData(data),
  });

export const getColumn = ({ id }: { id: number | string }): ApiFetcher<Column> => ({
  key: warehouseKeys.column.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-columns/items/${id}`,
    }),
});

export const patchColumn = (data: Assign<Partial<Column>, { id: number }>) =>
  queryFetch<Column>({
    method: "PATCH",
    url: `/wh-columns/items/${data.id}`,
    data: parsePatchData(data),
  });

export const deleteColumn = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: `/wh-columns/items/${id}`,
  });

export const getColumns = (search: string): ApiFetcher<Pagination<Column>> => ({
  key: warehouseKeys.column.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-columns/items${search}`,
    }),
});

export const postColumn = (data: Omit<Column, "id">) =>
  queryFetch<Column>({
    method: "POST",
    url: `/wh-columns/items`,
    data: parsePatchData(data),
  });

export const getCellsFillingStatus = (): ApiFetcher<CellFillingStatus> => ({
  key: warehouseKeys.cellsFillingStatus(),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-cells/filling-status`,
    }),
});

export const getCell = ({ id }: { id: number | string }): ApiFetcher<Cell> => ({
  key: warehouseKeys.cells.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-cells/items/${id}`,
    }),
});

export const patchPackagesUpdateCsv = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "PATCH",
    url: "/packages/update-csv",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.csv"',
    },
    onUploadProgress,
  });

export const patchCell = (data: Assign<Partial<Cell>, { id: number }>) =>
  queryFetch<Cell>({
    method: "PATCH",
    url: `/wh-cells/items/${data.id}`,
    data: parsePatchData(data),
  });

export const deleteCell = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: `/wh-cells/items/${id}`,
  });

export const getCells = (search: string): ApiFetcher<Pagination<Cell>> => ({
  key: warehouseKeys.cells.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-cells/items${search}`,
    }),
});

export const postCell = (data: Omit<Cell, "id">) =>
  queryFetch<Cell>({
    method: "POST",
    url: `/wh-cells/items`,
    data: parsePatchData(data),
  });

export const warehouseFileFactory = (() => {
  return {
    columnLabel: (column: Pick<Column, "id" | "name">) => ({
      url: `/wh-columns/download-cells-labels?whColumns=${column.id}`,
      name: `${column.name}-${fileFactoryUtils.getDate()}`,
    }),
    packagesCsv: () => ({
      url: "/warehouses/current-state/packages-csv",
      name: `paczki_${fileFactoryUtils.getDate()}`,
    }),
    indexesCsv: () => ({
      url: "/warehouses/current-state/indexes-csv",
      name: `indeksy-${fileFactoryUtils.getDate()}`,
    }),
    orderCsv: () => ({
      url: "/packages/update-csv",
      name: `produkty-${fileFactoryUtils.getDate()}`,
    }),
  };
})();
