import { InfoLabel } from "components/common/infoLabel";
import { closestDayOfWeek, cx, dateFns, dateUtils } from "utilities";
import { useRoutePatchMutation } from "api/call-center/routes/hooks";
import {
  CallCenterRouteOrderListItem,
  expectedPaymentFormDict,
} from "api/call-center/routes/models";
import { AvatarWithName } from "components/common/avatarWithName";
import { AssignedUser } from "components/common/assignUser/AssignUser";
import { StateLabel } from "components/common/stateLabel";
import { OrderedProducts } from "./orderedProducts/OrderedProducts";
import styles from "./GeneralInfoSection.module.css";
import { MdiCopyright } from "components/miloDesignSystem/atoms/icons/MdiCopyright";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ExpectedDelivery } from "../routeOrdersList/components/ExpectedDelivery";
import { OrderDriverDetails } from "./OrderDriverDetails";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";

interface Props {
  routeOrder: CallCenterRouteOrderListItem;
}

export const GeneralInfoSection = ({ routeOrder }: Props) => {
  const usePatchRouteOrderMutation = useRoutePatchMutation();
  const callBeforeDeliveryMutation = useRoutePatchMutation();
  const timeOfCallBeforeDelivery = useRoutePatchMutation();

  return (
    <>
      <InfoLabel title="przypisano do">
        <AssignedUser
          value={routeOrder.responsible}
          onChange={value =>
            usePatchRouteOrderMutation.mutate({
              id: routeOrder.id,
              toUpdate: { responsible: value },
            })
          }
        />
      </InfoLabel>
      <InfoLabel title="dropshipping">
        {routeOrder.customer ? (
          routeOrder.customer.name ? (
            <StateLabel kind="deepPurple">{routeOrder.customer.name}</StateLabel>
          ) : null
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>

      <InfoLabel title="forma płatności">
        {routeOrder.payment.source === "CASH" ||
        routeOrder.payment.source === "ONLINE" ||
        routeOrder.payment.source === "ON_DELIVERY" ? (
          <Typography fontSize="12" fontWeight="600">
            {expectedPaymentFormDict[routeOrder.payment.source]}
          </Typography>
        ) : routeOrder.payment.source.length > 0 ? (
          routeOrder.payment.source
        ) : (
          <EmptyValue fontSize="12" fontWeight="600" />
        )}
      </InfoLabel>

      <InfoLabel title="kontrahent">
        <div className="d-flex align-items-center gap-2">
          {routeOrder.customer ? (
            <div
              className={cx(styles.filterChip, styles.filterCustomer)}
              style={{
                background: routeOrder.customer.color,
                color: routeOrder.customer.textColor,
              }}
            >
              <MdiCopyright customColor={routeOrder.customer.textColor} size="16" />

              <div>
                {routeOrder.customer.name.length > 0 ? (
                  <Typography fontSize="12" fontWeight="600">
                    {routeOrder.customer.name}
                  </Typography>
                ) : (
                  <EmptyValue fontSize="12" fontWeight="600" />
                )}
              </div>
            </div>
          ) : (
            <EmptyValue fontSize="12" fontWeight="600" />
          )}
        </div>
      </InfoLabel>

      <InfoLabel title="kierowca">
        <OrderDriverDetails driver={routeOrder.driver} />
      </InfoLabel>

      <InfoLabel title="sugerowany termin odbioru">
        {routeOrder.collectionDate ? (
          <div className={cx("d-flex align-items-center gap-2", styles.suggestedDelivery)}>
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="14" fontWeight="700">
                {dateUtils.formatDateToDisplay(routeOrder.collectionDate)}
              </Typography>
              <Typography fontSize="14" fontWeight="600">
                {closestDayOfWeek(routeOrder.collectionDate)},
              </Typography>
            </div>
            <ExpectedDelivery order={routeOrder} hasDeliveryRemoval />
          </div>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>

      <InfoLabel title="adres">
        {routeOrder.delivery ? (
          <Address routeOrder={routeOrder} />
        ) : (
          <EmptyValue fontSize="12" fontWeight="600" />
        )}
      </InfoLabel>

      <OrderedProducts routeOrder={routeOrder} />
      <InfoLabel title="powód niepotwierdzenia / przełożenia terminu">
        <Typography fontSize="14" fontWeight="500">
          {routeOrder.deliveryDateRejectedByFirstName ? (
            <Typography fontSize="14" fontWeight="500">
              {routeOrder.deliveryDateRejectedByFirstName}
            </Typography>
          ) : (
            <EmptyValue fontSize="14" fontWeight="500" />
          )}
        </Typography>
      </InfoLabel>

      <InfoLabel title="potwierdzone">
        <Typography fontSize="14" fontWeight="500">
          {routeOrder.isDeliveryDateConfirm ? "potwierdzono" : "niepotwierdzone"}
        </Typography>
      </InfoLabel>

      <InfoLabel title="potwierdzone przez">
        {routeOrder.deliveryDateConfirmedBy ? (
          <AvatarWithName user={routeOrder.deliveryDateConfirmedBy} />
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>

      <InfoLabel title="źródło potwierdzenia">
        {routeOrder.deliveryDateConfirmationSource ? (
          <Typography fontSize="14" fontWeight="500">
            {routeOrder.deliveryDateConfirmationSource === "EXTERNAL" ? "SMS" : "wewnętrzne"}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel title="kiedy potwierdzono">
        {routeOrder.deliveryDateConfirmTimestamp ? (
          <Typography fontSize="14" fontWeight="500">
            {dateFns.format(new Date(routeOrder.deliveryDateConfirmTimestamp), "dd-LLL-yyyy")},{" "}
            {dateFns.format(new Date(routeOrder.deliveryDateConfirmTimestamp), "HH:mm")}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel title="potwierdzenie">
        <Typography fontSize="14" fontWeight="500">
          {routeOrder.isDeliveryDateConfirm ? (
            <span className="text-color-green">potwierdzono</span>
          ) : !routeOrder.isDeliveryDateConfirm && !routeOrder.isDeliveryDateRejected ? (
            <span className="text-orange-4">oczekuje</span>
          ) : (
            <span className="text-red-6">odrzucono</span>
          )}
        </Typography>
      </InfoLabel>
      <InfoLabel title="powód odrzucenia">
        {routeOrder.isDeliveryDateConfirm ? (
          <Typography fontSize="14" fontWeight="500">
            {routeOrder.isDeliveryDateConfirm}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel title="kto odrzucił">
        {routeOrder.deliveryDateRejectedByFirstName ? (
          <AvatarWithName
            user={{
              firstName: routeOrder.deliveryDateRejectedByFirstName,
              lastName: routeOrder.deliveryDateRejectedByLastName,
              avatar: routeOrder.deliveryDateRejectedByAvatar,
            }}
          />
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel title="kiedy odrzucono">
        {routeOrder.deliveryDateRejectedTimestamp ? (
          <Typography fontSize="14" fontWeight="500">
            {dateFns.format(new Date(routeOrder.deliveryDateRejectedTimestamp), "dd-LLL-yyyy")}
            {dateFns.format(new Date(routeOrder.deliveryDateRejectedTimestamp), "HH:mm")}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <div className="d-flex align-items-center gap-2">
        <Checkbox
          checked={routeOrder.callBeforeDelivery}
          label="Zadzwonić do klienta przed dojazdem"
          onChange={callBeforeDelivery => {
            callBeforeDeliveryMutation.mutate({
              id: routeOrder.id,
              toUpdate: {
                callBeforeDelivery,
              },
            });
          }}
          size="sm"
        />
        <Select
          onChange={value => {
            timeOfCallBeforeDelivery.mutate({
              id: routeOrder.id,
              toUpdate: {
                timeOfCallBeforeDelivery: value ? String(value) : null,
              },
            });
          }}
          disabled={!routeOrder.callBeforeDelivery}
          selected={routeOrder.timeOfCallBeforeDelivery}
          items={[
            { text: "30 min wcześniej", type: MenuItemType.TEXT, value: "00:30:00" },
            { text: "1h wcześniej", type: MenuItemType.TEXT, value: "01:00:00" },
            { text: "2h wcześniej", type: MenuItemType.TEXT, value: "02:00:00" },
            { text: "3h wcześniej", type: MenuItemType.TEXT, value: "03:00:00" },
          ]}
          label="Ile czasu przed?"
        />
      </div>
    </>
  );
};

const Address = ({ routeOrder }: Props) => {
  return (
    <Typography fontSize="14" fontWeight="500">
      {routeOrder.delivery.fistName}&nbsp; {routeOrder.delivery.lastName}
      <br />
      {routeOrder.delivery.street}
      <br />
      {routeOrder.delivery.postCode}&nbsp;
      {routeOrder.delivery.city}
      <br />
      {routeOrder.delivery.phone}
    </Typography>
  );
};
