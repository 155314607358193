import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";
import { SalesInvoiceItems } from "./SalesInvoiceItems";
import { SummarySection } from "../summarySection/SummarySection";
import { PaymentDetails } from "./PaymentDetails";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { useToggle } from "hooks";
import { ManageGTUCodes } from "../manageGTUCodes/ManageGTUCodes";

interface Props {
  hasGTUCodes?: boolean;
  tradingDocument: TradingDocument;
}

export const ItemsSection = ({ hasGTUCodes, tradingDocument }: Props) => {
  const manageGTUCodesModal = useToggle();

  if (!Boolean(tradingDocument.items.length)) return null;

  return (
    <RightPanelSection>
      <div className="pb-3">
        {hasGTUCodes && (
          <div className="d-flex justify-content-end pb-2">
            <Button
              className="text-uppercase"
              onClick={manageGTUCodesModal.open}
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Dodaj kod(-/y) GTU
            </Button>
          </div>
        )}
        <SalesInvoiceItems hasGTUCodes={hasGTUCodes} tradingDocument={tradingDocument} />
        <SummarySection tradingDocument={tradingDocument} />
        <PaymentDetails tradingDocument={tradingDocument} />
      </div>
      {manageGTUCodesModal.isOpen && (
        <ManageGTUCodes close={manageGTUCodesModal.close} tradingDocument={tradingDocument} />
      )}
    </RightPanelSection>
  );
};
