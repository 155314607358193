import { Pagination, UUID } from "api/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { carrierOrdersKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";
import {
  CarrierOrder,
  CarrierOrderEmailMessage,
  CreateCarrierOrderEmailMessageDTO,
} from "./models";
import { Assign } from "utility-types";
import { PartialOf } from "typeUtilities";
import { fileFactoryUtils } from "utilities/fileFactory";

const getCarrierOrders = (search: string = ""): ApiFetcher<Pagination<CarrierOrder>> => ({
  key: carrierOrdersKeys.carrierOrders.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/carrier-orders/items" + search,
    }),
});

const getCarrierOrder = (id: string | number): ApiFetcher<CarrierOrder> => ({
  key: carrierOrdersKeys.carrierOrders.details(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/carrier-orders/items/" + id,
    }),
});

const getCarrierOrdersEmailMessages = (
  search: string = "",
): ApiFetcher<Pagination<CarrierOrderEmailMessage>> => ({
  key: carrierOrdersKeys.carrierOrdersEmailMessage.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/carrier-orders/email-messages" + search,
    }),
});

const postCarrierOrdersEmailMessage = (data: CreateCarrierOrderEmailMessageDTO) =>
  queryFetch<void>({
    method: "POST",
    url: "/carrier-orders/send-carrier-order-email",
    data,
  });

const patchCarrierOrder = (data: Assign<PartialOf<CarrierOrder>, { id: UUID }>) =>
  queryFetch<CarrierOrder>({
    method: "PATCH",
    url: "/carrier-orders/items/" + data.id,
    data,
  });

export const carrierOrdersFileFactory = (() => {
  return {
    carrierOrderCSV: (carrierOrdersIds: UUID[], signature?: string) => ({
      url: `/carrier-orders/csv?speditionCarriers=${encodeURIComponent(
        carrierOrdersIds.join(","),
      )}`,
      name: `${
        signature ? fileFactoryUtils.formatSignature(signature) : "zlecenia-przewozu"
      }-${fileFactoryUtils.getDate()}`,
    }),
  };
})();

export const carrierOrdersApi = {
  getCarrierOrder,
  getCarrierOrders,
  getCarrierOrdersEmailMessages,
  patchCarrierOrder,
  postCarrierOrdersEmailMessage,
};
