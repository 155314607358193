import { NavigationSubLink } from "components/common/moduleNavigation/components/navigationSubLink/NavigationSubLink";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiScheme } from "components/miloDesignSystem/atoms/icons/MdiScheme";
import { useSelector } from "hooks";
import { WarehouseSchemaRouting } from "routes/wms/WarehouseSchemaRouting";

const TabSubSection = () => {
  const warehouses = useSelector(state => state.partials.warehouses);
  const halls = useSelector(state => state.partials.halls);
  return (
    <div>
      {warehouses.map(({ id, name }) => (
        <NavigationSubLink
          to={`/wms/warehouse-schema?hallId=${
            halls.filter(hall => hall.warehouse === id)?.[0]?.id
          }`}
          key={id}
        >
          {name}
        </NavigationSubLink>
      ))}
    </div>
  );
};

export const warehouseSchemaModule: ModuleLink = {
  url: "warehouse-schema",
  label: "Schemat magazynu",
  icon: MdiScheme,
  subSection: {
    renderer: <TabSubSection />,
    isExpandedByDefault: true,
    collapsingDisabled: true,
  },
  routing: WarehouseSchemaRouting,
  isNavigationDefaultCollapsed: true,
};
