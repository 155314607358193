import { carrierOrdersActions } from "api/logistics/carrierOrders/actions";
import { CarrierOrder } from "api/logistics/carrierOrders/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Modal } from "components/miloDesignSystem/atoms/modal/Modal";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Formik } from "formik";
import { cx } from "utilities";

interface Props {
  carrierOrder: CarrierOrder;
  close: () => void;
}

interface Values {
  speditionOrderId: CarrierOrder["id"];
  email: string;
}

export const SendEmailToCustomRecipient = ({ carrierOrder, close }: Props) => {
  const sendEmailMutation = carrierOrdersActions.useSendCarrierOrdersEmailMessage();
  const initialValues: Values = {
    speditionOrderId: carrierOrder.id,
    email: "",
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="18" fontWeight="700">
          Wyślij dokument przewozowy na wskazany adres
        </Typography>
      }
      width={400}
    >
      <div className="p-3">
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            sendEmailMutation.mutate(
              {
                speditionOrderId: values.speditionOrderId,
                recipient: {
                  email: values.email,
                },
              },
              {
                onSuccess: close,
              },
            );
          }}
        >
          {({ handleSubmit, isValid }) => (
            <form onSubmit={handleSubmit} className={cx({ "was-validated": !isValid })}>
              <TextField.Form<Values> label="Email" name="email" placeholder="Wpisz email" />
              <div className="d-flex align-items-center gap-3 pt-3">
                <Button
                  className="text-uppercase"
                  onClick={close}
                  size="medium"
                  variant="transparent"
                >
                  Anuluj
                </Button>
                <Button
                  className="text-uppercase"
                  isLoading={sendEmailMutation.isLoading}
                  size="medium"
                  type="submit"
                  variant="deepPurple"
                >
                  Wyślij
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
