import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";

export function getLabelColor(leftDays: number, type: "STANDARD" | "COMPLAINT"): ColorPalette {
  const days = type === "STANDARD" ? leftDays : (leftDays - 14) * -1;

  if (days > 14) {
    return "turquoise200";
  }
  if (days >= 7) {
    return "turquoise200";
  }
  if (days >= 2) {
    return "success200";
  }
  if (days >= 0) {
    return "yellow200";
  }
  if (days >= -7) {
    return "orange200";
  }
  if (days >= -14) {
    return "pink100";
  } else {
    return "red200";
  }
}
