import { channels, Channels } from "CONSTANTS";
import { CreateManufacturingOrderItem, PaymentSource } from "api/external-manufacturing/models";
import { orderActions } from "api/orders/actions";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { Switch } from "components/miloDesignSystem/atoms/switch";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { countryToFlagDict } from "constants/countriesFlags";
import { orderConstants } from "constants/orders";
import { isPast } from "date-fns";
import produce from "immer";
import { PriorityLabel } from "pages/externalManufacturerPanel/shared/components/PriorityLabel";
import { Dispatch, SetStateAction } from "react";
import { dateFns, dateUtils, pluralize } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

const paymentSourceDict: Record<PaymentSource, string> = {
  ONLINE: "Przelew",
  ON_DELIVERY: "Pobranie",
};

export const useExternalManufacturingOrdersColumns = (
  isLineItemSelected: (id: string | number) => boolean,
  setLineItemSelectionStatus: (id: string | number) => void,
  areSomeLineItemsSelected: boolean,
  areAllSelected: boolean,
  areAllLineItemsPreviouslyAdded: boolean,
  setAllLineItems: () => void,
  setLineItems: Dispatch<SetStateAction<CreateManufacturingOrderItem[]>>,
) => {
  return useCreateTableColumns<CreateManufacturingOrderItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectOrders",
        header: () => {
          return (
            <div className="d-flex align-items-end">
              <Checkbox
                checked={areAllSelected}
                disabled={areAllLineItemsPreviouslyAdded}
                indeterminate={areSomeLineItemsSelected}
                onChange={setAllLineItems}
                size="sx"
              />
            </div>
          );
        },
        size: 25,
        cell: info => {
          const lineItem: CreateManufacturingOrderItem = info.getValue();
          return (
            <Checkbox
              disabled={lineItem.details.addedToManufacturingOrder}
              checked={isLineItemSelected(lineItem.id)}
              onChange={() => {
                if (!lineItem.details.addedToManufacturingOrder) {
                  setLineItemSelectionStatus(lineItem.id);
                }
              }}
              size="sx"
            />
          );
        },
      }),
      columnHelper.text(row => row.product.name, {
        header: "nazwa produktu",
        size: 200,
      }),
      columnHelper.text(row => row.manufacturer.name, {
        header: "producent",
        size: 110,
      }),
      columnHelper.accessor(row => row.salesChannel, {
        header: "kanał sprzedaży",
        size: 110,
        cell: info => {
          const salesChannel: CreateManufacturingOrderItem["salesChannel"] = info.getValue();
          const salesChannelName = salesChannel.name as Channels;
          return (
            <Typography.WithCustomColor
              fontSize="12"
              fontWeight="700"
              color={
                channels[salesChannelName] !== undefined
                  ? channels[salesChannelName].color
                  : "neutralBlack100"
              }
              noWrap
            >
              {channels[salesChannelName] !== undefined
                ? channels[salesChannelName].name
                : salesChannel.name}
            </Typography.WithCustomColor>
          );
        },
      }),
      columnHelper.link({
        header: "rezerwacja",
        size: 100,
        to: row => `/reservations?panelId=${row.reservation?.id}`,
        textRenderer: row => row.reservation?.signature,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "updateItemAutoReservations",
        header: context => {
          const lineItems = context.table.options.data;
          return (
            <div className="d-flex align-items-center">
              <UpdateItemsAutoReservations
                isExcludedFromReservation={lineItems.every(
                  lineItem => lineItem.isExcludedFromReservation,
                )}
                onSuccess={isExcludedFromReservation => {
                  setLineItems(prev =>
                    prev.map(lineItem => ({ ...lineItem, isExcludedFromReservation })),
                  );
                }}
                singleItemQuantitiesIds={lineItems.map(lineItem => lineItem.singleItemQuantityId)}
              />
              <Typography className="ml-1" color="neutralBlack48" fontSize="12" fontWeight="400">
                blokuj rezerwacje
              </Typography>
            </div>
          );
        },
        size: 130,
        cell: info => {
          const lineItem = info.getValue();
          return (
            <UpdateItemsAutoReservations
              key={lineItem.id}
              isExcludedFromReservation={lineItem.isExcludedFromReservation}
              onSuccess={exclude => {
                setLineItems(prev =>
                  produce(prev, draft => {
                    const itemToUpdate = draft.find(item => item.id === lineItem.id);
                    assertIsDefined(itemToUpdate);
                    itemToUpdate.isExcludedFromReservation = exclude;
                  }),
                );
              }}
              singleItemQuantitiesIds={[lineItem.singleItemQuantityId]}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.priority, {
        id: "priority",
        header: "",
        size: 25,
        cell: info => {
          const priority = info.getValue();
          return (
            <div className="w-100 d-flex align-items-center justify-content-center pr-3">
              <PriorityLabel priority={priority} />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.payment, {
        header: "płatność",
        size: 160,
        cell: info => {
          const payment: CreateManufacturingOrderItem["payment"] = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="14" fontWeight="700">
                {paymentSourceDict[payment.type]}
              </Typography>
              <Tag
                label={orderConstants.paymentStatusVariants[payment.status].label}
                variant={orderConstants.paymentStatusVariants[payment.status].variant}
              />
            </div>
          );
        },
      }),
      columnHelper.text(row => row.order.signature, {
        header: "zamówienie",
        size: 130,
      }),
      columnHelper.accessor(row => row.order.placedAt, {
        header: "złożone",
        size: 100,
        cell: info => {
          const date: string | null = info.getValue();
          if (!date) return <EmptyValue />;
          return (
            <Typography fontSize="12" fontWeight="700">
              {dateFns.formatRelative(new Date(date), "dd.MM.yyyy", true)}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.address.countryCode, {
        header: "kraj",
        size: 60,
        cell: info => {
          const countryCode: string = info.getValue();
          if (!countryCode || !Boolean(countryCode.length)) return <EmptyValue />;
          return (
            <div className="d-flex align-items-center gap-1">
              <img alt="Flaga kraju" src={countryToFlagDict[countryCode]} />
              <Typography fontSize="12" fontWeight="700">
                {countryCode}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.order.shipmentTime, {
        header: "dni",
        size: 50,
        cell: info => {
          const days: number = info.getValue();
          return (
            <Typography fontSize="12" fontWeight="700">
              {days}{" "}
              {pluralize.pl(days, {
                singular: "dzień",
                plural: "dni",
                other: "dni",
              })}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.order.deliveryNoLaterThan, {
        header: "ostateczna data",
        size: 100,
        cell: info => {
          const date: string | null = info.getValue();
          if (!date) return <EmptyValue />;
          return (
            <Typography
              color={isPast(new Date(date)) ? "danger400" : "neutralBlack100"}
              fontSize="12"
              fontWeight="700"
            >
              {dateUtils.formatDateToDisplay(date)}
            </Typography>
          );
        },
      }),
      columnHelper.text(row => (row.deliveryGroup ? row.deliveryGroup.signature : null), {
        header: "trasa/grupa",
        size: 110,
      }),
      columnHelper.text(row => row.labels.length, {
        header: "l. paczek",
        size: 50,
      }),
      columnHelper.text(row => row.seller.name, {
        header: "sprzedawca",
        size: 110,
      }),
      columnHelper.accessor(row => row.details.addedToManufacturingOrder, {
        header: "status zlecenia",
        size: 90,
        cell: info => {
          const status: boolean = info.getValue();
          if (status) return <Tag label="zlecono" variant="success" />;
          return <Tag label="niezlecono" variant="warning" />;
        },
      }),
    ];
  });
};

const UpdateItemsAutoReservations = ({
  isExcludedFromReservation,
  singleItemQuantitiesIds,
  onSuccess,
}: {
  singleItemQuantitiesIds: CreateManufacturingOrderItem["singleItemQuantityId"][];
  isExcludedFromReservation: boolean;
  onSuccess: (exclude: boolean) => void;
}) => {
  const updateItemsAutoReservationsMutation = orderActions.useUpdateItemsAutoReservations();
  return (
    <Switch
      disabled={updateItemsAutoReservationsMutation.isLoading}
      checked={isExcludedFromReservation}
      onChange={exclude => {
        onSuccess(exclude);
        updateItemsAutoReservationsMutation.mutateAsync({
          exclude,
          singleItemQuantitiesIds,
        });
      }}
    />
  );
};
