import { useTradingDocument } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { useInfiniteLogs } from "hooks/useInfinityLogs";
import { useLocation, useParams } from "react-router";
import styles from "./TradingDocumentHistory.module.css";
import { PageHeader, ViewLabel } from "components/common";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { HistoryLogItem } from "components/common/historyLogItem/HistoryLogItem";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

type LocationState = { tradingDocument: TradingDocument } | undefined;

export const TradingDocumentHistory = () => {
  const params = useParams<{ tradingDocumentID: UUID }>();
  const location = useLocation<LocationState>();
  const locationTradingDocument = location.state?.tradingDocument;
  const { data: fetchedTradingDocument } = useTradingDocument(
    { id: params.tradingDocumentID },
    {
      enabled: !Boolean(locationTradingDocument),
    },
  );
  const tradingDocument = locationTradingDocument ?? fetchedTradingDocument;
  const { logs, fetchMore, inProgress, hasMore } = useInfiniteLogs(
    params.tradingDocumentID,
    "tradingDocument",
  );

  return (
    <div className="position-relative">
      <PageHeader
        searchBar={false}
        subtitle={tradingDocument?.signature ?? ""}
        title={
          <div className="d-flex align-items-center gap-2">
            <Typography fontSize="20" fontWeight="700">
              {getViewLabelAndHeaderTitle(tradingDocument?.invoiceType).title}
            </Typography>
            {inProgress && <Spinner size={20} />}
          </div>
        }
        viewLabel={
          (getViewLabelAndHeaderTitle(tradingDocument?.invoiceType)
            .viewLabel as unknown) as ViewLabel
        }
      />
      <div className={styles.listWrapper}>
        <div className={styles.listGroup}>
          {logs.map(log => (
            <HistoryLogItem key={log.id} log={log} />
          ))}
        </div>
        <div className="d-flex mt-4 mb-3">
          {hasMore && (
            <Button
              className="text-uppercase"
              isLoading={inProgress}
              onClick={fetchMore}
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Wczytaj więcej
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const getViewLabelAndHeaderTitle = (
  invoiceType?: TradingDocument["invoiceType"],
): { title: string; viewLabel: ViewLabel | null } => {
  switch (invoiceType) {
    case "ADVANCE":
      return { title: "Historia faktury zaliczkowej", viewLabel: "ADVANCE_HISTORY" };
    case "CORRECTION":
      return {
        title: "Historia korekty",
        viewLabel: "CORRECTION_INVOICE_HISTORY",
      };
    case "FINAL":
      return { title: "Historia faktury końcowej", viewLabel: "SALES_INVOICE_HISTORY" };
    case "PROFORMA":
      return {
        title: "Historia proformy",
        viewLabel: "PROFORMA_HISTORY",
      };
    case "PURCHASE":
      return { title: "Historia faktury zakupowej", viewLabel: "PURCHASE_INVOICE_HISTORY" };
    case "RECEIPT":
      return {
        title: "Historia paragonu",
        viewLabel: "RECEIPT_HISTORY",
      };
    case "RECEIPT_BASED_ON_INVOICE":
      return {
        title: "Historia paragonu",
        viewLabel: "RECEIPT_BASED_ON_INVOICE_HISTORY",
      };
    case "SALES":
      return {
        title: "Historia faktury sprzedażowej",
        viewLabel: "SALES_INVOICE_HISTORY",
      };
    default:
      const exhaustiveCheck = invoiceType;
      console.error(`Unhandled invoice type: ${exhaustiveCheck}`);
      return { title: EMPTY_VALUE, viewLabel: null };
  }
};
