import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useToggle } from "hooks";
import { useNavigate } from "hooks/useNavigate";
import { useLocation } from "react-router";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button as DesignSystemButton } from "components/miloDesignSystem/atoms/button";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import styles from "../../../../shared/TradingDocumentsLists.module.css";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { TradingDocumentOptionsMenuFactory } from "pages/tradingDocuments/shared/optionsMenuFactory/TradingDocumentOptionsMenuFactory";
import { TransportInfo } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/OrderMaterialModal";

export const PanelHeader = () => {
  const { close } = useDrawer("myInvoices");
  const { query } = useQuery();
  const { panelId } = query;
  const navigate = useNavigate();
  const location = useLocation();
  const { data: tradingDocument } = useTradingDocument({ id: panelId });
  assertIsDefined(tradingDocument);
  const optionsMenu = TradingDocumentOptionsMenuFactory({ close, tradingDocument });
  const { isTriggerElementHidden } = useScrollableContext();
  const transportModalController = useToggle();

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <DesignSystemButton
              className="text-uppercase"
              onClick={() => {
                navigate(`/finances/import-invoices/check?id=${tradingDocument.id}`, {
                  fromMyInvoices: `${location.pathname}${location.search}`,
                });
              }}
              size="small"
              startIcon={MdiCheck}
              variant="gray"
            >
              Przejdź do zatwierdzania
            </DesignSystemButton>
            <div>Faktura VAT&nbsp;{tradingDocument.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <div>
            <DesignSystemButton
              className="text-uppercase"
              onClick={() => {
                navigate(`/finances/import-invoices/check?id=${tradingDocument.id}`, {
                  fromMyInvoices: `${location.pathname}${location.search}`,
                });
              }}
              size="small"
              startIcon={MdiCheck}
              variant="gray"
            >
              Przejdź do zatwierdzania
            </DesignSystemButton>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          <Tooltip title="Podgląd PDF z danymi faktury zakupowej">
            <IconButton
              icon={<MdiDownloadPdf size="18" />}
              onClick={() => window.open(`${tradingDocument.tradingDocumentPdf?.pdf}`)}
              variant="transparent"
            />
          </Tooltip>
          <Menu
            className={styles.optionsMenu}
            disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
            menuItems={[
              optionsMenu.downloadTradingDocumentXML(),
              { type: MenuItemType.DIVIDER },
              optionsMenu.downloadDuplicatePDF(),
              { type: MenuItemType.DIVIDER },
              optionsMenu.editTransportDocuments(transportModalController.open),
              { type: MenuItemType.DIVIDER },
              optionsMenu.connectPurchaseInvoice(),
              optionsMenu.tradingDocumentHistory(panelId),
              { type: MenuItemType.DIVIDER },
              optionsMenu.deleteTradingDocument(),
            ]}
          />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {transportModalController.isOpen && (
        <TransportInfo close={transportModalController.close} tradingDocument={tradingDocument} />
      )}
    </>
  );
};
