import { Marker as MapMarker, Polyline } from "@react-google-maps/api";
import redClusterMarker from "assets/images/multi.png";
import blueClusterMarker from "assets/images/multiPinned.png";
import { useToggle } from "hooks";
import { Fragment, useEffect, useMemo } from "react";
import { useRouteViewState } from "../routeCreatorState";
import { Marker } from "./Marker";
import { Route } from "api/routes/models";
import { RoutePoint } from "./Map";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";

interface Props {
  cluster: RoutePoint[];
  span: { north: number; east: number; south: number; west: number };
  zoom: number;
  route: Route;
  isLoading: boolean;
}

export const MarkerCluster = ({ cluster, span, zoom, route, isLoading }: Props) => {
  const showOnlyRoutePoints = useRouteViewState("slave", store => store.showOnlyRoutePoints);

  const { isOpen, toggle, close } = useToggle();

  const isAnyOrderAddedToRoute = useMemo(() => cluster.some(order => order.isPinned), [cluster]);

  // Close on zoom change. We do this because it looks bad when you zoom with cluster opened.
  useEffect(() => {
    close();
  }, [zoom, close]);

  return (
    <>
      <MapMarker
        position={cluster[0].point}
        onClick={toggle}
        visible={showOnlyRoutePoints ? isAnyOrderAddedToRoute : true}
        icon={{
          url: isAnyOrderAddedToRoute ? blueClusterMarker : redClusterMarker,
          anchor: { x: 14, y: 14 } as google.maps.Point,
        }}
        label={{
          text: String(cluster.length),
          color: colorPalette.neutralWhite100,
          fontSize: "15px",
          fontWeight: "700",
        }}
      />
      {cluster.map((location, index) => {
        const spreadPoint = spread(location.point, cluster.length, index, span);

        const newLocation = { ...location, point: spreadPoint };
        return (
          <Fragment key={location.id}>
            <Marker
              routePoint={newLocation}
              isLoading={isLoading}
              visible={isOpen}
              route={route}
              index={9999}
            />

            <Polyline
              visible={isOpen}
              path={[location.point, spreadPoint]}
              options={{
                strokeColor: "#d50e01",
                strokeWeight: 4,
              }}
            />
          </Fragment>
        );
      })}
    </>
  );
};

function spread(
  point: { lat: number; lng: number },
  number: number,
  index: number,
  span: { east: number; west: number },
) {
  const earthHeightToWidthRatio = 0.83214799550777;
  const distanceFromRootPoint = (span.east - span.west) / 35;

  return {
    lat:
      point.lat +
      Math.cos(index * radianToDegree(360 / number)) *
        earthHeightToWidthRatio *
        distanceFromRootPoint,
    lng: point.lng + Math.sin(index * radianToDegree(360 / number)) * distanceFromRootPoint,
  };
}

function radianToDegree(x: number) {
  return (x * Math.PI) / 180;
}
