import cx from "classnames";
import boxImg from "assets/images/23w.svg";
import carImg from "assets/images/24w.svg";
import { useMemo, useState } from "react";
import styles from "./RoutesList.module.css";
import { dateFns, createDateRanges } from "utilities";
import { Button, Header, Toolbar } from "components/common";
import { Cell, List, Row } from "components/utils/list";
import { Checkbox, CommonError, Pagination, RightPanelHandler, Spinner } from "components/utils";
import { ListAndDrawerWrapper } from "components/utils/drawer";
import { RightPanel } from "./rightPanel";
import { RouteStatusFlow } from "./statusFlow";
import { useQuery, useSelector, useStateModal, useToggle } from "hooks";
import { RouteComponentProps } from "react-router";
import { useRoutes } from "hooks/apiPrimitives";
import { NoContent } from "components/utils/noContent";
import listEmptyIcon from "assets/images/el2.png";
import { UserAvatar } from "components/utils/userAvatar";
import plusIcon from "assets/images/20c.svg";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import barcodeDarkIcon from "assets/images/barcodeDark.svg";
import { Link } from "react-router-dom";
import { OrderIndex } from "api/orders/models";
import { CreateManufacturerOrProductionOrderModal } from "pages/orders/ordersList/createManufacturerOrProductionOrderModal/CreateManufacturerOrProductionOrderModal";
import { routePaths } from "routePaths";
import { useSearch } from "./hooks";
import { useListCheckboxes } from "hooks/useListCheckboxes";
import { RouteListItem } from "api/routes/models";
import { Subtoolbar } from "./Subtoolbar";
import { createSingleDateOptions } from "utilities/createSingleDateOptions";
import { DownloadRoutesReportModal } from "./reports/downloadRoutesReportModal/DownloadRoutesReportModal";

export type Tab = "active" | "departed" | "archive";

const splitTime = (time: string) => {
  const [hour, minutes, seconds] = time.split(":").filter(Boolean);
  return { hour, minutes, seconds };
};

export const defaultOrdering = "";

// modifies query based on active tab

type Props = RouteComponentProps<{ tab: Tab }>;
export type Mode = "default" | "productionPdfPreview" | "printReceipt";

export const RoutesList = ({ match }: Props) => {
  const filterByUser = useQuery().query.filterByUser === "true";
  const { tab } = match.params;
  const drivers = useSelector(state => state.partials.drivers);
  const startingPoints = useSelector(store => store.partials.startingPoints);
  const [dateRanges] = useState(() => createDateRanges());
  const [singleDateRanges] = useState(() => createSingleDateOptions());
  const [mode, setMode] = useState<Mode>("default");
  const checkboxes = useListCheckboxes<RouteListItem>(mode);
  const createManufacturerOrProductionOrderModal = useStateModal<OrderIndex[]>();
  const downloadRoutesReportModal = useToggle();
  const driversList = useMemo(
    () =>
      [{ label: "Wszyscy", value: "" }].concat(
        drivers.map(driver => ({
          label: `${driver.firstName} ${driver.lastName}`,
          value: String(driver.id),
        })),
      ),
    [drivers],
  );
  const startingPointsList = useMemo(
    () =>
      [{ label: "Wszystkie", value: "" }].concat(
        startingPoints.map(startingPoint => ({
          label: startingPoint.name || `${startingPoint.street}, ${startingPoint.city}`,
          value: String(startingPoint.id),
        })),
      ),
    [startingPoints],
  );
  const search = useSearch();
  const { data: routes, pagination, error, isLoading, isPreviousData } = useRoutes(search);

  const toggleProductionPdfPreview = () =>
    mode !== "productionPdfPreview" ? setMode("productionPdfPreview") : setMode("default");

  const togglePrintReceipt = () =>
    mode !== "printReceipt" ? setMode("printReceipt") : setMode("default");

  const departureDateRanges: { label: string; value: [string, string] }[] = singleDateRanges.map(
    dateRange => {
      return {
        label: dateRange.label,
        value: [dateRange.value, dateRange.value],
      };
    },
  );

  const noResults = !routes.length && !isLoading;

  if (error) {
    return <CommonError status={error.status} />;
  }

  return (
    <div className="position-relative">
      <Header
        title={({ styles: s }) => (
          <div className="d-flex align-items-center">
            <h1 className={s.title}>Trasy</h1>
            <div className="d-flex align-items-baseline ml-3">
              <Button kind="secondary-stroke" size="small" as={Link} to="/routes/creator">
                <img src={plusIcon} alt="" className="mr-1 mb-1" />
                Utwórz trasę
              </Button>
            </div>
          </div>
        )}
        viewSwitch={true}
        routeRoot="routes"
        tabs={[
          { label: "Aktywne", name: "active" },
          { label: "W drodze", name: "departed" },
          { label: "Archiwum", name: "archive" },
        ]}
      />
      <RightPanelHandler name="routes">
        {({ togglePanel, isActive, close, isHighlighted }) => (
          <>
            {(tab === "active" || tab === "departed") && (
              <Toolbar
                drawerFilters={[
                  {
                    name: "hasCarrier",
                    label: "czy ma przewoźnika?",
                    type: "string",
                    options: [
                      { label: "Wszystkie", value: "" },
                      { label: "Tak", value: "true" },
                      { label: "Nie", value: "false" },
                    ],
                    default: "",
                  },
                  {
                    name: "hasCustomRouteCost",
                    label: "czy ma wpisaną własną kwotę za trasę?",
                    type: "string",
                    options: [
                      { label: "Wszystkie", value: "" },
                      { label: "Tak", value: "true" },
                      { label: "Nie", value: "false" },
                    ],
                    default: "",
                  },
                  {
                    name: "startingPoint",
                    label: "punkt startowy",
                    type: "string",
                    options: startingPointsList,
                    default: "",
                  },
                ]}
                filtersBtn={
                  <div className="d-flex align-items-center gap-1">
                    <Button
                      className={styles.routesReportBtn}
                      kind="secondary-grey"
                      onClick={downloadRoutesReportModal.open}
                      size="small"
                      title="Pobierz raport z tras"
                    >
                      <div className="d-flex align-items-center">
                        <img alt="Pobierz" src={downloadPdfIcon} />
                        <span className="fs-12">Pobierz raport z tras</span>
                      </div>
                    </Button>
                    <Button
                      kind={mode === "productionPdfPreview" ? "primary" : "secondary"}
                      size="round-s"
                      title="Podgląd PDF produkcyjnego"
                      onClick={toggleProductionPdfPreview}
                    >
                      <img alt="Podgląd PDF produkcyjnego" src={downloadPdfIcon} />
                    </Button>
                    <Button
                      kind={mode === "printReceipt" ? "primary" : "secondary"}
                      size="round-s"
                      title="Drukuj paragon"
                      onClick={togglePrintReceipt}
                    >
                      <img alt="Drukuj paragon" src={barcodeDarkIcon} />
                    </Button>
                  </div>
                }
                filters={[
                  {
                    name: "ordering",
                    label: "sortuj",
                    type: "string",
                    options: [
                      { label: "Od najstarszych", value: "created" },
                      { label: "Od najnowszych", value: "" },
                    ],
                    default: defaultOrdering,
                  },
                  {
                    name: "created",
                    label: "utworzono",
                    type: "string",
                    options: [{ label: "Wszystkie", value: "" }, ...singleDateRanges],
                    default: "",
                    customValue: {
                      label: "Własna data",
                      type: "date",
                    },
                  },
                  {
                    name: "isDraft",
                    label: "gotowe",
                    type: "string",
                    options: [
                      { label: "Wszystkie", value: "" },
                      { label: "Gotowe", value: "false" },
                      { label: "Niegotowe", value: "true" },
                    ],
                    default: "",
                  },
                  {
                    name: "departureDate",
                    range: ["minDepartureDate", "maxDepartureDate"],
                    label: "data wyjazdu",
                    type: "range",
                    options: [{ label: "Wszystkie", value: ["", ""] }, ...departureDateRanges],
                    default: ["", ""],
                  },
                  {
                    name: "driver",
                    label: "kierowca",
                    type: "string",
                    options: driversList,
                    default: "",
                  },
                  {
                    name: "cratedDate",
                    range: ["minCreatedDate", "maxCreatedDate"],
                    label: "data",
                    type: "range",
                    options: [
                      { label: "Wszystkie", value: ["", ""] },
                      { label: "Ten miesiąc", value: dateRanges.thisMonth },
                      { label: "Ostatni miesiąc", value: dateRanges.lastMonth },
                      { label: "Ostatni pełny miesiąc", value: dateRanges.lastFullMonth },
                      { label: "Ostatnie 6 miesięcy", value: dateRanges.lastSixMonths },
                      { label: "Ostatnie 6 pełnych miesięcy", value: dateRanges.lastSixFullMonths },
                    ],
                    default: ["", ""],
                  },
                ]}
              />
            )}
            {tab === "archive" && (
              <Toolbar
                filters={[
                  {
                    name: "ordering",
                    label: "sortuj",
                    type: "string",
                    options: [
                      { label: "Od najstarszych", value: "created" },
                      { label: "Od najnowszych", value: "" },
                    ],
                    default: defaultOrdering,
                  },
                  {
                    name: "driver",
                    label: "kierowca",
                    type: "string",
                    options: driversList,
                    default: "",
                  },
                  {
                    name: "cratedDate",
                    range: ["minCreatedDate", "maxCreatedDate"],
                    label: "data",
                    type: "range",
                    options: [
                      { label: "Wszystkie", value: ["", ""] },
                      { label: "Ten miesiąc", value: dateRanges.thisMonth },
                      { label: "Ostatni miesiąc", value: dateRanges.lastMonth },
                      { label: "Ostatni pełny miesiąc", value: dateRanges.lastFullMonth },
                      { label: "Ostatnie 6 miesięcy", value: dateRanges.lastSixMonths },
                      { label: "Ostatnie 6 pełnych miesięcy", value: dateRanges.lastSixFullMonths },
                    ],
                    default: ["", ""],
                  },
                  {
                    name: "settledDate",
                    range: ["minSettledDate", "maxSettledDate"],
                    label: "data rozliczenia",
                    type: "range",
                    options: [
                      { label: "Wszystkie", value: ["", ""] },
                      { label: "Ten miesiąc", value: dateRanges.thisMonth },
                      { label: "Ostatni miesiąc", value: dateRanges.lastMonth },
                      { label: "Ostatni pełny miesiąc", value: dateRanges.lastFullMonth },
                      { label: "Ostatnie 6 miesięcy", value: dateRanges.lastSixMonths },
                      { label: "Ostatnie 6 pełnych miesięcy", value: dateRanges.lastSixFullMonths },
                    ],
                    default: ["", ""],
                  },
                ]}
              />
            )}
            <ListAndDrawerWrapper>
              <div className="w-100">
                <Subtoolbar
                  checkboxes={checkboxes}
                  filterByUser={filterByUser}
                  mode={mode}
                  routes={routes}
                  setMode={setMode}
                />
                <List
                  variant="compact"
                  overwrites={{
                    tableBody: {
                      style: {
                        maxHeight:
                          mode !== "default" ? "calc(100vh - 151px)" : "calc(100vh - 148px)",
                      },
                    },
                  }}
                >
                  <Spinner
                    on={isLoading || isPreviousData}
                    color="blue"
                    size="big"
                    text="trwa wczytywanie"
                    className={styles.spinner}
                  />
                  {noResults && (
                    <NoContent
                      on={noResults}
                      header="Lista nie zawiera elementów"
                      img={listEmptyIcon}
                    />
                  )}
                  {routes.map(route => {
                    return (
                      <Row
                        key={route.id}
                        onClick={() => togglePanel(route.id)}
                        className={cx({
                          active: isActive(route.id),
                          highlighted: isHighlighted(route.id),
                        })}
                      >
                        {mode !== "default" && (
                          <div
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <Checkbox
                              checked={checkboxes.check(route.id)}
                              name=""
                              onChange={() => checkboxes.toggle(route)}
                            />
                          </div>
                        )}
                        <Cell className={styles.checkboxCell}>
                          <label className={styles.checkboxContainer}>
                            <input type="checkbox" readOnly checked={!route.isDraft} />
                            <span className={styles.checkMark}></span>
                          </label>
                        </Cell>
                        <Cell className={styles.detailsCell}>
                          <div>
                            <div className="d-flex align-items-center">
                              <Link
                                to={routePaths.routeList({ tab }) + `?panelId=${route.id}`}
                                className={cx("mr-1", styles.routeSignature)}
                                onClick={e => e.stopPropagation()}
                              >
                                {route.signature}
                              </Link>
                              {route.color ? (
                                <span
                                  style={{ background: route.color }}
                                  className={styles.routeLabel}
                                ></span>
                              ) : null}
                              {route.name ? (
                                <div className={cx("mr-1", styles.routeName)}>{route.name}</div>
                              ) : null}
                              {route.isDraft ? (
                                <div className="d-flex align-items-center">
                                  <i className={cx("mr-2", styles.divider)}></i>
                                  <span className="label bgPurple">Szkic</span>
                                </div>
                              ) : null}
                            </div>
                            <div className="d-flex align-items-center">
                              <DateTimeItem
                                date={route.loadingDate}
                                time={route.loadingTime}
                                icon={boxImg}
                              />
                              <div className="ml-2">
                                <DateTimeItem
                                  date={route.departureDate}
                                  time={route.departureTime}
                                  icon={carImg}
                                />
                              </div>
                            </div>

                            <div className={styles.address}>
                              {route.startingPoint ? (
                                <span>
                                  {route.startingPoint.street}, {route.startingPoint.city}
                                </span>
                              ) : (
                                <span className={styles.noData}>wybierz punkt startowy</span>
                              )}
                            </div>
                          </div>
                        </Cell>
                        <Cell className={cx("row", styles.driverCarSection)}>
                          {route.secondaryDriver && (
                            <div className="d-flex flex-column justify-content-end col-4 px-1">
                              <small className="text-color-grey">Drugi kierowca:</small>
                              <div className={styles.address}>
                                <span className="noWrap">
                                  {route.secondaryDriver.firstName} {route.secondaryDriver.lastName}
                                </span>
                              </div>
                            </div>
                          )}

                          {route.driver && (
                            <div className="d-flex flex-column justify-content-end col-4 px-1">
                              <small className="text-color-grey">Kierowca:</small>
                              <div className={cx(styles.address, "d-flex flex-1")}>
                                <span className="noWrap">
                                  {route.driver.firstName} {route.driver.lastName}
                                </span>
                              </div>
                            </div>
                          )}

                          {route.car && (
                            <div className="d-flex flex-column justify-content-end col-4 px-1">
                              <small className="text-color-grey">Samochód:</small>
                              <div className={styles.address}>
                                <span className="noWrap">{route.car.registrationNumber}</span>
                              </div>
                            </div>
                          )}
                        </Cell>

                        <Cell className={cx("ml-0 ml-3", styles.statusesCell)}>
                          <RouteStatusFlow status={route.status} />
                        </Cell>
                        <Cell className={cx("mr-2", styles.avatarCell)}>
                          <UserAvatar
                            avatarSrc={
                              drivers.find(({ id }) => id === route.driver?.id)?.avatar || ""
                            }
                          />
                        </Cell>
                      </Row>
                    );
                  })}
                  <Pagination pagination={pagination} />
                </List>
              </div>
              <RightPanel close={close} />
            </ListAndDrawerWrapper>
          </>
        )}
      </RightPanelHandler>
      <CreateManufacturerOrProductionOrderModal modal={createManufacturerOrProductionOrderModal} />
      {downloadRoutesReportModal.isOpen && (
        <DownloadRoutesReportModal close={downloadRoutesReportModal.close} />
      )}
    </div>
  );
};

const DateTimeItem = ({
  date,
  time,
  icon,
}: {
  date: string | null;
  time: string | null;
  icon: string;
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className={styles.dateImg}>
        <img src={icon} alt="" />
      </div>

      <span>
        {time ? (
          `${splitTime(time).hour}:${splitTime(time).minutes}`
        ) : (
          <span className="text-color-grey">+ godz.</span>
        )}
      </span>

      {!date || !time ? <div className={styles.border}></div> : <div className="mr-1" />}

      <span>
        {date ? (
          dateFns.format(new Date(date), "d MMM yyyy")
        ) : (
          <span className="text-color-grey">+ datę.</span>
        )}
      </span>
    </div>
  );
};
