import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiDiamond = (props: BaseIcon) => {
  return (
    <SvgWrapper {...props}>
      <path d="M1.39378 14.1213C0.222209 12.9497 0.222208 11.0503 1.39378 9.87868L9.87906 1.3934C11.0506 0.221825 12.9501 0.221826 14.1217 1.3934L22.607 9.87868C23.7786 11.0503 23.7786 12.9497 22.607 14.1213L14.1217 22.6066C12.9501 23.7782 11.0506 23.7782 9.87906 22.6066L1.39378 14.1213Z" />
    </SvgWrapper>
  );
};
