import { TradingDocument } from "api/trading-documents/models";
import styles from "../../CreateDraftDocument.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  draftInvoice: TradingDocument;
}

export const RecipientDetails = ({ draftInvoice }: Props) => {
  return (
    <div className={cx(styles.recipientDetails, "pb-3")}>
      <div className="d-flex align-items-center gap-1 justify-content-end">
        <Typography color="neutralBlack88" fontSize="16" fontWeight="700">
          Nabywca
        </Typography>
        <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
          (Kupujący)
        </Typography>
      </div>
      <div className="d-flex flex-column">
        <Typography color="neutralBlack88" fontSize="18" fontWeight="700">
          {draftInvoice.recipientCompanyName ||
            `${draftInvoice.recipientFirstName} ${draftInvoice.recipientLastName}`}
        </Typography>
        {Boolean(draftInvoice.recipientCompanyName) && (
          <Typography color="neutralBlack88" fontSize="14" fontWeight="500">
            {draftInvoice.recipientFirstName} {draftInvoice.recipientLastName}
          </Typography>
        )}
        <Typography color="neutralBlack88" fontSize="14" fontWeight="500">
          {draftInvoice.recipientPostCode} {draftInvoice.recipientCity}
        </Typography>
        <Typography color="neutralBlack88" fontSize="14" fontWeight="500">
          NIP: {draftInvoice.recipientTaxId || EMPTY_VALUE}
        </Typography>
      </div>
    </div>
  );
};
