import { UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { UnloadingOrder } from "./models";
import { unloadingKeys } from "./keys";

const getUnloadingOrdersInfo = (search: string = ""): ApiFetcher<UnloadingOrder[]> => ({
  key: unloadingKeys.ordersInfo(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/unloading/web/orders-info" + search,
    }),
});

const postStartUnloading = (data: { unloadingId: UUID }) =>
  queryFetch<void>({
    method: "POST",
    url: `/wms/unloading/web/start-unloading`,
    data,
  });

const postFinishUnloading = (data: { unloadingId: UUID }) =>
  queryFetch<void>({
    method: "POST",
    url: `/wms/unloading/web/finish-unloading`,
    data,
  });

export const wmsUnloadingApi = {
  getUnloadingOrdersInfo,
  postStartUnloading,
  postFinishUnloading,
};
