import { TradingDocument } from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  draftInvoice: TradingDocument;
}

export const GeneralInfoSection = ({ draftInvoice }: Props) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex flex-column gap-2 mb-2">
        <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
          Sprzedawca
        </Typography>
        <Typography color="neutralBlack88" fontSize="18" fontWeight="700">
          {draftInvoice.sellerCompanyName}
        </Typography>
      </div>
      <div className="d-flex flex-column gap-2 mb-2">
        <div className="d-flex align-items-center gap-1 justify-content-end">
          <Typography color="neutralBlack88" fontSize="16" fontWeight="700">
            Nabywca
          </Typography>
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            (Kupujący)
          </Typography>
        </div>
        <Typography color="neutralBlack88" fontSize="18" fontWeight="700">
          {draftInvoice.recipientCompanyName}
        </Typography>
      </div>
    </div>
  );
};
