import { useQuery } from "hooks";
import { useRouteViewState } from "../routeCreatorState";

export const useCheckboxFilter = (queryName: string) => {
  const { updateQuery, query } = useQuery();
  const actions = useRouteViewState("slave", state => state.actions);

  const updateCheckbox = (value: boolean, name: string) => {
    actions.openLoader("Trwa wczytywanie zamówień");
    const currentValues = query[queryName];
    let newValues = "";

    if (currentValues) {
      if (value) {
        newValues = currentValues + "," + name;
      } else {
        newValues = currentValues
          .split(",")
          .filter(el => el !== name)
          .toString();
      }
    } else {
      newValues = name;
    }

    updateQuery({ [queryName]: newValues });
  };

  const isChecked = (value: string) => {
    const currentValues = query[queryName];
    if (currentValues) {
      return currentValues.split(",").includes(String(value));
    }
    return false;
  };

  return { updateCheckbox, isChecked };
};
