import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiTriangle = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path d="M11.134 2.5C11.5189 1.83333 12.4811 1.83333 12.866 2.5L22.134 18.5526C22.5189 19.2192 22.0378 20.0526 21.268 20.0526H2.73205C1.96225 20.0526 1.48113 19.2192 1.86603 18.5526L11.134 2.5Z" />
    <path
      d="M11.567 2.75C11.7594 2.41667 12.2406 2.41667 12.433 2.75L21.701 18.8026C21.8934 19.1359 21.6528 19.5526 21.268 19.5526H2.73205C2.34715 19.5526 2.10659 19.1359 2.29904 18.8026L11.567 2.75Z"
      strokeOpacity="0.2"
    />
  </SvgWrapper>
);
