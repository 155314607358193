import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { parseISO } from "date-fns";
import { dateFns, dateUtils } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
  estimatedDelivery: string | null;
}

export const EstimatedDelivery = ({ estimatedDelivery, fontSize, fontWeight }: Props) => {
  if (!estimatedDelivery) return <EmptyValue fontSize={fontSize} fontWeight={fontWeight} />;

  const daysDifference = dateFns.differenceInDays(parseISO(estimatedDelivery), new Date());

  if (daysDifference < -3) {
    return (
      <Typography color="danger500" fontSize="14" fontWeight="700">
        {dateUtils.formatDateToDisplay(estimatedDelivery)}
      </Typography>
    );
  }

  if (daysDifference >= -3 && daysDifference < -1) {
    return (
      <div className="d-flex align-items-center gap-1">
        <Typography color="danger500" fontSize="14" fontWeight="700">
          {dateUtils.formatDateToDisplay(estimatedDelivery)}
        </Typography>
        <Typography color="danger500" fontSize={fontSize} fontWeight={fontWeight}>
          {Math.abs(daysDifference)} dni temu
        </Typography>
      </div>
    );
  }

  if (daysDifference >= -1 && daysDifference <= 1) {
    return (
      <div className="d-flex align-items-center gap-1">
        <Typography
          color={daysDifference === -1 || daysDifference === 0 ? "danger500" : "warning500"}
          fontSize="14"
          fontWeight="700"
        >
          {dateUtils.formatDateToDisplay(estimatedDelivery)}
        </Typography>
        <Typography
          color={daysDifference === -1 || daysDifference === 0 ? "danger500" : "warning500"}
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {daysDifference === -1 ? "wczoraj" : daysDifference === 0 ? "dzisiaj" : "jutro"}
        </Typography>
      </div>
    );
  }

  if (daysDifference > 1 && daysDifference <= 3) {
    return (
      <div className="d-flex align-items-center gap-1">
        <Typography color="warning500" fontSize="14" fontWeight="700">
          {dateUtils.formatDateToDisplay(estimatedDelivery)}
        </Typography>
        <Typography color="warning500" fontSize={fontSize} fontWeight={fontWeight}>
          pozostały {daysDifference} dni
        </Typography>
      </div>
    );
  }

  if (daysDifference > 3) {
    return (
      <Typography color="success500" fontSize="14" fontWeight="700">
        {dateUtils.formatDateToDisplay(estimatedDelivery)}
      </Typography>
    );
  }

  return null;
};
