import { Route } from "api/routes/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { InfoLabel } from "components/common/infoLabel";
import { routeActions } from "api/routes/actions";

export const PostDeliveryInfo = ({
  routeId,
  ordersCount,
}: {
  routeId: Route["id"];
  ordersCount: number;
}) => {
  const { data } = routeActions.useRoutePostDeliveryInfo(routeId);
  return (
    <div>
      <InfoLabel title="wykonane zdjęcia">
        <div className="d-flex align-items-center">
          <Typography fontSize="14" fontWeight="400">
            {data?.photoCount}
          </Typography>
        </div>
      </InfoLabel>
      <InfoLabel title="zebrane kody odbioru">
        <div className="d-flex align-items-center">
          <Typography fontSize="14" fontWeight="400">
            {data?.pickupCodeCount}
          </Typography>
          <Typography fontSize="14" fontWeight="400">
            /{ordersCount}
          </Typography>
        </div>
      </InfoLabel>
      <InfoLabel title="zebrane podpisy">
        <div className="d-flex align-items-center">
          <Typography fontSize="14" fontWeight="400">
            {data?.signatureCount}
          </Typography>
          <Typography fontSize="14" fontWeight="400">
            /{ordersCount}
          </Typography>
        </div>
      </InfoLabel>
    </div>
  );
};
