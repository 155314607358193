import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiNoClaim = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 20.5V20.6153C4 20.8718 4.12824 21 4.38473 21H8.14962C8.40611 21 8.53435 20.8718 8.53435 20.6153V16.087L4.06066 20.5607L4 20.5ZM10.1893 14.4321H10.3206L14.058 20.7527C14.1679 20.9176 14.3237 21 14.5252 21H18.7572C18.9038 21 19.0046 20.9542 19.0595 20.8626C19.1145 20.771 19.0962 20.6611 19.0046 20.5328L14.7725 13.8V13.745C15.9084 13.3969 16.7328 12.7924 17.2458 11.9313C17.7588 11.0702 18.0153 10.0351 18.0153 8.82595C18.0153 8.10636 17.931 7.45061 17.7626 6.8587L10.1893 14.4321ZM16.0675 4.3112C14.917 3.43707 13.1388 3 10.7328 3H4.38473C4.12824 3 4 3.12824 4 3.38473V16.3787L9.49166 10.887H8.53435V6.84733H10.8427C11.7771 6.84733 12.4458 7.01221 12.8489 7.34198C12.8849 7.36865 12.9195 7.39673 12.9525 7.42622L16.0675 4.3112Z"
    />
    <path d="M18.5 2L2 18.5" stroke="black" strokeOpacity="0.88" strokeWidth="2" />
  </SvgWrapper>
);
