import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedTradingDocumentItem } from "../salesInvoiceItems/SalesInvoiceItems";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { EditAmountWithTaxSection } from "../EditAmountWithTaxSection";
import { TradingDocument } from "api/trading-documents/models";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { EditVatRateSection } from "../editVatRateSection/EditVatRateSection";

export const useItemsColumns = (tradingDocument: TradingDocument) => {
  return useCreateTableColumns<NormalizedTradingDocumentItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa towaru",
          size: 180,
        }),
        columnHelper.number(row => row.quantity, {
          header: "liczba",
          size: 60,
          textAlign: "right",
        }),
        columnHelper.text(row => row.unit, {
          header: "jedn.",
          size: 50,
          textAlign: "right",
        }),
        columnHelper.amount(row => row.amountWithoutTax, {
          header: "c. netto",
          size: 85,
          amountDisplayProps: {
            decimal: {
              fontSize: "10",
              fontWeight: "700",
            },
            integer: {
              fontSize: "12",
              fontWeight: "700",
            },
          },
        }),
        columnHelper.accessor(row => row, {
          id: "amountWithTax",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              c. brutto
            </Typography>
          ),
          size: 85,
          cell: info => {
            const item = info.getValue();
            if (tradingDocument.invoiceType === "RECEIPT") {
              return (
                <EditAmountWithTaxSection
                  tradingDocumentId={tradingDocument.id}
                  tradingDocumentItem={item}
                />
              );
            }
            return (
              <AmountDisplay
                amount={String(item.amountWithTax)}
                className="w-100 d-flex justify-content-end"
                decimalTypographyProps={{
                  fontSize: "10",
                  fontWeight: "700",
                }}
                integerTypographyProps={{
                  fontSize: "12",
                  fontWeight: "700",
                }}
              />
            );
          },
        }),
        columnHelper.accessor(row => row, {
          id: "vat",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              VAT
            </Typography>
          ),
          size: 78,
          cell: info => {
            const item = info.getValue();
            if (tradingDocument.invoiceType === "RECEIPT")
              return (
                <EditVatRateSection
                  tradingDocumentId={tradingDocument.id}
                  tradingDocumentItem={item}
                />
              );
            return (
              <Typography className="w-100 text-right" fontSize="12" fontWeight="700">
                {item.vatRate}%
              </Typography>
            );
          },
        }),
        columnHelper.amount(row => (row.quantity * row.amountWithoutTax).toFixed(2), {
          header: "war. netto",
          size: 85,
          amountDisplayProps: {
            decimal: {
              fontSize: "10",
              fontWeight: "700",
            },
            integer: {
              fontSize: "12",
              fontWeight: "700",
            },
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: row => `${row.displayPosition}.`,
    },
  );
};
