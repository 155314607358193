import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { PartialOf } from "typeUtilities";
import { ApiFetcher } from "hooks/createApiQuery";
import { BasketListItem, BasketDetails, PackageInBasket } from "./models";
import { wmsBasketsKeys } from "./keys";
import { PackageInLocation } from "../layout/models";
import { Assign } from "utility-types";
import { fileFactoryUtils } from "utilities/fileFactory";

const getBaskets = (search: string = ""): ApiFetcher<Pagination<BasketListItem>> => ({
  key: wmsBasketsKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/baskets/items" + search,
    }),
});

const getBasket = (id: UUID): ApiFetcher<BasketDetails> => ({
  key: wmsBasketsKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/baskets/items/" + id,
    }),
});

const postBasket = () =>
  queryFetch<BasketDetails>({
    method: "POST",
    url: "/wms/baskets/items",
  });

const patchBasket = (data: PartialOf<BasketDetails>, id: UUID | number) =>
  queryFetch<BasketDetails>({
    method: "PATCH",
    url: "/wms/baskets/items/" + id,
    data,
  });

const deleteBasket = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/wms/baskets/items/" + id,
  });

const basketFileFactory = (() => {
  return {
    label: (basket: Pick<BasketListItem, "code" | "id">) => ({
      url: `wms/baskets/get-labels/${basket.id}`,
      name: `${basket.code}-${fileFactoryUtils.getDate()}`,
    }),
    smallBasketLabel: (basket: Pick<BasketListItem, "code" | "id">) => ({
      url: `wms/baskets/get-labels/small-basket/${basket.id}`,
      name: `${basket.code}-${fileFactoryUtils.getDate()}`,
    }),
    packageLabel: (_package: Assign<Pick<PackageInLocation, "code">, { internalId: string }>) => ({
      url: `wms/utils/get-labels?uniquePackages=${_package.internalId}.${_package.code}`,
      name: `${_package.code}-${fileFactoryUtils.getDate()}`,
    }),
  };
})();

const getPackageInBasket = (search: string = ""): ApiFetcher<Pagination<PackageInBasket>> => ({
  key: wmsBasketsKeys.packagesInBasketList(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/baskets/packages/items" + search,
    }),
});

export const wmsBasketsApi = {
  getBaskets,
  getBasket,
  postBasket,
  patchBasket,
  deleteBasket,
  basketFileFactory,
  getPackageInBasket,
};
